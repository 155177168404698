import { FC } from 'react';
import { LucideIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';

type BaseProps = {
  onClick: () => void;
  icon: LucideIcon;
  dataCy?: string;
  disabled?: boolean;
};

// Either `title` or `label` must be provided
type WithTitle = { title: string; label?: string };
type WithLabel = { title?: string; label: string };

export type ToolbarButtonProps = BaseProps & (WithTitle | WithLabel);

export const ToolbarButton: FC<ToolbarButtonProps> = ({ onClick, icon: Icon, label, title, dataCy, disabled }) => {
  // Fallback value for title if needed
  const buttonTitle = title || label;
  // Use the available title or label to construct the data-cy attribute if dataCy property is not provided
  const dataCyValue = dataCy || `toolbar-${buttonTitle?.split(' ').join('-').toLowerCase()}-button`;

  return (
    <Button
      variant="outline"
      className="group/toolbar-button flex gap-x-2 rounded-md border-none px-2"
      onClick={() => onClick()}
      title={buttonTitle}
      data-cy={dataCyValue}
      disabled={disabled}
    >
      <Icon className="size-[18px] min-h-[18px] min-w-[18px] stroke-gray-icon-color transition-colors duration-200 group-hover/toolbar-button:stroke-text-color" />
      {label && <div className="whitespace-nowrap text-xs text-text-color">{label}</div>}
    </Button>
  );
};
