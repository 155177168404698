import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Updater,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { DataTablePagination } from '@/components/data-table/data-table-pagination';
import { RowEntity } from '@/context/DataTableSelectionContext';
import { useUserSettings } from '@/context/UserSettingsContext';
import { setLocalStorage } from '@/utilities/localStorage';
import { PaginationState, defaultPaginationIndex } from '@/types/pagination';
import { SortState } from '@/types/sort';
import { useDataTablePagination } from '@/hooks/usePagination';
import { EntityType } from '@/types/entity';
import { cn } from '@/lib/utils';

// import { DataTableToolbar } from '@/components/metadata-table/data-table-toolbar';

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>;
  data: Array<TData>;
  options?: Array<{ value: string; label: string; initials: string }>;
  pagination?: PaginationState;
  sort?: SortState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  withPagination?: boolean;
  rowCount?: number;
  entity: string;
  queryString: string;
  setSelectedEntity: any;
  setSelectedEntityType: any;
  tableContainer?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  // options,
  pagination,
  setPagination,
  withPagination = true,
  rowCount,
  entity,
  queryString,
  sort,
  setSelectedEntity,
  setSelectedEntityType,
  tableContainer,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { setShowInspector } = useUserSettings();
  const { setQueryParams } = useDataTablePagination(EntityType[entity as EntityType]);

  useEffect(() => {
    if (pagination && setPagination) {
      setPagination({ pageIndex: defaultPaginationIndex, pageSize: pagination.pageSize });
    }
  }, [sort?.value, sort?.order, queryString]);

  useEffect(() => {
    if (pagination && sort) {
      setQueryParams(pagination.pageIndex + 1, pagination.pageSize, sort.value, sort.order, queryString);
    }
  }, [sort?.value, sort?.order, pagination?.pageIndex, pagination?.pageSize, queryString]);

  const setDataTablePagination = (updater: Updater<PaginationState>) => {
    if (typeof updater !== 'function') {
      return;
    }
    const oldPageInfo = table.getState().pagination;
    const newPageInfo = updater(oldPageInfo);
    setLocalStorage(`${entity}/datatable/pagination/pageSize`, String(newPageInfo.pageSize));
    if (oldPageInfo.pageSize !== newPageInfo.pageSize) {
      newPageInfo.pageIndex = defaultPaginationIndex;
    }
    if (setPagination) {
      setPagination(newPageInfo);
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility: {
        id: false,
        user_id: false,
      },
      rowSelection,
      columnFilters,
      pagination,
    },
    rowCount: rowCount,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onPaginationChange: setDataTablePagination,
    manualPagination: true, //we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (row) => (row as { id: string }).id,
  });

  useEffect(() => {
    const rowId = Object.keys(rowSelection)[0];
    setSelectedEntity(rowId ? (table.getRow(rowId) as RowEntity) : null);
    setSelectedEntityType(entity);
    setShowInspector(true);
  }, [rowSelection]);

  return (
    <>
      <div className={cn('relative w-full grow overflow-auto', tableContainer)}>
        <Table>
          <TableHeader className="rounded-none">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn((header.column.columnDef.meta as { headerClassName: string })?.headerClassName)}
                    style={{ width: header.getSize() !== 150 ? header.getSize() : 'auto' }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  onClick={row.getToggleSelectedHandler()}
                  key={row.id}
                  {...(row.getIsSelected() && { 'data-state': 'selected' })}
                >
                  {row
                    .getVisibleCells()
                    .map((cell: { id: string; column: { columnDef: any }; getContext: () => any }) => (
                      <TableCell key={cell.id} className={cell.column.columnDef.meta?.cellClassName}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {withPagination && <DataTablePagination table={table} className="px-3 py-2" />}
    </>
  );
}
