import {
  isValidElement,
  Children,
  cloneElement,
  PropsWithChildren,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { EditCreateFolderAlbumDialog } from '@/components/edit-create-folder-album-dialog';
import { DeleteNodeAlertComponent } from '@/components/delete-dialogs/delete-node-alert-component';
import { GalleryLayoutProps } from '@/components/layouts/gallery-layout';
// import { KeyboardShortcuts } from '@/components/keyboard-shortcuts';
import { Asset, GetAssets } from '@/types/asset';
import { useUserSettings } from '@/context/UserSettingsContext';
import { PaginationState } from '@/types/pagination';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { AssetUploader } from '@/components/upload-dialog/asset-uploader';
import { Navigation } from '@/components/navigation';
import { Inspector } from '@/components/inspector/inspector';
import { useDialog } from '@/context/DialogContext';
import { Sidebar } from '@/components/sidebar/sidebar';
import { Tenant } from '@/types/tenant';
import { Folder } from '@/types/folder';
import { Album } from '@/types/album';

export const DamLayout = ({
  children,
  assets,
  node,
  tenant,
  handleOnSortChange,
  setPagination,
  singleAssetShare,
  singleAssetView,
}: PropsWithChildren<{
  assets?: GetAssets;
  node?: Album | Folder | Asset;
  tenant?: Tenant;
  handleOnSortChange?: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  singleAssetShare?: boolean;
  singleAssetView?: boolean;
}>) => {
  const { showSidebar, showInspector } = useUserSettings();
  const { isPublicRoute, isManageWelcomePage } = useCurrentPage();
  const { dialogMode, dialogDefaultValue } = useDialog();

  return (
    <div className="flex h-screen min-h-screen flex-col">
      <div className="flex h-12 w-full items-center justify-between gap-x-2 border-b border-b-void-gap bg-panel-background leading-4">
        <Navigation tenant={tenant} node={node} />
      </div>
      <ResizablePanelGroup autoSaveId="mainLayout" direction="horizontal">
        {showSidebar && !isPublicRoute && (
          <>
            <ResizablePanel order={1} defaultSize={15} minSize={10} collapsedSize={5}>
              {<Sidebar handleOnSortChange={handleOnSortChange!} setPagination={setPagination} />}
            </ResizablePanel>
            <ResizableHandle withHandle />
          </>
        )}
        <ResizablePanel
          order={2}
          minSize={30}
          maxSize={90}
          className="relative flex h-full flex-col bg-container-background @container/main"
        >
          {Children.map(children, (child) =>
            isValidElement(child) ? cloneElement(child as ReactElement<GalleryLayoutProps>) : child,
          )}
        </ResizablePanel>
        {(isPublicRoute || showInspector) && !isManageWelcomePage && (
          <>
            <ResizableHandle withHandle />
            <ResizablePanel order={3} defaultSize={20} minSize={20}>
              <Inspector
                data={assets?.assets ?? []}
                pagination={assets?.pagination}
                singleAssetShare={singleAssetShare}
                singleAssetView={singleAssetView}
              />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>

      <DeleteNodeAlertComponent count={assets?.pagination?.count} />
      <EditCreateFolderAlbumDialog mode={dialogMode} id={dialogDefaultValue?.id} />
      {/* <KeyboardShortcuts /> */}
      <AssetUploader />
    </div>
  );
};
