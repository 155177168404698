import { FC, forwardRef, useMemo } from 'react';
import { cn } from '@/lib/utils';
import { Option } from '@/types/select-option';
import { DropdownIndicator, ClearIndicator, CustomInput } from '@/components/ui/react-select-elements';
import Select, { components, MenuPlacement, OnChangeValue } from 'react-select';
import {
  controlStyles,
  placeholderStyles,
  selectInputStyles,
  valueContainerStyles,
  singleValueStyles,
  multiValueStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  indicatorsContainerStyles,
  clearIndicatorStyles,
  indicatorSeparatorStyles,
  dropdownIndicatorStyles,
  menuStyles,
  groupHeadingStyles,
  optionStyles,
  noOptionsMessageStyles,
  indeterminatePlaceholderStyles,
  indeterminateDropdownIndicatorStyles,
} from '@/components/ui/react-select-styles';
import { startCase } from 'lodash';

interface MultiSelectProps {
  'name': string;
  'value': Array<string>;
  'options': Array<Option>;
  'className'?: string;
  'placeholder'?: string;
  'isClearable'?: boolean;
  'isSearchable'?: boolean;
  'disabled'?: boolean;
  'isLoading'?: boolean;
  'onChange': (newValue: OnChangeValue<Option, true>) => void;
  'menuPlacement'?: MenuPlacement;
  'menuListStyle'?: string;
  'data-cy'?: string;
  'indeterminate'?: boolean;
}

const CustomControl = (props: any) => {
  // Merge data-cy custom attribute into innerProps
  const newInnerProps = { ...props.innerProps, 'data-cy': props.selectProps['data-cy'] };
  return <components.Control {...props} innerProps={newInnerProps} />;
};

export const MultiSelect: FC<MultiSelectProps> = forwardRef<HTMLDivElement, MultiSelectProps>(
  ({
    name,
    value,
    options,
    className,
    placeholder = 'Select...',
    isClearable = true,
    isSearchable = true,
    disabled = false,
    isLoading = false,
    onChange,
    menuPlacement = 'auto',
    menuListStyle = '',
    indeterminate,
    ...props
  }) => {
    const selectedOptions = useMemo(() => {
      const arrayValue = Array.isArray(value) ? value : [];

      return arrayValue
        ?.map((val) => options.find((option) => option.value === val))
        .filter((option) => option !== undefined) as Array<Option>;
    }, [value, options]);

    return (
      <Select
        menuPlacement={menuPlacement}
        isMulti
        value={selectedOptions}
        onChange={onChange}
        options={options}
        className={className}
        placeholder={
          indeterminate ? `Multiple values selected for "${startCase(name.replace(/_/g, ' '))}"` : placeholder
        }
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={disabled}
        data-cy={props['data-cy']}
        components={{
          Input: CustomInput,
          Control: CustomControl,
          DropdownIndicator,
          ClearIndicator,
        }}
        isLoading={isLoading}
        noOptionsMessage={() => 'No options found'}
        classNames={{
          control: ({ isFocused, isDisabled }) =>
            cn(
              isDisabled && controlStyles.disabled,
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base,
              indeterminate && controlStyles.indeterminate,
            ),
          placeholder: () => (indeterminate ? indeterminatePlaceholderStyles : placeholderStyles),
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => cn(dropdownIndicatorStyles, indeterminate && indeterminateDropdownIndicatorStyles),
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected, isDisabled }) =>
            cn(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              isDisabled && optionStyles.disabled,
              optionStyles.base,
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
          menuList: () => menuListStyle,
        }}
      />
    );
  },
);

MultiSelect.displayName = 'MetadataMultiselect';
