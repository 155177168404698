import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { pageSizeOptions } from '@/types/pagination';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  className?: string;
  refine?: (value: number) => void;
  grouped?: boolean;
}

export function DataTablePagination<TData>({
  table,
  className,
  refine,
  grouped = false,
}: DataTablePaginationProps<TData>) {
  return (
    <div
      className={cn(
        'flex items-center justify-between border-t border-t-neutral-300 bg-neutral-200 px-2 dark:border-t-black dark:bg-ul-color',
        className,
      )}
    >
      <div className="flex-1 text-sm text-neutral-500">
        {`Showing ${
          grouped
            ? `${table.getGroupedRowModel().rows.length} group${table.getGroupedRowModel().rows.length > 1 ? 's' : ''}`
            : `${table.getFilteredRowModel().rows.length} ${
                table.getFilteredRowModel().rows.length > 1 ? 'entries' : 'entry'
              }`
        }`}
        {/* {table.getFilteredSelectedRowModel().rows.length
          ? `${table.getFilteredSelectedRowModel().rows.length} of ${
              table.getFilteredRowModel().rows.length
            } row(s) selected.`
          : `Showing ${table.getFilteredRowModel().rows.length} ${
              table.getFilteredRowModel().rows.length > 1 ? 'entries' : 'entry'
            }`} */}
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">{grouped ? 'Rows per page' : 'Results per page'}</p>
          <Select
            data-cy="data-table-pagination-page-size-select"
            value={
              pageSizeOptions.includes(table.getState().pagination.pageSize)
                ? String(table.getState().pagination.pageSize)
                : String(pageSizeOptions[0])
            }
            onValueChange={(value) => {
              table.setPageSize(Number(value));
              if (refine) {
                refine(Number(value));
              }
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue
                placeholder={
                  pageSizeOptions.includes(table.getState().pagination.pageSize)
                    ? table.getState().pagination.pageSize
                    : String(pageSizeOptions[0])
                }
              />
            </SelectTrigger>
            <SelectContent side="top">
              {pageSizeOptions.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div
          data-cy="data-table-pagination-current-page-text"
          className="flex w-[100px] items-center justify-center text-sm font-medium"
        >
          Page {table.getPageCount() === 0 ? 1 : table.getState().pagination.pageIndex + 1} of{' '}
          {table.getPageCount() === 0 ? 1 : table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden size-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            data-cy="data-table-pagination-first-page-button"
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="size-4" />
          </Button>
          <Button
            variant="outline"
            className="size-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            data-cy="data-table-pagination-previous-page-button"
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="size-4" />
          </Button>
          <Button
            variant="outline"
            className="size-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            data-cy="data-table-pagination-next-page-button"
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="size-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden size-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            data-cy="data-table-pagination-last-page-button"
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="size-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
