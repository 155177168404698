import { FC, PropsWithChildren, forwardRef } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Check, Minus } from 'lucide-react';
import { cn } from '@/lib/utils';

type MetadataCheckboxProps = {
  'id': string;
  'name': string;
  'checked': boolean | 'indeterminate';
  'className'?: string;
  'onChange': (value: boolean | 'indeterminate') => void;
  'disabled': boolean;
  'data-cy'?: string;
  'onClick'?: (...args: Array<any>) => void;
  'value'?: boolean;
};

export const MetadataCheckbox: FC<PropsWithChildren<MetadataCheckboxProps>> = forwardRef<
  HTMLDivElement,
  MetadataCheckboxProps
>(({ id, name, checked, value, onChange, ...props }, ref) => {
  return (
    <div ref={ref} className="flex">
      <Checkbox
        data-cy={`form-checkbox-${id}`}
        onCheckedChange={onChange as (checked: CheckedState) => void}
        {...props}
        className={cn(
          '!size-6 rounded-md border ',
          checked === 'indeterminate'
            ? 'border-input-background bg-status-warning/25'
            : 'border-ui-bevel bg-input-background',
        )}
        checked={checked === 'indeterminate' ? 'indeterminate' : value}
      >
        <Check className="mt-[-1px] hidden size-5 group-data-[state=checked]/checkbox:block" strokeWidth={3} />
        <Minus
          className="mt-[-1px] hidden size-6 text-status-warning group-data-[state=indeterminate]/checkbox:block"
          strokeWidth={2}
        />
      </Checkbox>
    </div>
  );
});

MetadataCheckbox.displayName = 'MetadataCheckbox';
