import { FC, useMemo } from 'react';
import {
  Binary,
  Calendar,
  CalendarClock,
  Dot,
  EllipsisVertical,
  RectangleHorizontal,
  Square,
  ToggleLeft,
  Trash2Icon,
  Plus,
  RectangleEllipsis,
  File,
} from 'lucide-react';
import { DataTable } from '@/components/data-table/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { useDialog } from '@/context/DialogContext';
import { useAuth0 } from '@auth0/auth0-react';
import { CreateMetadataDialog } from '@/components/create-dialogs/create-metadata-dialog';
import { DeleteMetadataAlertDialog } from '@/components/delete-dialogs/delete-metadata-alert-dialog';
import { Button } from '@/components/ui/button';
import { IndeterminateCheckbox } from '@/components/data-table/data-table-checkbox';
import { useMetadataList } from '@/hooks/metadata/useMetadata';
import { metadataMap, MetadataField, metadataTypeOptions, FieldType } from '@/types/metadata';
import { useSort } from '@/hooks/useSort';
import { useSearchQuery } from '@/hooks/useSearchQuery';
import { EntityType } from '@/types/entity';
import { baseSortOptions, SortOrderOptions } from '@/types/sort';
import { useDataTableSelection } from '@/context/DataTableSelectionContext';
import { useDataTablePagination } from '@/hooks/usePagination';
import { StatusIndicator } from '@/components/data-table/data-table-status-indicator';
import { DataTableLoadingSkeleton } from '@/components/data-table/data-table-loading-skeleton';
import { DamLayout } from '@/components/layouts/layout';
import { FilterSelect } from '@/components/ui/filter-select';
import { Toolbar } from '@/components/toolbars/toolbar';
import { FilterBar } from '@/components/toolbars/filter-bar';
import { ToolbarFilterButton } from '@/components/toolbars/toolbar-filter-button';
import { ToolbarSortOrderButton } from '@/components/toolbars/toolbar-sort-order-button';
import { Tenant } from '@/types/tenant';
import { ToolbarButton } from '@/components/toolbars/toolbar-button';

const typeIconMap: Record<
  Exclude<FieldType, 'linked_assets' | 'slider' | 'dropzone' | 'quality_slider' | 'tag_selector'>,
  JSX.Element
> = {
  string: <RectangleHorizontal className="w-full" />,
  text: <Square className="w-full" />,
  integer: <Binary className="w-full" />,
  float: <Binary className="w-full" />,
  boolean: <ToggleLeft className="w-full" />,
  date: <Calendar className="w-full" />,
  datetime: <CalendarClock className="w-full" />,
  select: <Dot className="w-full" />,
  multi_select: <EllipsisVertical className="w-full" />,
  file: <File className="w-full" />,
};

export const typeOptions = metadataTypeOptions
  .filter(({ value }) => value !== 'linked_assets') // Exclude linked assets type
  .map(({ label, value }) => ({
    value,
    label: (
      <div className="flex items-center gap-x-2">
        <div className="w-[16px]">
          {
            typeIconMap[
              value as Exclude<FieldType, 'linked_assets' | 'slider' | 'dropzone' | 'quality_slider' | 'tag_selector'>
            ]
          }
        </div>
        {label}
      </div>
    ),
  }));

const Metadata: FC<{ tenant: Tenant }> = ({ tenant }) => {
  const { isAuthenticated } = useAuth0();
  const { openModal } = useDialog();
  const { queryString, debouncedSearch, showFilterInput, setShowFilterInput } = useSearchQuery();
  const {
    pagination,
    setPagination,
    offset,
    limit,
    ready: paginationReady,
  } = useDataTablePagination(EntityType.Metadata);
  const { sort, handleOnSortChange, handleOnSortOrderChange, ready: sortReady } = useSort(EntityType.Metadata);
  const { selectedEntity, setSelectedEntity, setSelectedEntityType } = useDataTableSelection();
  const { metadataList, metadataListIsPending, metadataListIsFetching } = useMetadataList(
    {
      pagination: {
        offset,
        limit,
      },
      sort,
      queryString,
    },
    { enabled: isAuthenticated && paginationReady && sortReady },
  );
  const { metadata, pagination: paginationInfo } = metadataList;

  const columns = useMemo<Array<ColumnDef<MetadataField>>>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => (
          <IndeterminateCheckbox
            className="mx-1"
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              id: row.id,
            }}
          />
        ),
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        meta: {
          cellClassName: 'dark:text-white text-black font-semibold',
        },
        cell: ({ row }) => row.getValue('name'),
      },
      {
        accessorKey: 'type',
        header: 'Type',
        cell: ({ row }) => metadataMap[row.getValue('type') as keyof typeof metadataMap],
      },
      {
        accessorKey: 'facet',
        header: 'Facet',
        meta: {
          headerClassName: 'text-center',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'facet'} />,
      },
      {
        accessorKey: 'searchable',
        header: 'Searchable',
        meta: {
          headerClassName: 'text-center',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'searchable'} />,
      },
      {
        accessorKey: 'public',
        header: 'Public',
        meta: {
          headerClassName: 'text-center',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'public'} />,
      },
    ],
    [],
  );

  return (
    <DamLayout tenant={tenant}>
      <Toolbar>
        <div className="flex flex-1 gap-x-2 border-r border-t border-toolbar-bevel">
          <div className="ml-2 flex grow items-center gap-x-2">
            <ToolbarButton
              onClick={() => openModal('createMetadata', 'createMetadata')}
              label="Create Metadata"
              icon={Plus}
            />
            {selectedEntity && (
              <ToolbarButton
                onClick={() => {
                  void openModal('deleteMetadataConfirmation', 'delete', {
                    id: selectedEntity.getValue('id'),
                    name: selectedEntity.getValue('name'),
                    onConfirm: () => setSelectedEntity(null),
                  });
                }}
                label="Delete Metadata"
                icon={Trash2Icon}
              />
            )}
          </div>
          <ToolbarFilterButton
            showFilterInput={showFilterInput}
            onClick={() => setShowFilterInput && setShowFilterInput(!showFilterInput)}
          />
        </div>
        <div className="flex divide-x divide-void-gap">
          <FilterSelect value={sort?.value} onValueChange={handleOnSortChange} options={baseSortOptions} />
          <div className="flex">
            <div className="flex items-center gap-x-2 border-l border-t border-toolbar-bevel px-3">
              <ToolbarSortOrderButton
                sort={{ order: sort?.order as SortOrderOptions }}
                onClick={handleOnSortOrderChange}
              />
            </div>
          </div>
        </div>
      </Toolbar>
      {showFilterInput && debouncedSearch && (
        <FilterBar showFilterInput={showFilterInput} queryString={queryString} onChange={debouncedSearch} />
      )}
      {metadataListIsPending ? (
        <DataTableLoadingSkeleton />
      ) : metadata.length ||
        (!metadata.length && queryString !== '') ||
        (!metadata.length && queryString === '' && metadataListIsFetching) ? (
        <DataTable
          data={metadata}
          columns={columns}
          pagination={pagination}
          sort={sort}
          setPagination={setPagination}
          rowCount={paginationInfo.count}
          entity={EntityType.Metadata}
          queryString={queryString}
          setSelectedEntity={setSelectedEntity}
          setSelectedEntityType={setSelectedEntityType}
        />
      ) : (
        <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
          <RectangleEllipsis className="mb-10 size-20 text-neutral-300 dark:text-neutral-600" strokeWidth={1} />
          <h4 className="text-2xl">No Metadata Fields</h4>
          <div className="mb-8 max-w-md text-balance px-16 text-center text-neutral-600">
            Create and manage the metadata. Start adding fields by clicking the &quot;New Field&quot; button!
          </div>
          <Button
            onClick={() => {
              void openModal('createMetadata', 'createMetadata');
            }}
          >
            Add Field
          </Button>
        </div>
      )}

      <DeleteMetadataAlertDialog />
      <CreateMetadataDialog />
    </DamLayout>
  );
};

export default Metadata;
