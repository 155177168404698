import { getDestination, getDestinationList } from '@/services/destination.service';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useQuery } from '@tanstack/react-query';

export const useDestinationsList = (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options: object,
) => {
  const destinationListQueryFn = useAuthenticatedQueryFn(getDestinationList);

  const {
    data: destinationList = {
      destinations: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: destinationListIsPending,
    isFetching: destinationListIsFetching,
    error: destinationListError,
    refetch: refetchDestinationList,
  } = useQuery({
    queryKey: ['destinationList', params.pagination, params.queryString, params.sort],
    queryFn: () => destinationListQueryFn(params),
    ...options,
  });

  return {
    destinationList,
    destinationListIsFetching,
    destinationListIsPending,
    destinationListError,
    refetchDestinationList,
  };
};

export const useDestination = (id: string, options?: object) => {
  const destinationQueryFn = useAuthenticatedQueryFn(getDestination);

  const {
    data: destination = [],
    isPending: destinationIsPending,
    isFetching: destinationIsFetching,
    error: destinationError,
    refetch: refetchDestination,
  } = useQuery({
    queryKey: ['destination', id],
    queryFn: () => destinationQueryFn(id),
    ...options,
  });

  return {
    destination,
    destinationIsFetching,
    destinationIsPending,
    destinationError,
    refetchDestination,
  };
};
