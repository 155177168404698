import { FC } from 'react';
import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useModalData } from '@/context/ModalContext';
import { Modal } from '@/components/ui/modal';
import { ScrollArea } from './ui/scroll-area';

export const NoteDialog: FC = () => {
  const modalData = useModalData()[0];
  const note = modalData?.note ?? '';

  return (
    <Modal id="assetNote" variant="alert">
      <AlertDialogContent className="max-w-4xl">
        <AlertDialogHeader>
          <AlertDialogTitle>Asset Note</AlertDialogTitle>
          <AlertDialogDescription>
            <ScrollArea className="max-h-[calc(100vh-15rem)] overflow-y-auto whitespace-pre-line">{note}</ScrollArea>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Dismiss</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </Modal>
  );
};
