import { batchUpdate } from '@/services/batch.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { AssetListQueryKeyPrefix } from '@/hooks/data/assets/useAssetList';

export const useBatchUpdateAssets = () => {
  const queryClient = useQueryClient();

  const batchUpdateAssetsQueryFn = useAuthenticatedQueryFn(batchUpdate);

  const { mutateAsync: batchUpdateAssetsMutation } = useMutation<unknown, Error, Parameters<typeof batchUpdate>['0']>({
    mutationFn: (params) => batchUpdateAssetsQueryFn(params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [AssetListQueryKeyPrefix] }),
  });

  return {
    batchUpdateAssetsMutation,
  };
};
