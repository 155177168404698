import { useMemo, useRef, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { error as errorLog, warn } from '@/utilities/log';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { Button } from '@/components/ui/button';
import { FolderOpen, Loader2, FileSpreadsheet, X } from 'lucide-react';
import { useDialog } from '@/context/DialogContext';
import { ExtendedFileWithPath, useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { useAsset } from '@/hooks/data/assets/useAsset';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { UploadFinishedPercentage, UploadState } from '@/types/uploads';
import { PromisePool } from '@supercharge/promise-pool';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';
import { capitalizeFileExtension } from '@/lib/utils';

type MetadataCsv = {
  filename: string;
  name: string;
  metadata: object;
  tags: Array<string>;
  workflows: object;
};

export const UploadsAccordion = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['ingestion']);
  const [csvFileName, setCsvFileName] = useState<string>('');
  const { reusableTreeSelectedFolder } = useTreeStore();
  const { openModal } = useDialog();
  const { toast } = useToast();

  const queryClient = useQueryClient();

  const {
    uploadFolderPath,
    filesData,
    filesByFolder,
    clearAllFiles,
    setUploadStatus,
    uploadState,
    setUploadState,
    setAssetsUploadProgress,
    setErroredAssets,
    setAverageProgress,
    keepExistingFolders,
    replaceAssetName,
    toggleKeepExistingFolders,
    toggleReplaceAssetName,
    removeAssetsUploadProgress,
  } = useUploadStore();
  const { createAsset } = useAsset();

  const [csvData, setCvsData] = useState<Record<string, MetadataCsv>>({});
  const csvRef = useRef<HTMLInputElement>(null);
  const uploadFolderRef = useRef<HTMLInputElement>(null);

  const handleCsvClick = () => {
    csvRef?.current?.click();
  };

  const handleCsvFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension !== 'csv' || file.type !== 'text/csv') {
      alert('Please upload a valid CSV file.');
      event.target.value = '';
      return;
    }
    setCsvFileName(file.name);
    const { readString } = (await import('react-papaparse')) as typeof import('react-papaparse');

    const reader = new FileReader();
    reader.onload = () => {
      const csvContent = reader.result as string;

      readString(csvContent, {
        header: true,
        skipEmptyLines: true,
        complete: (results: { data: Array<MetadataCsv>; errors: Array<object>; meta: object }) => {
          try {
            const metadataObject = results.data.reduce<Record<string, MetadataCsv>>((acc, item) => {
              const metadata: Record<string, any> = {};
              const workflows: Record<string, any> = {};
              const otherFields: Record<string, any> = {};
              let tags: Array<string> = [];

              Object.entries(item).forEach(([key, value]) => {
                if (key.startsWith('metadata.')) {
                  const newKey = key.replace('metadata.', '');
                  metadata[newKey] = value;
                } else if (key.startsWith('workflow.')) {
                  const newKey = key.replace('workflow.', '');
                  workflows[newKey] = value;
                } else if (key === 'tags') {
                  tags = JSON.parse((value as string).replace(/\b(\w+)\b/g, '"$1"'));
                } else if (key === 'filename') {
                  otherFields['filename'] = capitalizeFileExtension(value as string);
                } else {
                  otherFields[key] = value;
                }
              });

              const fileKey = otherFields.filename
                ? otherFields.filename.startsWith('./')
                  ? otherFields.filename.slice(2)
                  : otherFields.filename.startsWith('/')
                  ? otherFields.filename.slice(1)
                  : otherFields.filename
                : null;

              acc[fileKey] = { ...otherFields, metadata, workflows, tags } as MetadataCsv;
              return acc;
            }, {});

            setCvsData(metadataObject);
          } catch (err: any) {
            errorLog('Invalid CSV Data:', err);
            toast({
              variant: 'destructive',
              title: 'Invalid CSV Data',
              description: err.message ?? 'Something went wrong',
            });
          }
        },
      });
    };

    reader.onerror = () => {
      warn('Error reading file');
    };

    reader.readAsText(file);
  };

  const formFields: Array<any> = useMemo(
    () => [
      {
        'name': 'Upload folder',
        'slug': 'upload-folder',
        'description': 'Select a folder where the uploaded assets will be stored.',
        'type': 'file',
        'value': uploadFolderPath,
        'readOnly': 'readonly',
        'data-cy': 'upload-folder-field',
        'validation': z.string(),
        'ref': uploadFolderRef,
        'disabled': uploadState !== UploadState.NotStarted,
        'appendIcon': (
          <Button
            onClick={(e) => {
              e.preventDefault();
              openModal('uploadFolder');
              if (csvRef?.current) {
                csvRef.current.value = '';
              }
            }}
            disabled={uploadState !== UploadState.NotStarted}
            variant="ghost"
            className="h-10 rounded-none p-2"
          >
            <FolderOpen className="size-4 text-gray-icon-color" />
          </Button>
        ),
        'onClick': () => {
          openModal('uploadFolder');
        },
      },
      {
        'name': 'Keep existing folders',
        'slug': 'keep-existing-folders',
        'description':
          'When adding a directory, the existing directory hierarchy will be preserved and assets will be imported in the selected folder inside new sub-folders matching the asset path relative to the added directory.',
        'type': 'boolean',
        'checked': keepExistingFolders,
        'data-cy': 'keep-existing-folders-switch',
        'onClick': () => {
          toggleKeepExistingFolders();
        },
      },
      {
        'name': 'Replace assets matching filename',
        'slug': 'replace-assets-matching-filename',
        'description':
          'When a file name matches an asset ID, upload as a new version of that asset and activate the new version.',
        'type': 'boolean',
        'checked': replaceAssetName,
        'data-cy': 'replace-asset-name-switch',
        'onClick': () => {
          toggleReplaceAssetName();
        },
      },
      {
        'name': 'Metadata CSV',
        'slug': 'metadata-csv',
        'description': 'Select a CSV file containing metadata to apply to the uploaded assets.',
        'type': 'file',
        'withUploader': true,
        'value': csvFileName,
        'readOnly': 'readonly',
        'accept': '.csv',
        'data-cy': 'upload-metadata-csv-field',
        'ref': csvRef,
        'onInput': handleCsvFileChange,
        'appendIcon': csvFileName ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setCsvFileName('');
              setCvsData({});
              if (csvRef?.current) {
                csvRef.current.value = '';
              }
            }}
            variant="ghost"
            className="h-10 rounded-none p-2"
          >
            <X className="size-4 text-gray-icon-color" />
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleCsvClick();
            }}
            variant="ghost"
            className="h-10 rounded-none p-2"
          >
            <FileSpreadsheet className="size-4 text-gray-icon-color" />
          </Button>
        ),
        'onClick': handleCsvClick,
      },
    ],
    [
      keepExistingFolders,
      replaceAssetName,
      openModal,
      uploadFolderPath,
      toggleKeepExistingFolders,
      toggleReplaceAssetName,
      csvFileName,
      uploadState,
    ],
  );

  const formSchema = z.object(
    formFields.reduce(
      (acc, field) => {
        if (field.validation) {
          acc[field.slug] = field.validation;
        }
        return acc;
      },
      {} as Record<string, z.ZodType<any>>,
    ),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const setUploadProgress = (progress: number, id: string) => {
    setAssetsUploadProgress(id, progress, false);
    setAverageProgress();
  };

  const setOnErrorAsset = (id: string, error: any) => {
    setErroredAssets(id, error);
    setAverageProgress();
  };

  const updateFileWithRetry = (file: { id: string; name: string }, assetId: string, delays = [2000, 3000, 5000]) => {
    let attempt = 0;
    let isUpdated = false;
    const tryUpdate = () => {
      if (isUpdated) {
        return;
      }
      for (const folder in filesByFolder) {
        const fileIndex = filesByFolder[folder].findIndex((f) => f.id === file.id);

        if (fileIndex !== -1) {
          filesByFolder[folder][fileIndex].id = assetId;
          isUpdated = true;
          removeAssetsUploadProgress(file.id);
          setAssetsUploadProgress(assetId, UploadFinishedPercentage, true);
          if (useUploadStore.getState().selectedAssetIds[0]?.id === file.id) {
            useUploadStore.setState((state) => ({
              ...state,
              selectedAssetIds: [{ id: assetId, name: file.name }],
            }));
          }
          return;
        }
      }

      if (attempt < delays.length) {
        setTimeout(tryUpdate, delays[attempt]);
        attempt++;
      }
    };

    tryUpdate();
  };

  const onSubmit = async () => {
    try {
      useUploadStore.setState((state) => ({
        ...state,
        selectedAssetIds: [],
      }));

      setUploadStatus('uploading');
      setUploadState(UploadState.Uploading);
      await PromisePool.withConcurrency(5)
        .for(filesData)
        .process(async (file: ExtendedFileWithPath, i, pool) => {
          if (useUploadStore.getState().uploadState === UploadState.Canceled) {
            pool.stop();
          }
          const fileKey = file.path
            ? file.path.startsWith('./')
              ? file.path.slice(2)
              : file.path.startsWith('/')
              ? file.path.slice(1)
              : file.path
            : null;
          const {
            tags = [],
            workflows = {},
            metadata = {},
            name = '',
          } = fileKey ? csvData[capitalizeFileExtension(fileKey)] ?? {} : {};

          const result = await createAsset({
            folderId: reusableTreeSelectedFolder!,
            file,
            onProgress: setUploadProgress,
            assetId: replaceAssetName ? file.name.replace(/\.[^/.]+$/, '') : undefined,
            originalFileName: keepExistingFolders ? file.name : file.path,
            temporaryId: file.id,
            onError: setOnErrorAsset,
            metadata,
            workflows,
            tags,
            name,
          });

          if (result?.assetId) {
            const assetId = result.assetId;
            updateFileWithRetry(file, assetId);
          }
        });

      if (Object.keys(useUploadStore.getState().erroredAssets).length === 0) {
        setUploadStatus('success');
      } else {
        setUploadStatus('error');
      }
      setUploadState(UploadState.Finished);
      void queryClient.invalidateQueries({ queryKey: ['tree'] });
    } catch (err) {
      errorLog('Action failed:', err);
      setUploadStatus('failed');
      setUploadState(UploadState.Finished);
    }
  };

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      defaultValue={['ingestion']}
      className="grow"
    >
      <AccordionItem value="ingestion" className="h-full">
        <AccordionTrigger>Ingestion</AccordionTrigger>
        <AccordionContent className="flex max-h-[calc(100vh-120px)] min-h-[calc(100vh-97px)] flex-col overflow-y-auto p-0">
          <div className="grow">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-6 p-3 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {formFields.map((field) => renderField(form, field, 'hidden'))}
              </form>
            </FormProvider>
          </div>
          <div className="flex flex-col justify-end">
            <Button
              disabled={uploadState === UploadState.Uploading || filesData.length === 0}
              variant={'default'}
              className="!mt-0 w-full justify-center rounded-none !bg-light-ui-color !text-text-color"
              data-cy="clear-list-button"
              onClick={() => clearAllFiles()}
            >
              Clear list
            </Button>
            <Button
              disabled={uploadState === UploadState.Uploading || filesData.length === 0 || !reusableTreeSelectedFolder}
              type="submit"
              variant={'default'}
              className="!mt-0 w-full justify-center rounded-none !bg-highlight-color"
              data-cy="start-upload-button"
              onClick={onSubmit}
            >
              {uploadState === UploadState.Uploading ? (
                <>
                  <Loader2 className="mr-2 size-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                <>Start upload</>
              )}
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
