import React, { ElementType, FC, RefObject, useState } from 'react';
import Image from 'next/image';
import {
  File,
  FileText,
  FileImage,
  FileVideo,
  FileAudio,
  Play,
  LucideIcon,
  LucideFolder,
  BookImage,
  FileSpreadsheet,
  FileArchive,
  FileCode,
  FileChartLine,
} from 'lucide-react';
import { FileType } from '@/types/fileType';
import { cn } from '@/lib/utils';

export type AssetThumbnailProps = {
  name: string;
  type?: string;
  thumbnail?: string;
  draggable?: boolean;
  width?: number;
  height?: number;
  imageRef?: RefObject<HTMLImageElement>;
  iconContainerRef?: RefObject<HTMLImageElement>;
  overlayIcon?: LucideIcon;
  iconStrokeWidth?: number;
  fill?: boolean;
  onLoad?: () => void;
  imageClassName?: string;
  iconClassName?: string;
  imageContainerClassName?: string;
  iconContainerClassName?: string;
  playIconClassName?: string;
  overlayIconClassName?: string;
  onDoubleClick?: () => void;
  imageDescription?: React.ReactElement;
};

export const AssetThumbnail: FC<AssetThumbnailProps> = ({
  name,
  type,
  thumbnail,
  draggable = false,
  width,
  height,
  imageRef,
  iconContainerRef,
  overlayIcon: Icon,
  iconStrokeWidth = 1,
  fill = true,
  onLoad,
  imageClassName = '',
  iconClassName = '',
  imageContainerClassName = 'relative',
  iconContainerClassName = '',
  playIconClassName = '',
  overlayIconClassName = '',
  onDoubleClick,
  imageDescription = null,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImage = (src: string) => (
    <div className={imageContainerClassName}>
      {type === FileType.Video && (
        <Play
          className={cn(
            'absolute z-10 stroke-white drop-shadow-[1px_1px_3px_hsla(0,0%,0%,0.25)]',
            playIconClassName ? playIconClassName : 'bottom-4 left-4',
          )}
          strokeWidth={1.5}
        />
      )}
      {Icon && (
        <Icon
          className={cn(
            'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform stroke-white',
            overlayIconClassName,
          )}
          strokeWidth={1.5}
        />
      )}
      <Image
        width={width}
        height={height}
        ref={imageRef}
        alt={name}
        onLoad={onLoad}
        onError={handleImageError}
        src={src}
        draggable={draggable}
        fill={fill}
        className={imageClassName}
        onDoubleClick={onDoubleClick}
      />
      {imageDescription && imageDescription}
    </div>
  );

  const renderIcon = (IconComponent: ElementType) => (
    <div className={imageContainerClassName}>
      {Icon && (
        <Icon
          className={cn(
            'absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform stroke-white',
            overlayIconClassName,
          )}
          strokeWidth={1.5}
        />
      )}
      <div ref={iconContainerRef} className={iconContainerClassName}>
        <IconComponent className={iconClassName} strokeWidth={iconStrokeWidth} />
      </div>
    </div>
  );

  if (thumbnail && !imageError) {
    return renderImage(thumbnail);
  }

  switch (type) {
    case FileType.Image:
      return renderIcon(FileImage);
    case FileType.Video:
      return renderIcon(FileVideo);
    case FileType.Audio:
      return renderIcon(FileAudio);
    case FileType.Document:
      return renderIcon(FileText);
    case FileType.Folder:
      return renderIcon(LucideFolder);
    case FileType.Album:
      return renderIcon(BookImage);
    case 'video/mp4':
      return renderIcon(FileVideo);
    case 'text/plain':
    case 'application/rtf':
    case 'application/pdf':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return renderIcon(FileText);
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return renderIcon(FileChartLine);
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return renderIcon(FileSpreadsheet);
    case 'application/zip':
    case 'application/gzip':
    case 'application/x-rar-compressed':
      return renderIcon(FileArchive);
    case 'text/xml':
    case 'application/xml':
      return renderIcon(FileCode);
    case FileType.Other:
    default:
      return renderIcon(File);
  }
};
