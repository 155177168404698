import { ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase } from 'react-select';
import { ChevronDown, X } from 'lucide-react';

// Add spellcheck property to the input element
export const CustomInput = (props: any) => <components.Input {...props} spellCheck={true} />;

export const DropdownIndicator = <TOption, IsMulti extends boolean, Group extends GroupBase<TOption>>(
  props: DropdownIndicatorProps<TOption, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <ChevronDown className="size-6" strokeWidth={2} />
  </components.DropdownIndicator>
);

export const ClearIndicator = <TOption, IsMulti extends boolean, Group extends GroupBase<TOption>>(
  props: ClearIndicatorProps<TOption, IsMulti, Group>,
) => (
  <components.ClearIndicator {...props}>
    <X className="size-6" strokeWidth={2} />
  </components.ClearIndicator>
);
