import { z } from 'zod';
import { paginationSchema } from '@/types/pagination';

export const assetNoteSchema = z.object({
  id: z.string(),
  assetId: z.string(),
  note: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  createdAt: z.string(),
  updatedBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  updatedAt: z.string(),
});

export type AssetNote = z.infer<typeof assetNoteSchema>;

export const getAssetNotesSchema = z.object({
  notes: z.array(assetNoteSchema),
  pagination: paginationSchema,
});
