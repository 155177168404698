import { FC, useEffect, useRef, useState, RefObject, useCallback, useMemo } from 'react';
import { FormField, FormItem, FormLabel, FormMessage, FormControl, FormDescription } from '@/components/ui/form';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { Asset, AssetWorkflowMetadata, assetWorkflowMetadataSchema } from '@/types/asset';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { MetadataField, PublicMetadataField } from '@/types/metadata';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { Info, Loader2, RectangleEllipsis, Save } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useForm, FormProvider } from 'react-hook-form';
import { throttle, debounce } from 'lodash';
import { useBatchEditAssets } from '@/hooks/data/assets/useBatchEditAssets';
import { saveAssetMetadata } from '@/services/metadata.service';
import { FieldComponents } from '@/components/inspector/metadata-accordion/metadata-field-components';
import { useMetadataList } from '@/hooks/metadata/useMetadata';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { zodResolver } from '@hookform/resolvers/zod';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { ulid } from 'ulid';
import { log } from '@/utilities/log';
import { cn } from '@/lib/utils';
import { z } from 'zod';
import { ObjectAction, ObjectType } from '@/types/batch';

export const MetadataAccordion: FC<{
  currentAccordionItems: Array<string>;
  asset?: Asset;
  scrollRef?: RefObject<HTMLDivElement>;
  multipleAssetsMetadata?: AssetWorkflowMetadata;
  selectedAssetIds?: Array<{ id: string; name: string }>;
  folderId?: string;
  albumId?: string;
  disabled?: boolean;
  readOnly?: boolean;
}> = ({
  currentAccordionItems,
  asset,
  scrollRef,
  multipleAssetsMetadata,
  selectedAssetIds,
  folderId,
  albumId,
  disabled,
  readOnly = false,
}) => {
  const { toast } = useToast();
  const { isAuthenticated } = useAuth0();
  const { isPublicRoute } = useCurrentPage();
  const { batchEditAssetsMutation } = useBatchEditAssets();

  const metadataValues = useMemo(() => {
    if (multipleAssetsMetadata) {
      return multipleAssetsMetadata;
    } else if (asset?.metadata) {
      return asset.metadata;
    }
  }, [asset, multipleAssetsMetadata]);

  const queryClient = useQueryClient();
  const saveButtonRef = useRef<HTMLDivElement>(null);
  const formContainerRef = useRef<HTMLFormElement>(null);

  // State to track "Save Metadata" button position
  const [isAtBottom, setIsAtBottom] = useState(false);

  const { metadataList, metadataListIsFetching } = useMetadataList(
    {
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: isAuthenticated },
  );

  const { metadata: privateMetadata } = metadataList;

  const metadata = useMemo(() => {
    return isPublicRoute
      ? Object.values(metadataValues as unknown as Array<PublicMetadataField>) ?? []
      : privateMetadata;
  }, [isPublicRoute, metadataValues, privateMetadata]);

  const publicMetadataValues = useMemo(() => {
    return (
      isPublicRoute &&
      Object.fromEntries(
        Object.entries(metadataValues as unknown as Array<PublicMetadataField>).map(([key, obj]) => [key, obj.value]),
      )
    );
  }, [metadata, isPublicRoute]);

  const form = useForm({
    resolver: zodResolver(assetWorkflowMetadataSchema),
    defaultValues: isPublicRoute
      ? (publicMetadataValues as Record<string, any>)
      : (metadataValues as Record<string, any>),
  });

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  // Reset the form when the asset changes, helps with isFormEdited being marked as dirty if the component remounts
  useEffect(() => {
    form.reset(isPublicRoute ? (publicMetadataValues as Record<string, any>) : (metadataValues as Record<string, any>));
  }, [metadataValues, form]);

  const isFormEdited = form.formState.isDirty;
  const bottomMargin = 20;

  const handleScroll = useCallback(() => {
    const formContainer = formContainerRef.current;
    if (formContainer) {
      const rect = formContainer.getBoundingClientRect();
      const isVisible = rect.bottom <= window.innerHeight - bottomMargin && rect.bottom >= 0;
      setIsAtBottom(isVisible);
    }
  }, [bottomMargin]);

  const throttledHandleScroll = throttle(handleScroll, 1000);
  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    const container = scrollRef?.current;
    if (container) {
      container.addEventListener('scroll', throttledHandleScroll);
      container.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', throttledHandleScroll);
        container.removeEventListener('scroll', debouncedHandleScroll);
      }
    };
  }, [scrollRef, throttledHandleScroll, debouncedHandleScroll]);

  const saveAssetMetadataWithAuth = useAuthenticatedQueryFn(saveAssetMetadata);

  const saveAssetMetadataMutation = useMutation({
    mutationFn: saveAssetMetadataWithAuth,
    onSuccess: () => {
      toast({
        title: 'Metadata Saved',
        description: 'Metadata has been saved successfully.',
      });

      void queryClient.invalidateQueries({ queryKey: ['asset'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      log(err);
    },
  });

  function onSubmit(values: z.infer<typeof assetWorkflowMetadataSchema>) {
    if (multipleAssetsMetadata && values) {
      // Extract only changed fields
      const changedMetadata = Object.keys(form.formState.dirtyFields).reduce(
        (acc, key) => {
          acc[key] = values[key];
          return acc;
        },
        {} as Record<string, any>,
      );

      batchEditAssetsMutation.mutate({
        objectType: ObjectType.Asset,
        objectAction: ObjectAction.Update,
        filter: {
          ...(selectedAssetIds && { assetIds: selectedAssetIds.map((selectedAsset) => selectedAsset.id) }),
          ...(folderId && { folderId }),
          ...(albumId && { albumId }),
        },
        payload: {
          metadata: changedMetadata,
        },
      });
    } else {
      saveAssetMetadataMutation.mutate({
        asset_id: asset?.id,
        metadata: values,
      });
    }
  }

  const isLoading = saveAssetMetadataMutation.status === 'pending' || batchEditAssetsMutation.status === 'pending';

  const getPublicMetadataOptions = (field: PublicMetadataField) => {
    let options: Array<{ value: string; label: string }> = [];
    if (field.type === 'multi_select') {
      const fieldValue = field.value as Array<string>;
      options = fieldValue.map((value: string) => ({ value, label: value.charAt(0).toUpperCase() + value.slice(1) }));
    } else if (field.type === 'select') {
      const fieldValue = field.value as string;
      options = [
        {
          value: fieldValue,
          label: fieldValue,
        },
      ];
    }
    return options;
  };

  const getMetadataOptions = (field: MetadataField) => {
    let options: Array<{ value: string; label: string }> = [];
    if (Array.isArray(field.options)) {
      options = [];
    } else if (field.options?.choices) {
      options = Object.entries(field.options.choices).map(([value, label]) => ({ value, label }));
    }
    return options;
  };

  const renderField = (field: MetadataField | PublicMetadataField) => {
    const Component = FieldComponents[field.type];
    if (!Component) {
      return null;
    }

    const options: Array<{ value: string; label: string }> = isPublicRoute
      ? getPublicMetadataOptions(field as PublicMetadataField)
      : getMetadataOptions(field as MetadataField);

    return (
      <FormField
        key={field.slug}
        disabled={readOnly}
        name={field.slug}
        control={form.control}
        render={({ field: controllerField }) => (
          <>
            <FormItem
              className={cn(
                'flex @[30rem]/inspector:gap-x-3',
                field.type === 'boolean'
                  ? 'flex-row flex-wrap gap-x-3 @[24rem]/inspector:items-center @[24rem]/inspector:justify-between @[30rem]/inspector:flex-row @[30rem]/inspector:flex-nowrap @[30rem]/inspector:justify-normal'
                  : 'flex-col @[30rem]/inspector:flex-row',
              )}
            >
              <div
                className={cn(
                  'flex items-center gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end',
                  field.type === 'boolean' && 'grow @[30rem]/inspector:grow-0',
                )}
              >
                <FormLabel
                  htmlFor={field.slug}
                  className={cn(
                    'leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:text-right',
                    field.type === 'boolean' ? '@[30rem]/inspector:mr-1.5' : '@[30rem]/inspector:mt-2',
                  )}
                >
                  {field.name}
                </FormLabel>
                {field.description && (
                  <TooltipProvider key={ulid()} delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger
                        asChild
                        className={cn(
                          'hidden @[30rem]/inspector:order-1',
                          field.type !== 'boolean'
                            ? '@[24rem]/inspector:flex @[30rem]/inspector:mt-2'
                            : '@[30rem]/inspector:flex',
                        )}
                      >
                        <Info className="size-3.5 text-neutral-600" />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent className="max-w-[300px]">
                          <p>{field.description}</p>
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
              <FormControl
                className={cn(field.type === 'boolean' ? '!mt-0' : '@[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2')}
              >
                <div className="relative">
                  <Component
                    {...controllerField}
                    placeholder={field.type === 'date' ? 'YYYY-MM-DD' : field.name}
                    options={options}
                    data-cy={`${field.slug}-metadata-field`}
                    {...(field.type === 'boolean' && multipleAssetsMetadata && controllerField.value === undefined
                      ? { checked: 'indeterminate' }
                      : { checked: controllerField.value })}
                    indeterminate={multipleAssetsMetadata && !controllerField.value}
                    onChange={(value: any) => {
                      if (field.type === 'multi_select') {
                        const newValues = (value as Array<{ value: string; label: string }>).map(
                          (option) => option.value,
                        );
                        controllerField.onChange(newValues);
                      } else {
                        controllerField.onChange(value);
                      }
                    }}
                    disabled={readOnly}
                    className={cn(
                      field.type === 'text' && 'w-full',
                      ['text', 'string', 'float', 'integer', 'select', 'multi_select', 'date'].includes(field.type) &&
                        '@[30rem]/inspector:!mt-0',
                      field.type === 'boolean' && '@[30rem]/inspector:!mt-0.5',
                      ['text', 'string', 'float', 'integer', 'select', 'date'].includes(field.type) &&
                        'bg-input-background focus-within:ring-offset-white dark:focus-within:ring-offset-neutral-950',
                      field.type === 'date' &&
                        'bg-input-background focus-within:ring-offset-neutral-100 hover:bg-white dark:focus-within:ring-offset-neutral-950 dark:hover:bg-neutral-950',
                      ['text', 'string', 'float', 'integer', 'date'].includes(field.type) && '!pr-0',
                    )}
                    // Add Spellcheck to text, string or multi_select fields
                    {...(['text', 'string', 'multi_select'].includes(field.type) && { spellCheck: true })}
                    // Include Input's `appendIcon` property with an "Clear" button only if field type is text, string, float, date or integer and if controllerField.value contains value, but remove it if readOnly is true
                    {...(['text', 'string', 'float', 'integer', 'date'].includes(field.type) &&
                      !readOnly &&
                      controllerField.value && {
                        appendIcon: (
                          <Button
                            onClick={() => controllerField.onChange('')}
                            variant="ghost"
                            className="mr-px min-h-6 px-1 py-0 text-xs !text-neutral-400 duration-150 hover:bg-transparent hover:!text-red-300 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-red-800 dark:focus-visible:ring-neutral-900"
                            aria-label="Clear field"
                          >
                            Clear
                          </Button>
                        ),
                      })}
                  />
                  <FormMessage />
                </div>
              </FormControl>
              {field.description && (
                <FormDescription
                  className={cn(
                    'block',
                    field.type === 'boolean' ? 'grow @[30rem]/inspector:hidden' : '@[24rem]/inspector:hidden',
                  )}
                >
                  {field.description}
                </FormDescription>
              )}
            </FormItem>
            <Separator className={cn('block @[24rem]/inspector:hidden', readOnly && 'last:hidden')} />
          </>
        )}
      />
    );
  };

  return (
    <AccordionItem value="metadata" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Metadata</AccordionTrigger>
      <AccordionContent className="m-0 flex flex-col px-3 pb-0 pt-3" contentContainerClassName="!overflow-visible">
        {metadataListIsFetching ? (
          <div className="space-y-5 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]">
            {Array.from(Array(2)).map((_, index) => (
              <div
                className="flex flex-col items-center gap-y-2 space-y-2 @[30rem]/inspector:flex-row @[30rem]/inspector:gap-x-3"
                key={index}
              >
                <div className="flex w-full items-center gap-x-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end">
                  <Skeleton className={cn('order-1 h-5 @[30rem]/inspector:order-2', index === 0 ? 'w-1/2' : 'w-1/3')} />
                  {index === 1 && <Skeleton className="order-2 size-4 rounded-full @[30rem]/inspector:order-1" />}
                </div>
                <Skeleton className="!m-0 h-8 w-full @[30rem]/inspector:w-1/2" />
              </div>
            ))}
          </div>
        ) : (
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              ref={formContainerRef}
            >
              {isPublicRoute && metadata.length === 0 && (
                <div className="pt-14 text-center text-neutral-300 dark:text-neutral-600">
                  <RectangleEllipsis className="mx-auto mb-2 " size={32} strokeWidth={1.5} />
                  No Metadata.
                </div>
              )}
              {metadata.map((field) => renderField(field))}
              {currentAccordionItems?.includes('metadata') && !readOnly && (
                <div
                  ref={saveButtonRef}
                  className="sticky inset-x-0 bottom-0 z-50 flex justify-center px-[28px] pb-6 pt-0 @[30rem]/inspector:pt-3"
                >
                  <Button
                    type="submit"
                    variant={isFormEdited ? 'default' : 'outline'}
                    className="w-full justify-center @[21rem]/inspector:w-auto"
                    disabled={metadataListIsFetching || !isFormEdited || isLoading}
                  >
                    {isLoading ? <Loader2 className="mr-2 size-4 animate-spin" /> : <Save className="mr-2 size-4" />}
                    {isLoading ? 'Saving...' : 'Save Metadata'}
                  </Button>
                  {!isAtBottom && isFormEdited && (
                    <>
                      <div className="pointer-events-none absolute inset-0 -top-14 -z-10 [background-image:linear-gradient(to_top,hsl(0_0_89)_30%,transparent_100%)] dark:[background-image:linear-gradient(to_top,hsl(0_0_11)_30%,transparent_100%)]" />
                      <div className="pointer-events-none absolute inset-0 -top-5 -z-10 backdrop-blur-[5px] [mask-image:linear-gradient(to_top,white_60%,transparent_100%)]" />
                    </>
                  )}
                </div>
              )}
            </form>
          </FormProvider>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};
