import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getAssetNotes } from '@/services/asset.service';
import { useQuery } from '@tanstack/react-query';

export const useNotes = (id: string, options?: object) => {
  const notesQueryFn = useAuthenticatedQueryFn(getAssetNotes);

  const {
    data,
    isPending: notesIsPending,
    isFetching: notesIsFetching,
    error: notesError,
  } = useQuery({
    queryKey: ['notesList', id],
    queryFn: () => notesQueryFn(id),
    ...options,
  });

  return {
    data,
    notesIsPending,
    notesIsFetching,
    notesError,
  };
};
