import { Check, X } from 'lucide-react';

export const StatusIndicator = ({ row, value }: { row: any; value: string }) => (
  <div className="flex items-center justify-center">
    {row.getValue(value) === true || row.getValue(value) ? (
      <Check data-cy={`${row.getValue(value)}-checkmark-row-id-${row.id}`} className="size-5" />
    ) : (
      <X data-cy={`${row.getValue(value)}-xicon-row-id-${row.id}`} className="size-5" />
    )}
  </div>
);
