import { FC } from 'react';
import { cn } from '@/lib/utils';
import { buttonVariants, Button } from '@/components/ui/button';
import { useCommandContext } from '@/context/CommandContext';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { DialogMode, useDialog } from '@/context/DialogContext';
import { DialogContent, Dialog, DialogHeader, DialogTitle, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { useUserSettings } from '@/context/UserSettingsContext';
import { error as errorLog, warn } from '@/utilities/log';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { useFolderOrAlbum } from '@/hooks/data/folder/useFolderOrAlbum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { createFolder, renameFolder } from '@/services/folder.service';
import { renameAlbum, createAlbum } from '@/services/album.service';
import { useTree } from '@/hooks/data/tree/useTree';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { CreateFolderCommand } from '@/hooks/commands/folder/CreateFolderCommand';
import { CreateAlbumCommand } from '@/hooks/commands/albums/CreateAlbumCommand';

const formSchema = z.object({
  name: z.string().min(2, {
    message: 'Name must be at least 2 characters.',
  }),
  description: z.string({ required_error: 'The description field must be a string.' }).optional(),
});

type EditCreateFolderAlbumDialogProps = {
  mode: DialogMode | null;
  id?: string | null;
};

export const EditCreateFolderAlbumDialog: FC<EditCreateFolderAlbumDialogProps> = ({ mode, id }) => {
  const queryClient = useQueryClient();

  const { closeModal, openDialogId, setOpenDialogId } = useDialog();
  const { showSidebar, setShowSidebar } = useUserSettings();
  const { toast } = useToast();
  const { insert } = useCommandContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const { handleError } = useFormErrorHandler(form.setError, form.getValues);
  const { item, isLoading, isFetching } = useFolderOrAlbum(openDialogId, mode, form, id ?? undefined);

  const watchedName = useWatch({ control: form.control, name: 'name' });
  const watchedDescription = useWatch({ control: form.control, name: 'description' });
  const createFolderCommand = CreateFolderCommand();
  const createAlbumCommand = CreateAlbumCommand();
  const createFolderWithAuth = useAuthenticatedQueryFn(createFolder);
  const renameFolderWithAuth = useAuthenticatedQueryFn(renameFolder);
  const renameAlbumWithAuth = useAuthenticatedQueryFn(renameAlbum);
  const createAlbumWithAuth = useAuthenticatedQueryFn(createAlbum);

  const createFolderMutation = useMutation({
    mutationFn: createFolderWithAuth,
    onSuccess: (result) => {
      if (!showSidebar) {
        setShowSidebar(true);
      }

      createFolderCommand.update({ id: result.id });
      insert(createFolderCommand);

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      form.reset({
        name: '',
        description: '',
      });
      closeModal();
    },
    onError: (err) => {
      errorLog('Action failed:', err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const createAlbumMutation = useMutation({
    mutationFn: createAlbumWithAuth,
    onSuccess: (result) => {
      if (!showSidebar) {
        setShowSidebar(true);
      }

      createAlbumCommand.update({ id: result.id });
      insert(createAlbumCommand);

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      closeModal();
    },
    onError: (err) => {
      errorLog('Action failed:', err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const renameFolderMutation = useMutation({
    mutationFn: renameFolderWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === item?.name && watchedDescription !== item?.description;

      if (!showSidebar) {
        setShowSidebar(true);
      }

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <div className="flex whitespace-nowrap">
            {onlyDescriptionChanged ? (
              <>
                The description for <span className="mx-1 inline-block max-w-24 truncate font-bold">{result.name}</span>
                has been updated.
              </>
            ) : (
              <>
                The folder has been renamed to &quot;
                <span className="inline-block max-w-24 truncate font-bold">{result.name}</span>&quot;.
              </>
            )}
          </div>
        ),
      });

      closeModal();
    },
    onError: (err: BackendError) => handleError(err),
  });

  const renameAlbumMutation = useMutation({
    mutationFn: renameAlbumWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === item?.name && watchedDescription !== item?.description;

      if (!showSidebar) {
        setShowSidebar(true);
      }

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <div className="flex whitespace-nowrap">
            {onlyDescriptionChanged ? (
              <>
                The description for <span className="mx-1 inline-block max-w-24 truncate font-bold">{result.name}</span>
                has been successfully updated.
              </>
            ) : (
              <>
                The album has been successfully renamed to &quot;
                <span className="inline-block max-w-24 truncate font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </div>
        ),
      });

      closeModal();
    },
    onError: (err: BackendError) => handleError(err),
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      switch (mode) {
        case 'createFolder':
          createFolderMutation.mutate({
            name: values.name,
            description: values.description,
            parentFolderId: id ?? undefined,
          });
          break;

        case 'createAlbum':
          createAlbumMutation.mutate({
            name: values.name,
            description: values.description,
            parentFolderId: id ?? undefined,
          });
          break;

        case 'renameFolder':
          renameFolderMutation.mutate({
            id,
            name: values.name,
            description: values.description,
          });
          break;

        case 'renameAlbum':
          renameAlbumMutation.mutate({
            id,
            name: values.name,
            description: values.description,
          });
          break;

        default:
          warn('Unknown mode:', mode);
          break;
      }
    } catch (err) {
      errorLog('Action failed:', err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    }
  }

  return (
    <Dialog
      open={openDialogId === 'editCreateFolderAlbum'}
      onOpenChange={() => {
        setOpenDialogId(null);

        form.reset({
          name: '...',
          description: '...',
        });
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex">
            <div className="whitespace-nowrap">
              {mode === 'createFolder' || mode === 'createAlbum' ? 'Create' : 'Rename'}{' '}
              {mode === 'createFolder' || mode === 'renameFolder' ? 'Folder' : 'Album'}{' '}
            </div>
            {mode === 'createFolder' || mode === 'createAlbum' ? (
              ''
            ) : isLoading || isFetching ? (
              <Skeleton className="mb-[-2px] ml-1 inline-flex h-[18px] w-[100px] rounded" />
            ) : (
              <div className="ml-1 line-clamp-1 [overflow-wrap:anywhere]">{item?.name}</div>
            )}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              disabled={isLoading || isFetching}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className={cn(
                          'focus-within:ring-offset-white dark:focus-within:ring-offset-neutral-950',
                          form.formState.errors.name && '!border-red-500 dark:focus-within:ring-red-500',
                        )}
                        isLoading={isLoading || isFetching}
                        placeholder={`Type your ${
                          mode === 'createFolder' || mode === 'renameFolder' ? 'folder' : 'album'
                        }'s name here.`}
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              disabled={isLoading || isFetching}
              render={({ field }) => (
                <FormItem className="relative">
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Textarea
                        className={cn('focus-visible:ring-offset-white dark:focus-visible:ring-offset-neutral-950')}
                        isLoading={isLoading || isFetching}
                        placeholder={`Type your ${
                          mode === 'createFolder' || mode === 'renameFolder' ? 'folder' : 'album'
                        } description here.`}
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild className="mt-2 sm:mt-0">
                <Button type="button" variant="secondary">
                  Dismiss
                </Button>
              </DialogClose>
              <Button
                type="submit"
                disabled={isLoading || isFetching}
                className={cn(
                  buttonVariants({
                    variant: 'default',
                  }),
                )}
              >
                {mode === 'createFolder' || mode === 'createAlbum' ? 'Create' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
