/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ['class'],
  content: ['./pages/**/*.{ts,tsx}', './components/**/*.{ts,tsx}', './app/**/*.{ts,tsx}', './src/**/*.{ts,tsx}'],
  theme: {
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      colors: {
        'highlight-color': 'hsl(var(--highlight-color))',
        'highlight-hover-color': 'hsl(var(--highlight-hover-color))',
        'dark-highlight-color': 'hsl(var(--dark-highlight-color))',
        'text-color': 'hsl(var(--text-color))',
        'inverted-text-color': 'hsl(var(--inverted-text-color))',
        'gray-text': 'hsl(var(--gray-text))',
        'dark-gray-text': 'hsl(var(--dark-gray-text))',
        'gray-icon-color': 'hsl(var(--gray-icon-color))',
        'gray-icon-hover-color': 'hsl(var(--gray-icon-hover-color))',
        'ul-color': 'hsl(var(--ul-color))',
        'light-ui-color': 'hsl(var(--light-ui-color))',
        'ui-bevel': 'hsl(var(--ui-bevel))',
        'separator-color': 'hsl(var(--separator-color))',
        'panel-background': 'hsl(var(--panel-background))',
        'container-background': 'hsl(var(--container-background))',
        'input-background': 'hsl(var(--input-background))',
        'void-gap': 'hsl(var(--void-gap))',
        'status-success': 'hsl(var(--status-success))',
        'status-success-hover': 'hsl(var(--status-success-hover))',
        'status-progress': 'hsl(var(--status-progress))',
        'status-failure': ({ opacityValue }) =>
          opacityValue !== undefined ? `hsl(var(--status-failure), ${opacityValue})` : `hsl(var(--status-failure))`,
        'status-failure-hover': 'hsl(var(--status-failure-hover))',
        'status-warning': ({ opacityValue }) =>
          opacityValue !== undefined ? `hsl(var(--status-warning), ${opacityValue})` : `hsl(var(--status-warning))`,
        'album-color': 'hsl(var(--album-color))',
        'smart-folder-color': 'hsl(var(--smart-folder-color))',
        'upload-color': 'hsl(var(--upload-color))',
        'toolbar-bevel': 'hsl(var(--toolbar-bevel))',
        'toolbar-icon': 'hsl(var(--toolbar-icon))',
      },
      keyframes: {
        'accordion-down': {
          from: { height: 0 },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: 0 },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },
  plugins: [require('tailwindcss-animate'), require('@tailwindcss/container-queries')],
  safelist: ['grid-cols-2', 'grid-cols-3', 'grid-cols-4', 'grid-cols-5', 'grid-cols-6'],
};
