import { FC, useCallback, useEffect, useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { AssetKeywordSelect } from '@/components/inspector/asset-keyword-select';
import { Asset } from '@/types/asset';
import { useAutocompleteTags } from '@/hooks/tags/useTags';
import { useMutation } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { updateAsset } from '@/services/asset.service';
import { useToast } from '@/components/ui/use-toast';
import { Option } from '@/types/select-option';
import { debounce } from 'lodash';
import { useCommandContext } from '@/context/CommandContext';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { ToastAction } from '@/components/ui/toast';
import { DeleteTagsCommand } from '@/hooks/commands/tags/DeleteTagsCommand';
import { useAuth0 } from '@auth0/auth0-react';
import { TrafficCone } from 'lucide-react';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { useBatchEditAssets } from '@/hooks/data/assets/useBatchEditAssets';
import { ObjectAction, ObjectType } from '@/types/batch';

export const TagsAccordion: FC<{
  asset?: Asset;
  currentAccordionItems: Array<string>;
  multipleAssetsTags?: { isFullIntersection: boolean; tags: Array<string> };
  disabled?: boolean;
  selectedAssetIds?: Array<{ id: string; name: string }>;
  folderId?: string;
  albumId?: string;
  readOnly?: boolean;
}> = ({
  asset,
  currentAccordionItems,
  multipleAssetsTags,
  disabled,
  selectedAssetIds,
  folderId,
  albumId,
  readOnly = false,
}) => {
  const { toast } = useToast();
  const { apply, undo } = useCommandContext();
  const { isAuthenticated } = useAuth0();
  const { isPublicRoute } = useCurrentPage();
  const { batchEditAssetsMutation } = useBatchEditAssets();

  const [values, setValues] = useState<Array<Option> | []>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (multipleAssetsTags) {
      setValues(multipleAssetsTags.tags.map((tag) => ({ label: tag, value: tag })));
    } else if (asset?.tags) {
      setValues(asset.tags.map((tag) => ({ label: tag, value: tag })));
    }
  }, [asset, multipleAssetsTags]);

  const debounceInputChange = useCallback(
    debounce((value) => {
      setInputValue(value);
    }, 300),
    [],
  );

  const handleInputChange = (newValue: string) => {
    if (newValue.length >= 1) {
      debounceInputChange(newValue);
    } else {
      setInputValue('');
    }
  };

  const { tags, areTagsFetching } = useAutocompleteTags(
    { q: inputValue },
    {
      enabled:
        Boolean(inputValue) && inputValue.length >= 1 && currentAccordionItems.includes('tags') && isAuthenticated,
    },
  );

  const updateAssetTags = (assetId: string, newTags: Array<string>) => {
    updateAssetMutation.mutate({
      id: assetId,
      body: { tags: newTags },
    });
  };

  const handleCreate = (newTag: string) => {
    const newValues = [...values.map((tag) => tag.value), newTag];

    if (multipleAssetsTags) {
      batchEditAssetsMutation.mutate({
        objectType: ObjectType.Asset,
        objectAction: ObjectAction.Update,
        filter: {
          ...(selectedAssetIds && { assetIds: selectedAssetIds.map((selectedAsset) => selectedAsset.id) }),
          ...(folderId && { folderId }),
          ...(albumId && { albumId }),
        },
        payload: {
          tags: newValues,
        },
      });
    } else {
      updateAssetTags(asset?.id as string, newValues);
    }

    setValues(newValues.map((tag) => ({ label: tag, value: tag })));
  };

  const updateAssetWithAuth = useAuthenticatedQueryFn(updateAsset);

  const updateAssetMutation = useMutation({
    mutationFn: updateAssetWithAuth,
    onSuccess: (result) => {
      toast({
        title: 'Asset Tags Updated',
        description: `"${result.name}" tags have been successfully updated.`,
      });
    },
    onError: () => {
      toast({
        title: 'Error',
        description: `Asset tags were not updated.`,
      });
    },
  });

  const onChange = (newOptions: Array<Option>, context: any) => {
    if (multipleAssetsTags) {
      batchEditAssetsMutation.mutate({
        objectType: ObjectType.Asset,
        objectAction: ObjectAction.Update,
        filter: {
          ...(selectedAssetIds && { assetIds: selectedAssetIds.map((selectedAsset) => selectedAsset.id) }),
          ...(folderId && { folderId }),
          ...(albumId && { albumId }),
        },
        payload: {
          tags: newOptions.map((tag) => tag.value),
        },
      });
    } else {
      if (context.action === 'clear') {
        const oldTags = values.map((tag) => tag.value);
        const newTags = [] as Array<any>;

        const deleteCommand = DeleteTagsCommand(asset?.id as string, oldTags, newTags, updateAssetTags);
        apply(deleteCommand);
        setValues(newTags);

        toast({
          title: 'Asset Tags Cleared',
          description: 'Asset tags have been cleared.',
          action: (
            <ToastAction
              onClick={() => {
                undo();
                setValues(oldTags.map((tag) => ({ label: tag, value: tag })));
              }}
              altText="Undo tag change"
            >
              Undo
              <ArrowUturnLeftIcon className="ml-1 size-3" />
            </ToastAction>
          ),
        });
      } else {
        updateAssetTags(
          asset?.id as string,
          newOptions.map((tag) => tag.value),
        );

        setValues(newOptions);
        setInputValue('');
      }
    }
  };

  return (
    <AccordionItem value="tags" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Tags</AccordionTrigger>
      {isPublicRoute && values.length === 0 ? (
        <AccordionContent className="py-8 text-center text-neutral-300 dark:text-neutral-600">
          <TrafficCone className="mx-auto mb-2" size={28} strokeWidth={1.5} />
          No tags
        </AccordionContent>
      ) : (
        <AccordionContent className="m-1 flex flex-col gap-3">
          <AssetKeywordSelect
            disabled={readOnly}
            options={(tags?.suggestions ?? []).map((tag) => ({ label: tag.name, value: tag.name }))}
            value={values}
            onChange={(newOptions, context) => onChange(newOptions as Array<Option>, context)}
            onCreateOption={handleCreate}
            isLoading={areTagsFetching}
            onInputChange={handleInputChange}
            placeholder="Type to search for tags..."
            indeterminate={Boolean(multipleAssetsTags) && !multipleAssetsTags?.isFullIntersection}
          />
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
