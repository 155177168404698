import { UploadAsset, UploadState, UploadStatus } from '@/types/uploads';
import { Ref, useRef } from 'react';
import { FileWithPath } from 'react-dropzone';
import { create } from 'zustand';

export type ExtendedFileWithPath = FileWithPath & {
  folderPath: string;
  preview: string;
  id: string;
};

interface UploadStore {
  uploadModalOpen: boolean;
  setUploadModalOpen: (value: boolean) => void;
  uploadFolderPath: string;
  setUploadFolderPath: (value: string) => void;
  filesByFolder: Record<string, Array<UploadAsset>>;
  setFiles: (folderPath: string, files: Array<UploadAsset>) => void;
  filesData: Array<ExtendedFileWithPath>;
  setFilesData: (filesData: Array<ExtendedFileWithPath>) => void;
  totalAssetsToUpload: number;
  setTotalAssetsToUpload: (value: number) => void;
  uploadState: UploadState;
  setUploadState: (value: UploadState) => void;
  assetsUploadProgress: Record<string, { progress: number; uploaded: boolean }>;
  setAssetsUploadProgress: (id: string, progress: number, uploaded: boolean) => void;
  removeAssetsUploadProgress: (id: string) => void;
  erroredAssets: Record<string, string>;
  setErroredAssets: (id: string, error: string) => void;
  averageProgress: number;
  setAverageProgress: () => void;
  clearAllFiles: () => void;
  errors: Array<string>;
  setErrors: () => void;
  removeFolder: (folderPath: string) => void;
  selectedAssetIds: Array<{ id: string; name: string }>;
  removeAssets: () => void;
  acceptedTypes: Record<string, Array<string>>;
  uploadStatus: UploadStatus;
  setUploadStatus: (status: UploadStatus) => void;
  getFileById: (fileId: string) => UploadAsset | null;
  keepExistingFolders: boolean;
  replaceAssetName: boolean;
  toggleKeepExistingFolders: () => void;
  toggleReplaceAssetName: () => void;
  isAssetsProcessingCanceled: boolean;
  setAssetsProcessingCanceled: (value: boolean) => void;
}

export const useUploadStore = create<UploadStore>((set, get) => ({
  acceptedTypes: {
    //Images
    'image/png': ['.png'],
    'image/jpg': ['.jpg', '.jpeg', '.pjpeg'],
    'image/gif': ['.gif'],
    'image/tiff': ['.tiff', '.tif'],
    //RAW
    'image/x-sony-arw': ['.arw'],
    'image/x-canon-cr2': ['.cr2'],
    'image/x-canon-crw': ['.crw'],
    'image/x-kodak-dcr': ['.dcr'],
    'image/x-adobe-dng': ['.dng'],
    'image/x-epson-erf': ['.erf'],
    'image/x-kodak-k25': ['.k25'],
    'image/x-kodak-kdc': ['.kdc'],
    'image/x-minolta-mrw': ['.mrw'],
    'image/x-nikon-nef': ['.nef'],
    'image/x-olympus-orf': ['.orf'],
    'image/x-pentax-pef': ['.pef'],
    'image/x-fuji-raf': ['.raf'],
    'image/x-panasonic-raw': ['.raw', '.rw2'],
    'image/x-sony-sr2': ['.sr2'],
    'image/x-sony-srf': ['.srf'],
    'image/x-sigma-x3f': ['.x3f'],
    'image/x-hasselblad-3fr': ['.3fr'],
    'image/x-portable-pixmap': ['.ppm'],
    'image/x-minolta-raw': ['.mos'],
    'image/x-leica-raw': ['.dng'],
    'image/x-mamiya-mef': ['.mef'],
    'image/x-minolta-mdc': ['.mdc'],
    'image/x-nikon-nrw': ['.nrw'],
    'image/x-samsung-srw': ['.srw'],
    //Documents
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'text/xml': ['.xml'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/rtf': ['.rtf'],
    'application/xml': ['.xml'],
    'application/zip': ['.zip'],
    'application/gzip': ['.gz'],
    //Videos
    'video/mp4': ['.mp4'],
    'video/mov': ['.mov'],
  },
  uploadModalOpen: false,
  setUploadModalOpen: (value: boolean) =>
    set((state) => ({
      ...state,
      uploadModalOpen: value,
    })),
  uploadFolderPath: '',
  setUploadFolderPath: (value: string) =>
    set((state) => ({
      ...state,
      uploadFolderPath: value,
    })),
  filesByFolder: {},
  setFiles: (folderPath: string, files: Array<UploadAsset>) =>
    set((state) => ({
      ...state,
      filesByFolder: {
        ...state.filesByFolder,
        [folderPath]: files,
      },
    })),
  filesData: [],
  setFilesData: (filesData: Array<ExtendedFileWithPath>) =>
    set((state) => ({
      ...state,
      filesData,
    })),
  totalAssetsToUpload: 0,
  setTotalAssetsToUpload: (value: number) =>
    set((state) => ({
      ...state,
      totalAssetsToUpload: value,
    })),
  uploadState: UploadState.NotStarted,
  setUploadState: (value: UploadState) =>
    set((state) => ({
      ...state,
      uploadState: value,
    })),
  assetsUploadProgress: {},
  setAssetsUploadProgress: (id: string, progress: number, uploaded: boolean) =>
    set((state) => ({
      ...state,
      assetsUploadProgress: {
        ...state.assetsUploadProgress,
        [id]: { progress, uploaded },
      },
    })),
  removeAssetsUploadProgress: (id: string) =>
    set((state) => {
      const { [id]: _, ...remainingProgress } = state.assetsUploadProgress;
      return {
        ...state,
        assetsUploadProgress: remainingProgress,
      };
    }),
  erroredAssets: {},
  setErroredAssets: (id: string, error: string) =>
    set((state) => ({
      ...state,
      erroredAssets: {
        ...state.erroredAssets,
        [id]: error,
      },
    })),
  averageProgress: 0,
  setAverageProgress: () =>
    set((state) => {
      let progress = 0;
      const values = Object.values(state.assetsUploadProgress).map((entry) => entry.progress);

      if (values.length !== 0) {
        const total = values.reduce((sum, value) => sum + value, 0);

        progress = Math.floor(total / state.totalAssetsToUpload);
      } else {
        progress = 100;
      }

      return {
        ...state,
        averageProgress: progress,
      };
    }),
  clearAllFiles: () =>
    set((state) => ({
      ...state,
      isAssetsProcessingCanceled: true,
      filesByFolder: {},
      filesData: [],
      assetsUploadProgress: {},
      uploadState: UploadState.NotStarted,
      averageProgress: 0,
      totalAssetsToUpload: 0,
      erroredAssets: {},
      uploadStatus: 'ready',
      selectedAssetIds: [],
    })),
  removeFolder: (folderPath) =>
    set((state) => {
      const updatedFilesByFolder = { ...state.filesByFolder };
      delete updatedFilesByFolder[folderPath];

      const filesData: Array<ExtendedFileWithPath> = state.filesData.filter((file) => file.folderPath !== folderPath);
      const selectedAssetIds: Array<{ id: string; name: string }> = state.selectedAssetIds.filter(
        (item) => !state.filesData.some((file) => file.folderPath === folderPath && file.id === item.id),
      );

      return {
        ...state,
        filesByFolder: updatedFilesByFolder,
        filesData,
        selectedAssetIds,
        totalAssetsToUpload: filesData.length,
      };
    }),
  errors: [],
  setErrors: () => set((state) => ({ ...state, errors: [] })),
  selectedAssetIds: [],
  removeAssets: () =>
    set((state) => {
      const updatedFilesByFolder = Object.entries(state.filesByFolder).reduce(
        (acc, [folderPath, files]) => {
          const filteredFiles = files.filter(
            (file) => !state.selectedAssetIds.some((selected) => selected.id === file.id),
          );
          if (filteredFiles.length > 0) {
            acc[folderPath] = filteredFiles;
          }
          return acc;
        },
        {} as Record<string, Array<UploadAsset>>,
      );

      const filesData: Array<ExtendedFileWithPath> = state.filesData.filter(
        (file) => !state.selectedAssetIds.some((selected) => selected.id === file.id),
      );

      return {
        ...state,
        filesByFolder: updatedFilesByFolder,
        filesData,
        selectedAssetIds: [],
        totalAssetsToUpload: filesData.length,
      };
    }),
  uploadStatus: 'ready',
  setUploadStatus: (status) => set({ ...get(), uploadStatus: status }),
  getFileById: (fileId: string) => {
    const filesByFolder = get().filesByFolder;
    for (const folder in filesByFolder) {
      const file = filesByFolder[folder].find((f) => f.id === fileId);
      if (file) {
        return file;
      }
    }
    return null;
  },
  keepExistingFolders: true,
  replaceAssetName: false,
  toggleKeepExistingFolders: () =>
    set((state) => ({
      ...state,
      keepExistingFolders: !state.keepExistingFolders,
    })),
  toggleReplaceAssetName: () =>
    set((state) => ({
      ...state,
      replaceAssetName: !state.replaceAssetName,
    })),
  isAssetsProcessingCanceled: false,
  setAssetsProcessingCanceled: (value: boolean) => set({ isAssetsProcessingCanceled: value }),
}));
