import { FC } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { LogOut, Moon, Sun, Contrast, Undo2, Redo2, User } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { defaultPaginationSize } from '@/types/pagination';
import { useCommandContext } from '@/context/CommandContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { QuickSearch } from '@/components/quick-search';
import { useLogout } from '@/hooks/data/account/useAccount';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from 'next-themes';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Tenant } from '@/types/tenant';
import { Album } from '@/types/album';
import { Folder } from '@/types/folder';
import { Asset } from '@/types/asset';
import Link from 'next/link';

export const Navigation: FC<{ tenant?: Tenant; node?: Album | Folder | Asset }> = ({ tenant, node }) => {
  const { user, logout } = useAuth0();
  const { setTheme, theme } = useTheme();
  const { push } = useRouter();
  const { undo, redo, history } = useCommandContext();
  const { isManagePage, isPublicRoute, isUploadPage, isDuplicatesPage, isTrashPage, isSearchPage } = useCurrentPage();
  const { logoutUser } = useLogout();
  const {
    query: { pageIndex, pageSize },
  } = useRouter();

  return (
    <>
      <Link
        href={{
          pathname: '/',
          query: {
            pageIndex: 1,
            pageSize: pageSize && pageIndex ? pageSize : defaultPaginationSize,
          },
        }}
        className={cn(isPublicRoute ? 'ml-2' : 'ml-4 min-w-44')}
      >
        <p className="-mb-1 line-clamp-1 text-sm text-gray-text" title={tenant?.name}>
          {tenant?.name}
        </p>
        <p className="text-base font-medium uppercase leading-5">{isPublicRoute ? node?.name : 'DAM'}</p>
      </Link>
      {!isPublicRoute && (
        <>
          <div className="flex">
            <Button
              variant="ghost"
              className="p-1"
              disabled={history.length === 0}
              title="Undo"
              data-cy="navigation-undo-button"
            >
              <Undo2 onClick={undo} className="stroke-toolbar-icon" />
            </Button>
            <Button
              variant="ghost"
              className="p-1"
              disabled={history.length === 0}
              title="Redo"
              data-cy="navigation-redo-button"
            >
              <Redo2 onClick={redo} className="stroke-toolbar-icon" />
            </Button>
          </div>
          <QuickSearch />
          <div className="flex">
            <Button
              variant="link"
              onClick={() =>
                push(
                  // We aim to retain the 'assets per page' count if accessible, guiding the user to the initial page, otherwise, direct them to '/'
                  pageSize
                    ? {
                        pathname: '/',
                        query: {
                          pageIndex: 1,
                          pageSize: pageSize && pageIndex ? pageSize : defaultPaginationSize,
                        },
                      }
                    : '/',
                )
              }
              className={cn(
                'hover:no-underline',
                !(isManagePage || isPublicRoute || isUploadPage || isDuplicatesPage || isTrashPage || isSearchPage) &&
                  'font-bold !text-highlight-color hover:text-highlight-hover-color',
              )}
              data-cy="navigation-browse-button"
            >
              Browse
            </Button>
            <Button
              variant="link"
              onClick={() => push('/manage')}
              className={cn(
                'hover:no-underline',
                isManagePage && 'font-bold !text-highlight-color hover:text-highlight-hover-color',
              )}
              data-cy="navigation-manage-button"
            >
              Manage
            </Button>
          </div>
        </>
      )}
      {isPublicRoute ? (
        <Button
          variant="link"
          className="flex gap-x-2 hover:no-underline"
          onClick={() =>
            push({
              pathname: '/',
            })
          }
          data-cy="navigation-login-button"
          title="Login"
        >
          <div className="text-sm text-gray-text">Login</div>
          <User className="size-[18px] stroke-gray-icon-color" />
        </Button>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="link"
              className="gap-x-2 border-0 hover:no-underline"
              data-cy="navigation-user-menu-button"
            >
              <p className="line-clamp-1 max-w-56 font-light text-toolbar-icon">
                {user?.nickname
                  ?.split('.')
                  .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
                  .join(' ')}
              </p>
              <Avatar className="!size-9">
                <AvatarImage src={user?.picture} />
                <AvatarFallback>
                  {user?.nickname
                    ?.split('.')
                    .map((name: string) => name[0].toUpperCase())
                    .join('')}
                </AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="max-w-56">
            <DropdownMenuLabel className="line-clamp-1 break-all" title={user?.email}>
              {user?.email}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  {theme === 'light' ? (
                    <Sun className="mr-2 size-4" />
                  ) : theme === 'light' ? (
                    <Moon className="mr-2 size-4" />
                  ) : (
                    <Contrast className="mr-2 size-4" />
                  )}
                  Theme
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem disabled={theme === 'light'} onClick={() => setTheme('light')}>
                      <Sun className="mr-2 size-4" />
                      Light
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled={theme === 'dark'} onClick={() => setTheme('dark')}>
                      <Moon className="mr-2 size-4" />
                      Dark
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled={theme === 'system'} onClick={() => setTheme('system')}>
                      <Contrast className="mr-2 size-4" />
                      System
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => {
                void logout({ logoutParams: { returnTo: window.location.origin } });
                void logoutUser();
              }}
            >
              <LogOut className="mr-2 size-4" />
              <span>Log out</span>
              <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};
