import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { auditDefaultPageSize } from '@/types/pagination';
import { variantListSchema } from '@/types/variant';
import axios from 'axios';

const BASE = `${INTERNAL_V1}/variants`;

const getVariantList = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}&filterQuery=${
      params.queryString
    }&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return variantListSchema.parse(response);
};

const createVariant = async (body: object, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });

  return response;
};

const deleteVariant = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const getVariant = async (id: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${id}`, token);
  return response;
  // return variantSchema.parse(response);
};

const updateVariant = async ({ id, body }: { id: string; body: object }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, { body });
  return response;
  // return variantSchema.parse(response);
};

const requestUploadSlot = async (params?: object, token?: string) => {
  const response = await damRequest('POST', `${INTERNAL_V1}/uploads`, token, {});

  return response;
};

const uploadWatermarkFile = async (url: string, file: File) => {
  try {
    const response = await axios.put(url, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to upload the watermark file.');
    }

    return response;
  } catch (error) {
    console.error('Error uploading watermark file:', error);
    throw new Error('Failed to upload the watermark file.');
  }
};

const getVariantAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? auditDefaultPageSize),
  }).toString();

  const response = await damRequest('GET', `${BASE}/${params?.id}/audit?${query}`, token);

  return response;
};

export {
  getVariantList,
  createVariant,
  deleteVariant,
  getVariant,
  updateVariant,
  requestUploadSlot,
  uploadWatermarkFile,
  getVariantAuditLog,
};
