import { useEffect } from 'react';
import { Link, Plus, Trash2Icon } from 'lucide-react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useAuth0 } from '@auth0/auth0-react';
import { useSharesList } from '@/hooks/shares/useShares';
import { DialogMode, useDialog } from '@/context/DialogContext';
import { EditShareDialog } from '@/components/edit-dialogs/edit-share-dialog';
import { DeleteShareAlertDialog } from '@/components/delete-dialogs/delete-share-alert-dialog';
import { format } from 'date-fns';
import { CreateShareDialog } from '@/components/create-dialogs/create-share-dialog';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { Album } from '@/types/album';
import { Folder } from '@/types/folder';
import { Asset } from '@/types/asset';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { Badge } from '@/components/ui/badge';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { OptionAction } from '@/components/inspector/entity-fields/form/option-action';
import { Share } from '@/types/share';
import { Label } from '@/components/ui/label';
import { useQueryClient } from '@tanstack/react-query';
import useClipboard from '@/hooks/useClipboard';

type OptionItemProps = {
  option: Share;
  nodeId: string;
  openModal: (dialogId: string, mode?: DialogMode, defaultValue?: { id: string; nodeId: string }) => void;
  copy: (text: string, successMessage?: string, errorMessage?: string) => void;
  baseShareUrl: string;
  baseShareHref: string;
};

export const OptionItem = ({ option, openModal, copy, nodeId, baseShareUrl, baseShareHref }: OptionItemProps) => {
  return (
    <div className="flex flex-col justify-center gap-2 px-3">
      <div className="flex w-full items-center gap-2">
        <div className="flex grow items-start justify-between gap-2">
          <div
            className="flex cursor-pointer flex-col gap-y-1 text-xs"
            onClick={() =>
              openModal('editShare', 'editShare', {
                id: option.id,
                nodeId,
              })
            }
          >
            {option.sharedWith && <div className="break-all font-bold">{option.sharedWith}</div>}
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="line-clamp-1 break-all text-gray-text">{`${baseShareUrl}/${option.id}`}</div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{`${baseShareUrl}/${option.id}`}</TooltipContent>
              </TooltipPortal>
            </Tooltip>

            <div className="flex gap-x-1">
              <span className="font-bold">Expires:</span> {option.expiresAt ?? 'Never'}
            </div>

            {(option.previewVariant || (option.extraVariants && option.extraVariants.length > 0)) && (
              <>
                <div className="font-bold">Variants:</div>
                <div className="flex flex-wrap items-center gap-1">
                  {option.previewVariant && (
                    <Badge
                      key={option.previewVariant.id}
                      className="pointer-events-none rounded-full !bg-light-ui-color px-2 py-0 !text-gray-text"
                    >
                      {option.previewVariant.name}
                    </Badge>
                  )}
                  {option.extraVariants?.map((variant) => (
                    <Badge
                      key={variant.id}
                      className="pointer-events-none rounded-full !bg-gray-icon-color px-2 py-0 !text-inverted-text-color"
                    >
                      {variant.name}
                    </Badge>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="flex gap-1">
            <OptionAction
              onClick={() => copy(`${baseShareHref}/${option.id}`)}
              icon={Link}
              type="button"
              data-cy="option-action-button-share-copy-link"
            />
            <OptionAction
              onClick={() =>
                openModal(`deleteShareConfirmation`, 'delete', {
                  id: option.id,
                  nodeId,
                })
              }
              icon={Trash2Icon}
              type="button"
              variant="destructive"
              data-cy="option-action-button-share-delete"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SharesAccordion = ({
  currentAccordionItems,
  disabled,
  item,
  currentSelectedType,
  thumbnail,
}: {
  currentAccordionItems: Array<string>;
  disabled: boolean;
  item: Album | Asset | Folder;
  currentSelectedType: string;
  thumbnail: Asset;
}) => {
  const { openModal } = useDialog();
  const { copy } = useClipboard();
  const { isAuthenticated } = useAuth0();
  const queryClient = useQueryClient();

  const baseShareUrl = `${window.location.host}/shares`;
  const baseShareHref = `${window.location.origin}/shares`;

  const { shareList, shareListIsPending } = useSharesList(
    {
      nodeId: item.nodeId,
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: currentAccordionItems?.includes('shares') && isAuthenticated },
  );

  const { shares } = shareList;

  useEffect(() => {
    if (currentAccordionItems?.includes('shares')) {
      void queryClient.invalidateQueries({ queryKey: ['shareList'] });
    }
  }, [currentAccordionItems]);

  return (
    <AccordionItem value="shares" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Sharing</AccordionTrigger>
      <AccordionContent className="flex flex-col gap-2.5 @[11rem]/inspector:m-1 @[18rem]/inspector:px-2">
        <Label className="text-base font-bold leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:text-right">
          Active shares
        </Label>
        <div className="mt-2 flex flex-col gap-2">
          <Options
            handleAddNewOption={() =>
              openModal('createShare', 'createShare', {
                id: item.nodeId,
              })
            }
            disabled={shareListIsPending}
            emptyState={
              <p className="text-balance px-4 py-12 text-center text-gray-icon-color">
                There are no {currentSelectedType} shares, click <Plus className="inline size-4" /> to share this{' '}
                {currentSelectedType}.
              </p>
            }
            className="gap-y-5 py-2"
          >
            {shares.map((share) => (
              <OptionItem
                key={share.id}
                option={share}
                copy={copy}
                openModal={openModal}
                nodeId={item.nodeId}
                baseShareUrl={baseShareUrl}
                baseShareHref={baseShareHref}
              />
            ))}
          </Options>
        </div>
      </AccordionContent>
      <CreateShareDialog
        firstShare={shares.length === 0}
        shareItem={item}
        shareType={currentSelectedType}
        thumbnail={thumbnail}
      />
      <EditShareDialog shareType={currentSelectedType} shareItem={item} thumbnail={thumbnail} />
      <DeleteShareAlertDialog lastShare={shares.length === 1} />
    </AccordionItem>
  );
};
