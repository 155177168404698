import { FC, useMemo, useState } from 'react';
import { cn } from '@/lib/utils';
import { Asset, Variants } from '@/types/asset';
import { Skeleton } from '@/components/ui/skeleton';
import { FileType } from '@/types/fileType';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { VideoPlayer } from '@/components/video-player';

export const InspectorImage: FC<Asset & { stackedAssetsLength: number; index: number; classname?: string }> = ({
  id,
  stackedAssetsLength,
  name,
  index,
  variants,
  type,
  versions,
  classname,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const thumbnailUrl = variants[Variants.ThumbnailInternal]?.url;

  const activeVersion = useMemo(() => {
    if (Array.isArray(versions)) {
      const v = versions.find((version) => version.active);
      return v ?? versions[0];
    }
    return null;
  }, [versions]);

  const fileType = useMemo(() => {
    return type ? type : activeVersion ? activeVersion.fileType : FileType.Other;
  }, [activeVersion, type]);

  return (
    <div
      className={cn('flex size-full origin-center overflow-hidden transition-all duration-200', classname)}
      key={`inspector-${id}`}
      style={{
        transform: `rotate(${(30 / stackedAssetsLength) * index}deg)`,
        zIndex: stackedAssetsLength - index,
      }}
    >
      {Object.entries(variants).length === 0 && thumbnailUrl && !isLoaded ? (
        <Skeleton className="size-full rounded-none" />
      ) : fileType === FileType.Video ? (
        <VideoPlayer variants={variants} className="object-cover" />
      ) : (
        <AssetThumbnail
          name={name}
          type={fileType}
          thumbnail={thumbnailUrl}
          imageClassName="size-auto object-cover"
          iconClassName="size-16 stroke-light-ui-color"
          imageContainerClassName="relative size-full"
          iconContainerClassName="flex size-full items-center justify-center bg-panel-background"
        />
      )}
    </div>
  );
};
