import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { trashNodesResponseSchema, getTrashResponseSchema } from '@/types/trash';

const BASE = `${INTERNAL_V1}/trash`;

const trashNodesQuery = async (params: { nodes: Array<string> }, token?: string) => {
  const response = await damRequest('POST', BASE, token, {
    body: params,
  });
  return trashNodesResponseSchema.parse(response);
};

const restoreNodesQuery = async (params: { nodes: Array<string> }, token?: string) => {
  const response = await damRequest('POST', `${BASE}/restore`, token, {
    body: params,
  });
  return trashNodesResponseSchema.parse(response);
};

const getTrashQuery = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}`,
    token,
  );
  return getTrashResponseSchema.parse(response);
};

const emptyTrashQuery = async (params: void, token?: string) => {
  const response = await damRequest('DELETE', BASE, token);
  return trashNodesResponseSchema.parse(response);
};

export { trashNodesQuery, restoreNodesQuery, getTrashQuery, emptyTrashQuery };
