import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ChevronsDownUp,
  FolderPlusIcon,
  ListTree,
  Workflow,
  Tags,
  RectangleEllipsis,
  Users,
  Copy,
  Trash2,
  CopyX,
  LucideIcon,
  Upload,
  FolderSearch2,
  Route,
  TriangleAlert,
  Plus,
  FolderPlus,
  BookPlus,
  FolderRoot,
} from 'lucide-react';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { BatchMoveAddAssetsConfirmationDialog } from '@/components/delete-dialogs/batch-move-add-assets-confirmation-dialog';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { PaginationState } from '@/types/pagination';
import { getLocalStorage } from '@/utilities/localStorage';
import { useTreeContext } from '@/context/TreeContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { TenantUserRole } from '@/types/tenantUserRole';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { SearchOptions } from '@/components/sidebar/search-options';
import { ChevronsRight } from 'lucide-react';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { UploadState } from '@/types/uploads';
import { SidebarNav } from '@/components/sidebar/sidebar-nav';
import { useAccount } from '@/hooks/data/account/useAccount';
import { EntityType } from '@/types/entity';
import { useDialog } from '@/context/DialogContext';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { useTree } from '@/hooks/data/tree/useTree';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Tree } from '@/components/sidebar/tree/tree';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export const styles = 'text-neutral-600 dark:text-neutral-400 duration-150 select-none';
export const hoverStyles = 'hover:bg-neutral-200 hover:text-black dark:hover:bg-neutral-800 dark:hover:text-white';
export const dropStyle = 'bg-neutral-200 text-black dark:bg-neutral-800 dark:text-white';
export const selectedStyles =
  'bg-neutral-200 text-neutral-950 hover:bg-neutral-300 hover:text-black dark:bg-neutral-950 dark:text-white dark:hover:bg-black dark:hover:text-white';

const SidebarButton = (props: {
  label: string;
  icon?: LucideIcon;
  iconProps?: any;
  isActive?: boolean;
  className?: string;
  badge?: React.ReactNode;
  badgeClasses?: string;
}) => {
  const Icon = props?.icon;

  return (
    <Button
      variant="ghost"
      size="sm"
      {...props}
      className={cn(
        'h-8 w-full justify-between !pl-8 pr-3 text-base font-light ',
        styles,
        hoverStyles,
        props?.isActive && selectedStyles,
        props?.className,
      )}
    >
      <div className={cn('flex gap-x-2', props.badge && 'mr-1')}>
        {Icon && props?.iconProps && <Icon {...props.iconProps} />}
        <span className="line-clamp-1 break-all text-left">{props?.label}</span>
      </div>
      {props.badge && <Badge className={cn('rounded-full', props.badgeClasses)}>{props.badge}</Badge>}
    </Button>
  );
};

export type SidebarButtonProps = {
  label: string;
  icon?: LucideIcon;
  iconProps?: any;
  isActive?: boolean;
  className?: string;
  badge?: React.ReactNode;
  badgeClasses?: string;
  onClick?: () => void;
};

export function Sidebar({
  className,
  handleOnSortChange,
  setPagination,
}: {
  className?: string;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
}) {
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);

  const { isTrashPage, isSearchPage, isManagePage, isDuplicatesPage, isUploadPage } = useCurrentPage();
  const { includeSubFolderAssets, sidebarTreeOpenIds, setSidebarTreeOpenIds } = useTreeContext();
  const { query, push } = useRouter();
  const { deselectAllAssets } = useAssetSelection();
  const { openModal } = useDialog();
  const { toggleSubFolders, tree, setCurrentSelectedType, selectedParentNode, selectedFolder } = useTree();
  const { profile } = useAccount();
  const { isAuthenticated } = useAuth0();
  const { userRole } = profile;
  const { averageProgress, uploadState, erroredAssets } = useUploadStore();

  const assetManagementMenuItems: Array<SidebarButtonProps> = useMemo(
    () => [
      {
        label: 'Uploads',
        icon: Upload,
        iconProps: { className: 'size-6 min-w-6 stroke-upload-color', strokeWidth: 2 },
        isActive: isUploadPage,
        badge:
          uploadState !== UploadState.NotStarted ? (
            Object.keys(erroredAssets).length > 0 ? (
              <div className="flex items-center justify-between">
                <div>{Object.keys(erroredAssets).length}</div>
                <TriangleAlert size={14} strokeWidth={2} />
              </div>
            ) : (
              <span className="text-black">{String(averageProgress)}%</span>
            )
          ) : undefined,
        onClick: (): void => {
          deselectAllAssets();
          setSelectedFolder(undefined);
          setSelectedAlbum(undefined);
          setCurrentSelectedType(undefined);

          void push({
            pathname: '/uploads',
          });
        },
        badgeClasses:
          Object.keys(erroredAssets).length > 0
            ? '!bg-status-failure'
            : '!bg-inverted-text-color dark:!bg-gray-icon-color',
      },
      {
        label: 'Duplicates',
        icon: CopyX,
        iconProps: { className: 'size-6 min-w-6 stroke-gray-icon-color', strokeWidth: 2 },
        isActive: isDuplicatesPage,
        onClick: (): void => {
          deselectAllAssets();
          setSelectedFolder(undefined);
          setSelectedAlbum(undefined);
          setCurrentSelectedType(undefined);

          void push({
            pathname: '/duplicates',
            query: {
              ...query,
              pageIndex: 1,
              pageSize: getLocalStorage(`${EntityType.Duplicate}/datatable/pagination/pageSize`),
            },
          });
        },
      },
      {
        label: 'Trash',
        icon: Trash2,
        iconProps: { className: 'size-6 min-w-6 stroke-red-500', strokeWidth: 2 },
        isActive: isTrashPage,
        onClick: (): void => {
          deselectAllAssets();
          setSelectedFolder(undefined);
          setSelectedAlbum(undefined);
          setCurrentSelectedType(undefined);

          void push({
            pathname: '/trash',
            query: {
              ...query,
              pageIndex: 1,
              pageSize: getLocalStorage(`${EntityType.Asset}/datatable/pagination/pageSize`),
            },
          });
        },
      },
    ],
    [deselectAllAssets, isDuplicatesPage, isTrashPage, isUploadPage, averageProgress, uploadState, push, query],
  );

  const recentActivityMenuItems = useMemo(
    () => [
      {
        label: 'Last import',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
      {
        label: 'Recently Added',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
      {
        label: 'Recently Updated',
        icon: FolderSearch2,
        iconProps: { className: 'size-6 min-w-6 stroke-smart-folder-color', strokeWidth: 2 },
        isActive: false,
        disabled: true,
      },
    ],
    [],
  );

  return isManagePage ? (
    <div className="relative flex h-screen flex-col space-y-px overflow-y-scroll bg-container-background">
      <SidebarNav
        section={{
          title: 'Configuration',
          icon: ChevronsRight,
        }}
        links={[
          {
            title: 'Workflows',
            icon: Workflow,
            path: '/manage/workflows',
            disabled: false,
          },
          {
            title: 'Tags',
            icon: Tags,
            path: '/manage/tags',
            disabled: false,
          },
          {
            title: 'Metadata',
            icon: RectangleEllipsis,
            path: '/manage/metadata',
            disabled: false,
          },
          {
            title: 'Variants',
            icon: Copy,
            path: '/manage/variants',
            disabled: false,
          },
          {
            title: 'Destinations',
            icon: Route,
            path: '/manage/destinations',
            disabled: false,
          },
        ]}
      />
      {isAuthenticated && userRole === TenantUserRole.Owner && (
        <SidebarNav
          section={{
            title: 'Management',
            icon: ChevronsRight,
          }}
          links={[
            {
              title: 'Users',
              icon: Users,
              path: '/manage/users',
              disabled: false,
            },
          ]}
        />
      )}
    </div>
  ) : (
    <div className={cn('flex h-full flex-col @container/sidebar', className)}>
      {isSearchPage ? (
        <SearchOptions className="flex grow" />
      ) : (
        <>
          <div className="relative flex h-full flex-col bg-white dark:bg-[#2C2C2C]">
            <div className="relative flex h-12 max-h-12 min-h-12 w-full items-center justify-end space-x-2 border-y border-b-void-gap border-t-toolbar-bevel bg-ul-color px-3 py-0 before:pointer-events-none">
              <DropdownMenu>
                <DropdownMenuTrigger asChild className="mr-auto">
                  <Plus className="min-h-6 min-w-6 cursor-pointer" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="max-w-80">
                  {selectedFolder && selectedParentNode && (
                    <>
                      <DropdownMenuItem
                        onClick={() => openModal('editCreateFolderAlbum', 'createAlbum', { id: selectedFolder })}
                      >
                        <FolderPlus className="mr-2 size-4" />
                        {`Create new album in '${selectedParentNode.node.name}'`}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => openModal('editCreateFolderAlbum', 'createFolder', { id: selectedFolder })}
                      >
                        <BookPlus className="mr-2 size-4" />

                        {`Create new folder in '${selectedParentNode.node.name}'`}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </>
                  )}
                  <DropdownMenuItem onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}>
                    <FolderRoot className="mr-2 size-4" />
                    Create top level folder
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <ToggleGroup type="multiple" variant="default" size="sm" className="flex gap-x-2">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ToggleGroupItem
                      value="includeSubfolderAssets"
                      aria-label="Include Subfolders"
                      className={cn('h-6 w-6 p-0', !includeSubFolderAssets && 'data-[state=off]:bg-white')}
                      onClick={toggleSubFolders}
                    >
                      <ListTree className="min-h-6 min-w-6" />
                    </ToggleGroupItem>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>{includeSubFolderAssets ? `Hide` : `Show`} SubFolders assets</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ToggleGroupItem
                      value="collapse"
                      aria-label="Toggle tree collapse"
                      onClick={() => setSidebarTreeOpenIds([])}
                      className={cn('h-6 w-6 p-0', 'hidden @[12.5rem]/sidebar:flex')}
                      disabled={sidebarTreeOpenIds.length === 0}
                    >
                      <ChevronsDownUp className="min-h-6 min-w-6" />
                    </ToggleGroupItem>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Collapse</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </ToggleGroup>
            </div>
            <ContextMenu>
              <div className="relative flex h-full flex-col bg-white dark:bg-[#2C2C2C]">
                <div className="flex flex-col space-y-1 border-b border-neutral-200 pb-2 pt-1 dark:border-[#444444]">
                  {assetManagementMenuItems.map((item) => (
                    <SidebarButton key={item.label} {...item} />
                  ))}
                </div>
                <div className="flex flex-col space-y-1 border-b border-neutral-200 pb-2 pt-1 dark:border-[#444444]">
                  {recentActivityMenuItems.map((item) => (
                    <SidebarButton key={item.label} {...item} />
                  ))}
                </div>
                <ContextMenuTrigger className="flex grow items-start overflow-y-auto overflow-x-hidden ">
                  <Tree
                    className="flex grow px-3 pb-4 pt-1"
                    handleOnSortChange={handleOnSortChange}
                    setPagination={setPagination}
                    reusable={false}
                    openIds={sidebarTreeOpenIds}
                    tree={tree}
                    setOpenIds={setSidebarTreeOpenIds}
                  />
                </ContextMenuTrigger>
              </div>
              <ContextMenuContent>
                <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}>
                  <FolderPlusIcon className="mr-2 size-4" />
                  New Folder
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          </div>
          <BatchMoveAddAssetsConfirmationDialog id="batch-move-add-assets-confirmation-dialog" />
        </>
      )}
    </div>
  );
}
