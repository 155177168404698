import { ComponentType } from 'react';
import { MetadataDocuments } from '@/components/inspector/metadata-accordion/metadata-documents';
import { MetadataCheckbox } from '@/components/inspector/metadata-accordion/metadata-checkbox';
import { MetadataSelect } from '@/components/inspector/metadata-accordion/metadata-select';
import { QualitySlider } from '@/components/ui/quality-slider';
import { MultiSelect } from '@/components/inspector/metadata-accordion/metadata-multiselect';
import { TagSelector } from '@/components/tag-selector';
import { DatePicker } from '@/components/ui/datepicker';
import { FieldType } from '@/types/metadata';
import { Textarea } from '@/components/ui/textarea';
import { Dropzone } from '@/components/ui/dropzone';
import { Slider } from '@/components/ui/slider';
import { Input } from '@/components/ui/input';

export const FieldComponents: Record<FieldType, ComponentType<any>> = {
  linked_assets: MetadataDocuments,
  quality_slider: QualitySlider,
  boolean: MetadataCheckbox,
  tag_selector: TagSelector,
  multi_select: MultiSelect,
  select: MetadataSelect,
  datetime: DatePicker,
  dropzone: Dropzone,
  date: DatePicker,
  text: Textarea,
  integer: Input,
  slider: Slider,
  string: Input,
  float: Input,
  file: Input,
};
