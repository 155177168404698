import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getMultipleAssets } from '@/services/asset.service';
import { useQuery } from '@tanstack/react-query';

export const useMultipleAsset = (
  form: {
    reset: (data: any) => void;
  },
  folderId?: string,
  albumId?: string,
  assetIds?: Array<string>,
  searchQuery?: string,
  options?: object,
) => {
  const getMultipleAssetsFn = useAuthenticatedQueryFn(getMultipleAssets);

  const {
    data: multipleAssets,
    isLoading: isMultipleAssetLoading,
    isPending: isMultipleAssetPending,
    isFetching: isMultipleAssetFetching,
    error: multipleAssetError,
    refetch: refetchMultipleAsset,
  } = useQuery({
    queryKey: ['multipleAssets', folderId, albumId, assetIds],
    queryFn: async () => {
      const data = await getMultipleAssetsFn({ folderId, albumId, assetIds, searchQuery });
      form?.reset(data);
      return data;
    },
    ...options,
  });

  return {
    multipleAssets,
    refetchMultipleAsset,
    isMultipleAssetLoading,
    isMultipleAssetPending,
    isMultipleAssetFetching,
    multipleAssetError,
  };
};
