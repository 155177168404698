import { FC } from 'react';
import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { DeleteNodeAlertAction } from '@/components/delete-dialogs/delete-node-alert-action';

export const DeleteNodeAlertDialog: FC<{
  open: boolean;
  setOpenDialogId: any;
  message: React.ReactElement;
  isRemove: boolean;
  removeAction: () => void;
  deleteAction: () => void;
}> = ({ open, setOpenDialogId, message, isRemove, removeAction, deleteAction }) => {
  return (
    <AlertDialog open={open} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>{message}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <DeleteNodeAlertAction removeAction={removeAction} deleteAction={deleteAction} isRemove={isRemove} />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
