import { getAutocompleteTagsResponseSchema, getTagsResponseSchema, UpdateTagParams } from '@/types/tags';
import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { auditDefaultPageSize } from '@/types/pagination';

const BASE = `${INTERNAL_V1}/tags`;

const getTagList = async (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset}&limit=${params.pagination?.limit}&filterQuery=${params.queryString}&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return getTagsResponseSchema.parse(response);
};

const getAutocompleteTags = async (params: { q: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}/autocomplete?q=${params.q}&limit=${10}`, token);

  return getAutocompleteTagsResponseSchema.parse(response);
};

const getTag = async ({ asset_id }: { asset_id: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${asset_id}`, token);

  return response;
  // return getTagResponseSchema.parse(response);
};

const deleteTag = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const createTag = async (body: object, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });

  return response;
};

const editTag = async ({ id, body }: UpdateTagParams, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, { body });

  return response;
};

const moveTag = async (body: object, token?: string) => {
  const response = await damRequest('POST', `${BASE}/move`, token, { body });

  return response;
};

const getTagAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? auditDefaultPageSize),
  }).toString();

  const response = await damRequest('GET', `${BASE}/${params?.id}/audit?${query}`, token);

  return response;
};

export { getTagList, getTag, getAutocompleteTags, deleteTag, createTag, editTag, moveTag, getTagAuditLog };
