import { FC, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import { requestUploadSlot, updateVariant, uploadWatermarkFile } from '@/services/variant.service';
import { Loader2, MessageSquareWarningIcon, Save } from 'lucide-react';
import { BackendError, useFormErrorHandler } from '@/hooks/useFormErrorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FieldProps, renderField } from '@/components/inspector/entity-fields/form/field-components';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useForm, FormProvider } from 'react-hook-form';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { z, ZodTypeAny } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useVariant } from '@/hooks/variants/useVariant';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';
import { log } from '@/utilities/log';
import { cn } from '@/lib/utils';

export interface UpdateVariantPayload {
  name: any;
  description: any;
  fileType: any;
  versionTypeFilter: any;
  automatic: boolean;
  public: any;
  options: Record<string, any>;
  watermarkUploadId?: string;
  watermark?: any;
  auditable: boolean;
}

export interface FieldProp {
  name: string;
  slug: string;
  description?: string;
  type: string;
  value: any;
  validation?: ZodTypeAny;
  options?: Array<{
    label: string;
    value: string;
  }>;
  readOnly?: string;
  disabled?: boolean;
}

export interface AccordionProps {
  id: string;
  label: string;
  fields: Array<FieldProp>;
  readOnly?: string;
  disabled?: boolean;
}

export const VariantFields: FC<{ variantId: string }> = ({ variantId }) => {
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>([
    'variant',
    'resize',
    'watermark',
    'video',
    'image',
  ]);
  const [lastVariantId, setLastVariantId] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const { variant: fieldData, variantIsFetching } = useVariant(variantId);
  const { toast } = useToast();

  const formFields: Array<AccordionProps> = useMemo(() => {
    if (!fieldData) {
      return [];
    }

    return [
      {
        id: 'variant',
        label: 'Variant',
        fields: [
          {
            name: 'Variant Name',
            slug: 'name',
            type: 'string',
            value: fieldData.name || '',
            validation: z
              .string({ required_error: 'Name is required' })
              .min(1, 'Name should be at least 1 character long'),
          },
          {
            name: 'Slug',
            slug: 'slug',
            type: 'string',
            value: fieldData.slug || '',
            readOnly: 'readonly',
            disabled: true,
          },
          {
            name: 'Description',
            slug: 'description',
            type: 'text',
            value: fieldData.description || '',
            validation: z.string({ required_error: 'The description field must be a string.' }).nullable(),
          },
          {
            name: 'Applies to',
            description: 'This is the type of files this variant should be generated from.',
            slug: 'versionTypeFilter',
            type: 'multi_select',
            value: fieldData.versionTypeFilter,
            options: [
              {
                label: 'Image',
                value: 'image',
              },
              {
                label: 'Video',
                value: 'video',
              },
              {
                label: 'Document',
                value: 'document',
              },
            ],
            validation: z.array(z.enum(['image', 'video', 'document'])),
          },
          {
            name: 'Variant type',
            description: 'This is the type of file that is to be generated by this variant.',
            slug: 'fileType',
            type: 'select',
            value: fieldData.fileType || 'image',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            validation: z.enum(['image', 'video']),
          },
          {
            name: 'Automatic',
            description:
              'When this option is selected, the variant will be generated automatically for every new asset or asset version.',
            slug: 'automatic',
            type: 'boolean',
            value: true,
            readOnly: 'readonly',
            disabled: true,
            validation: z.boolean().optional(),
          },
          {
            name: 'Public',
            description:
              'When this option is selected, the variant can be shown publicly when sharing folders or albums or when an asset is published to a public destination.',
            slug: 'public',
            type: 'boolean',
            value: fieldData.public || false,
            validation: z.boolean(),
          },
          {
            name: 'Auditable',
            description: '',
            slug: 'auditable',
            type: 'boolean',
            value: fieldData.auditable || false,
            validation: z.boolean(),
          },
        ],
      },
      {
        id: 'image',
        label: 'Format',
        fields: [
          {
            name: 'Format',
            slug: 'options.format',
            type: 'select',
            value: fieldData.options?.format || 'png',
            description:
              'This is the file format that is to be generated by this variant. The choices here are dependant on the selected variant type.',
            options: [
              { label: 'JPG', value: 'jpg' },
              { label: 'PNG', value: 'png' },
              { label: 'TIFF', value: 'tiff' },
            ],
            // validation: z.enum(['jpg', 'png', 'tiff']).optional(),
            validation: z.string().optional(),
          },
          {
            name: 'Quality',
            slug: 'options.quality',
            type: 'quality_slider',
            value: fieldData.options?.quality !== undefined ? [fieldData.options.quality] : [80],
            description: 'JPG encoding quality.',
            min: 1,
            max: 100,
            step: 1,
            validation: z.array(z.number().min(1).max(100)).optional(),
          },
        ],
      },
      {
        id: 'video',
        label: 'Format',
        fields: [
          {
            name: 'Format',
            slug: 'options.format',
            type: 'select',
            value: fieldData.options?.format || 'mp4',
            description:
              'This is the file format that is to be generated by this variant. The choices here are dependant on the selected variant type.',
            options: [{ label: 'MP4', value: 'mp4' }],
            // validation: z.enum(['mp4']).optional(),
            validation: z.string().optional(),
          },
          {
            name: 'Bitrate',
            slug: 'options.bitrate',
            type: 'float',
            value: fieldData.options?.bitrate || '',
            min: 1,
            validation: z.union([z.string(), z.number()]).optional(),
          },
          {
            name: 'Framerate',
            slug: 'options.framerate',
            type: 'float',
            value: fieldData.options?.framerate || '',
            min: 1,
            validation: z.union([z.string(), z.number()]).optional(),
          },
          {
            name: 'Audio Codec',
            slug: 'options.audioCodec',
            type: 'select',
            value: fieldData.options?.audioCodec,
            options: [
              { label: 'AAC', value: 'aac' },
              { label: 'AAC-HE', value: 'aac-he' },
              { label: 'AAC-HE-V2', value: 'aac-he-v2' },
              { label: 'MP3', value: 'mp3' },
              { label: 'AC3', value: 'ac3' },
              { label: 'EAC3', value: 'eac3' },
            ],
            validation: z.string().optional(),
            // validation: z.enum(['aac', 'aac-he', 'aac-he-v2', 'mp3', 'ac3', 'eac3']).optional(),
          },
          {
            name: 'Audio Bitrate',
            slug: 'options.audioBitrate',
            type: 'float',
            value: fieldData.options?.audioBitrate || '128000',
            min: 1,
            validation: z.union([z.string(), z.number()]).optional(),
          },
        ],
      },
      {
        id: 'resize',
        label: 'Resize',
        fields: [
          {
            name: 'Resize Mode',
            description: 'Determines how the image is resized by this variant.',
            slug: 'resize',
            type: 'select',
            value: fieldData.options?.resize || 'original',
            options: [
              { label: 'Original', value: 'original' },
              { label: 'Cover', value: 'cover' },
              { label: 'Contain', value: 'contain' },
            ],
            validation: z.enum(['original', 'cover', 'contain']),
          },
          {
            name: 'Width',
            description: 'The width of the resized image.',
            slug: 'width',
            type: 'float',
            min: 0,
            value: fieldData?.options?.width || 0,
          },
          {
            name: 'Height',
            description: 'The height of the resized image.',
            slug: 'height',
            type: 'float',
            min: 0,
            value: fieldData?.options?.height || 0,
          },
        ],
      },
      {
        id: 'watermark',
        label: 'Watermark',
        fields: [
          {
            name: 'Watermark',
            slug: 'watermark',
            type: 'dropzone',
            value: fieldData.watermark || null,
            noLabel: true,
            validation: z.any().optional(),
          },
        ],
      },
    ];
  }, [fieldData]);

  const formSchema = useMemo(() => {
    const shape: Record<string, ZodTypeAny> = {};
    formFields.forEach((accordion) => {
      accordion.fields.forEach((field) => {
        if (field.slug.includes('.')) {
          const [parent, child] = field.slug.split('.');
          if (!shape[parent]) {
            shape[parent] = z.object({ [child]: field.validation ?? z.any() });
          } else {
            shape[parent] = (shape[parent] as z.ZodObject<any>).extend({
              [child]: field.validation ?? z.any(),
            });
          }
        } else {
          shape[field.slug] = field.validation ?? z.any();
        }
      });
    });
    return z.object(shape);
  }, [formFields]);

  const defaultValues = useMemo(() => {
    const values: Record<string, any> = {};
    formFields.forEach((accordion) => {
      accordion.fields.forEach((field) => {
        if (field.slug.includes('.')) {
          const [parent, child] = field.slug.split('.');
          if (!values[parent]) {
            values[parent] = {};
          }
          values[parent][child] = field.value;
        } else {
          values[field.slug] = field.value;
        }
      });
    });
    return values;
  }, [formFields]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const { watch, handleSubmit, reset, setError, getValues } = form;
  const { handleError } = useFormErrorHandler(setError, getValues);

  const watchedFileType = watch('fileType');
  const watchedFormatImage = watch('options.format');

  const requestUploadSlotWithAuth = useAuthenticatedQueryFn(requestUploadSlot);
  const updateVariantWithAuth = useAuthenticatedQueryFn(updateVariant);

  const updateVariantMutation = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: any }) => updateVariantWithAuth({ id, body: payload }),
    onSuccess: () => {
      toast({
        title: `Variant updated`,
        description: `The variant has been successfully updated.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['variantList'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      log(err);
      toast({ title: 'Error', description: err?.message || 'Variant update failed.' });
    },
  });

  async function onSubmit(values: any) {
    const {
      name,
      description,
      versionTypeFilter,
      fileType,
      options: { format, quality, bitrate, framerate, audioCodec, audioBitrate },
      watermark,
      resize,
      width,
      height,
      auditable,
    } = values;

    let watermarkUploadId = fieldData.watermarkUploadId;

    try {
      if (watermark === null) {
        // User removed the watermark
        watermarkUploadId = undefined;
      } else if (watermark && watermark !== fieldData.watermark) {
        // New watermark was added
        const { id, url } = await requestUploadSlotWithAuth({});
        await uploadWatermarkFile(url, watermark);
        watermarkUploadId = id;
      }

      // If fileType === 'image', we pick the image fields
      // If fileType === 'video', we pick the video fields
      const options: Record<string, any> = {};
      if (fileType === 'image') {
        options.format = format;
        options.quality = quality?.length && quality[0];
      } else if (fileType === 'video') {
        options.format = format;
        options.bitrate = bitrate;
        options.framerate = framerate;
        options.audioCodec = audioCodec;
        options.audioBitrate = audioBitrate;
      }

      options.resize = resize;

      if (resize && (resize === 'contain' || resize === 'cover')) {
        options.width = width;
        options.height = height;
      }

      const payload: UpdateVariantPayload = {
        name,
        description,
        fileType,
        versionTypeFilter,
        automatic: true,
        public: values.public,
        auditable,
        options,
      };

      if (watermarkUploadId) {
        payload.watermarkUploadId = watermarkUploadId;
      }

      // If user removes the watermark send null
      if (watermark === null) {
        payload.watermark = null;
      }

      updateVariantMutation.mutate({ id: variantId, payload });
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err?.message || 'Failed to process the request.',
      });
    }
  }

  useEffect(() => {
    if (!variantIsFetching && fieldData && variantId !== lastVariantId) {
      setLastVariantId(variantId);

      const newDefaults: Record<string, any> = {};
      formFields.forEach((acc) => {
        acc.fields.forEach((f) => {
          newDefaults[f.slug] = f.value;
        });
      });
      reset(newDefaults);
    }
  }, [variantIsFetching, fieldData, variantId, lastVariantId, formFields, reset]);

  const isLoading = updateVariantMutation.status === 'pending';

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion
            onValueChange={(accordion) => setCurrentAccordionItems(accordion)}
            value={currentAccordionItems}
            type="multiple"
            className="w-full"
          >
            {variantIsFetching ? (
              <AccordionItem value="variant" key="variant">
                <AccordionTrigger>
                  <Skeleton className="h-6 w-1/2" />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex flex-col space-y-6">
                    {Array.from(Array(3)).map((_, index) => (
                      <>
                        <div key={`skeleton-${index}`} className="flex flex-col space-y-3">
                          <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index === 0 })} />
                          <Skeleton className="h-8 w-full" />
                          <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index === 1 })} />
                        </div>
                        {index !== 2 && <Separator className="block" />}
                      </>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ) : (
              formFields
                .filter((accordion) =>
                  watchedFileType === 'image' ? accordion.id !== 'video' : accordion.id !== 'image',
                )
                .map((accordion) => (
                  <AccordionItem key={accordion.id} value={accordion.id}>
                    <AccordionTrigger>{accordion.label}</AccordionTrigger>
                    <AccordionContent className="relative p-0">
                      {accordion.id === 'variant' && (
                        <div className="flex flex-col gap-y-3 bg-status-warning px-3 py-5 text-inverted-text-color">
                          <MessageSquareWarningIcon className="size-6" />
                          Changes made to variants will only apply to new uploads. Existing asset will remain unchanged.
                        </div>
                      )}
                      <div className="flex flex-col space-y-6 px-3 py-5 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]">
                        {watchedFileType === 'image' && accordion.id === 'image'
                          ? formFields
                              .filter((a) => a.id === 'image')
                              .flatMap((a) => a.fields as Array<FieldProps>)
                              .map((field) => {
                                // Hide quality slider if image format is not jpg
                                if (field.slug === 'options.quality' && watchedFormatImage !== 'jpg') {
                                  return null;
                                }

                                return renderField(form, field, 'hidden');
                              })
                          : watchedFileType === 'video' && accordion.id === 'video'
                          ? formFields
                              .filter((a) => a.id === 'video')
                              .flatMap((a) => a.fields as Array<FieldProps>)
                              .map((field) => renderField(form, field, 'hidden'))
                          : accordion.fields.map((field: any) => renderField(form, field, 'hidden'))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))
            )}
          </Accordion>
          <div className="flex justify-center bg-panel-background p-3">
            <Button
              disabled={variantIsFetching || isLoading}
              type="submit"
              variant="default"
              className="w-full justify-center @[22rem]/inspector:w-auto"
              data-cy="variant-save-button"
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 size-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="mr-2 size-4" />
                  Save
                </>
              )}
            </Button>
          </div>
        </form>
      </FormProvider>
      <Accordion
        onValueChange={(accordion) => setCurrentAccordionItems(accordion)}
        value={currentAccordionItems}
        type="multiple"
        className="w-full"
      >
        <AuditLogAccordion
          multipleAssetSelected={false}
          selectedAssetIds={[{ id: fieldData.id, name: fieldData.name! }]}
          currentAccordionItems={currentAccordionItems}
          entity="Variant"
        />
      </Accordion>
    </>
  );
};
