import { FC } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';

export const FileInformationAccordion: FC<{ asset: Asset; disabled: boolean; disabledMessage?: string }> = ({
  asset,
  disabled,
  disabledMessage,
}) => (
  <AccordionItem value="file-information" disabled={disabled}>
    <AccordionTrigger disabled={disabled} disabledMessage={disabledMessage}>
      File Information
    </AccordionTrigger>
    {!disabled && (
      <AccordionContent className="flex flex-col p-5">
        <div className="divide-y divide-ui-bevel">
          {asset &&
            Object.entries(asset?.fileMetadata as Record<string, { description: string; value: string }>).map(
              ([key, entry]) => (
                <div key={key} className="flex flex-col py-2 first:pt-0 last:pb-0">
                  <div className="font-bold">{entry.description}</div>
                  <div
                    {...(entry?.value?.length > 100 ? { title: entry.value } : {})}
                    className={cn(entry.value?.length > 100 && 'line-clamp-3')}
                  >
                    {entry.value}
                  </div>
                </div>
              ),
            )}
        </div>
      </AccordionContent>
    )}
  </AccordionItem>
);
