import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { ChevronDown, Info } from 'lucide-react';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />);
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    disabledMessage?: string;
    showChevron?: boolean;
  }
>(({ className, children, disabled, disabledMessage, showChevron = true, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      disabled={disabled}
      className={cn(
        'flex h-[48px] flex-1 items-center justify-between border-y border-b-void-gap border-t-toolbar-bevel bg-ul-color p-3 py-2 text-sm font-bold text-black transition-all hover:no-underline disabled:text-dark-gray-text @[18rem]/inspector:py-3 dark:text-white dark:disabled:text-white/50 [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}
    >
      {children}

      {disabled ? (
        <Tooltip>
          <TooltipTrigger>
            <Info
              className="size-5 min-h-5 min-w-5 shrink-0 text-gray-icon-color transition-transform duration-200"
              strokeWidth={2}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent className="text-balance">
              {disabledMessage ? disabledMessage : 'Not all options are available when multiple assets are selected.'}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ) : (
        showChevron && (
          <ChevronDown
            className="-mr-px size-[22px] min-h-[22px] min-w-[22px] shrink-0 text-gray-icon-color transition-transform duration-200"
            strokeWidth={2}
          />
        )
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & { contentContainerClassName?: string }
>(({ className, children, contentContainerClassName, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden border-b border-b-void-gap bg-panel-background text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      // TODO: this class is introduced just for the `save metadata` button positioning, need to find a better way
      contentContainerClassName,
    )}
    {...props}
  >
    <div className={cn('flex flex-col p-3', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
