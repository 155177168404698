import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { BatchUpdateBody } from '@/types/batch';

const BASE = `${INTERNAL_V1}/batch/`;

const batchUpdate = async (body: BatchUpdateBody, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });
  return response;
};

const getBatchProgress = async (params: { batchId: string }, token?: string) => {
  const response = await damRequest('DELETE', `${BASE}/${params.batchId}`, token);
  return response;
};

export { batchUpdate, getBatchProgress };
