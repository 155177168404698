import { useCallback, useEffect, useMemo } from 'react';
import { useTreeContext } from '@/context/TreeContext';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DefaultError, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getIdFromPath, getNode, getRootNodes, moveNodesQuery } from '@/services/tree.service';
import { trashNodesQuery, restoreNodesQuery } from '@/services/trash.service';
import { MoveNodesResponse, TreeBranchNode, TreeRecursiveChildrenNode } from '@/types/tree';
import { AssetListQueryKeyPrefix } from '@/hooks/data/assets/useAssetList';
import { TrashNodesResponse } from '@/types/trash';
import { useAuth0 } from '@auth0/auth0-react';
import { uniq } from 'lodash';
import { convertToDAMNode, convertToFolderNode, useTreeStore } from '@/hooks/data/tree/useTreeStore';

export const usePathData = (pathArray: Array<string>, folderOpened: boolean) => {
  const getIdFromPathFn = useAuthenticatedQueryFn(getIdFromPath);
  // Check if pathArray is valid

  const { data: routeIds } = useQuery({
    queryKey: ['treePath', { path: pathArray }], // Pass pathArray as part of the query key
    queryFn: () => {
      return getIdFromPathFn({
        path: pathArray, // Ensure the path is an empty array if pathArray is undefined or null
      });
    },
    enabled: folderOpened && pathArray?.length > 0,
  });

  const folderIds = routeIds?.slice(0, -1).map((item: { id: any }) => item.id) ?? [];

  const { albumId, folderId } =
    routeIds?.length > 0
      ? routeIds[routeIds.length - 1].type === 'album'
        ? { albumId: routeIds[routeIds.length - 1].id, folderId: undefined }
        : routeIds[routeIds.length - 1].type === 'folder'
        ? { albumId: undefined, folderId: routeIds[routeIds.length - 1].id }
        : { albumId: undefined, folderId: undefined }
      : { albumId: undefined, folderId: undefined };

  return {
    folderIds,
    albumId,
    folderId,
  };
};

export const useTree = ({ reusable = false }: { reusable?: boolean } = {}) => {
  const queryClient = useQueryClient();
  const getRootNodesFn = useAuthenticatedQueryFn(getRootNodes);
  const getNodeFn = useAuthenticatedQueryFn(getNode);
  const moveNodesQueryFn = useAuthenticatedQueryFn(moveNodesQuery);
  const trashNodesQueryFn = useAuthenticatedQueryFn(trashNodesQuery);
  const restoreNodesQueryFn = useAuthenticatedQueryFn(restoreNodesQuery);
  const context = useTreeContext();

  const { sidebarTreeOpenIds, reusableTreeOpenIds, updatedIds } = context;
  const { isAuthenticated } = useAuth0();

  const setRootFolders = useTreeStore((state) => state.setRootFolders);
  const updateNodeChildren = useTreeStore((state) => state.updateNodeChildren);

  const selectedAlbum = useTreeStore((state) => state.selectedAlbum);
  const selectedFolder = useTreeStore((state) => state.selectedFolder);

  const nodeId = selectedFolder ?? selectedAlbum ?? '';

  const { data: selectedParentNode } = useQuery({
    queryKey: ['tree', { nodeId }], // Ensures nodeId is a string
    queryFn: () => {
      return nodeId ? getNodeFn({ nodeId }) : null;
    },
    enabled: isAuthenticated && (Boolean(selectedFolder) || Boolean(selectedAlbum)),
  });

  const { data: rootNodes, isLoading: isRootNodesLoading } = useQuery({
    queryKey: ['tree'],
    queryFn: () => getRootNodesFn(null),
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (rootNodes) {
      const folderNode = convertToFolderNode(rootNodes);
      setRootFolders(folderNode);
    }
  }, [rootNodes]);

  const { data: childNodes } = useQueries({
    queries: uniq([...reusableTreeOpenIds, ...sidebarTreeOpenIds, ...updatedIds]).map((nodeId) => ({
      queryKey: ['tree', { nodeId }],
      queryFn: () => getNodeFn({ nodeId }),
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isLoading: results.some((result) => result.isLoading),
    }),
  });

  useEffect(() => {
    if (Array.isArray(childNodes) && childNodes.length > 0 && !childNodes.includes(undefined)) {
      childNodes
        .filter((node): node is NonNullable<typeof node> => node !== undefined)
        .map((apiNode) => convertToDAMNode(apiNode, updateNodeChildren));
    }
  }, [childNodes]);

  // mutations
  const { mutateAsync: moveNodes } = useMutation<
    MoveNodesResponse,
    DefaultError,
    Parameters<typeof moveNodesQuery>['0']
  >({
    mutationFn: (params) => moveNodesQueryFn(params),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tree'] });
      queryClient.invalidateQueries({ queryKey: [AssetListQueryKeyPrefix] });
    },
  });

  const { mutateAsync: trashNodes } = useMutation<
    TrashNodesResponse,
    DefaultError,
    Parameters<typeof trashNodesQuery>['0']
  >({
    mutationFn: (params) => trashNodesQueryFn(params),
  });

  const { mutateAsync: restoreNodes } = useMutation<
    TrashNodesResponse,
    DefaultError,
    Parameters<typeof trashNodesQuery>['0']
  >({
    mutationFn: (params) => restoreNodesQueryFn(params),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tree'] }),
  });

  // combine rootNodes and childNodes into recursive structure
  const tree = useMemo(() => {
    const appendChildren = (
      nodes: Array<TreeRecursiveChildrenNode>,
      treeBranchNode: TreeBranchNode,
    ): Array<TreeRecursiveChildrenNode> =>
      nodes.map((node) => ({
        ...node,
        children:
          node.id === treeBranchNode?.id
            ? // attach new children nodes if node matches
              treeBranchNode.children
            : // otherwise go through children and try again
              appendChildren(node.children ?? [], treeBranchNode),
      }));

    // go through every fetched childNodes and attach them to the right node in the roottree
    return childNodes.reduce((prev, current) => (current ? appendChildren(prev, current) : prev), rootNodes ?? []);
  }, [childNodes, rootNodes]);

  return {
    ...context,
    selectedAlbum,
    selectedFolder,
    selectedParentNode,
    tree,
    rootNodes,
    moveNodes,
    trashNodes,
    restoreNodes,
    isRootNodesLoading,
  };
};
