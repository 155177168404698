import { FC, useMemo } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';
import { NodeType } from '@/types/tree';
import { InspectorImage } from './inspector-image';
import { BookImage, LucideFolder } from 'lucide-react';
import Image from 'next/image';
import { ExtendedFileWithPath } from '@/hooks/data/inspector/useUploadStore';
import { Badge } from '@/components/ui/badge';
import { filesize } from 'filesize';
import { Progress } from '../ui/progress';

export const PreviewAccordion: FC<{
  asset: Asset;
  currentSelectedType: string;
  selectedAssetIdsLength: number;
  temporaryAssets: Array<ExtendedFileWithPath>;
  stackedAssets: Array<Asset>;
  isUploadPage: boolean;
  containerRef: any;
  erroredAssets: any;
  assetsUploadProgress: any;
  disabled: boolean;
}> = ({
  asset,
  currentSelectedType,
  selectedAssetIdsLength,
  temporaryAssets,
  stackedAssets,
  isUploadPage,
  containerRef,
  erroredAssets,
  assetsUploadProgress,
  disabled,
}) => {
  const activeVersion = useMemo(() => {
    if (asset && Array.isArray(asset.versions)) {
      const v = asset.versions.find((version) => version.active);
      return v ?? asset.versions[0];
    }
    return null;
  }, [asset]);

  return (
    <AccordionItem disabled={disabled} value="preview">
      <AccordionTrigger disabled={disabled}>Preview </AccordionTrigger>
      <AccordionContent className="flex flex-col p-0">
        <>
          <div className={cn('relative flex aspect-square w-full justify-center')} ref={containerRef}>
            {currentSelectedType === NodeType.Assets ? (
              selectedAssetIdsLength > 1 ? (
                isUploadPage ? (
                  <>
                    {temporaryAssets.map((temporaryAsset, i) => (
                      <div
                        className={cn(
                          'absolute flex size-full origin-center overflow-hidden shadow-lg transition-all duration-200 first:relative',
                        )}
                        key={temporaryAsset.id}
                        style={{
                          transform: `rotate(${(30 / temporaryAssets.length) * i}deg)`,
                          zIndex: temporaryAssets.length - i,
                        }}
                      >
                        <Image
                          alt={temporaryAsset.id}
                          src={temporaryAsset.preview}
                          width={200}
                          height={200}
                          unoptimized={false}
                          className="object-cover"
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  stackedAssets.map((props: Asset, index: number) => (
                    <InspectorImage
                      classname="absolute first:relative"
                      key={`${props.id}-container`}
                      {...props}
                      index={index}
                      stackedAssetsLength={stackedAssets.length}
                    />
                  ))
                )
              ) : asset ? (
                <InspectorImage
                  classname="absolute"
                  key={`${asset.id}-header`}
                  {...asset}
                  index={0}
                  stackedAssetsLength={stackedAssets.length}
                />
              ) : (
                isUploadPage &&
                temporaryAssets[0] && (
                  <Image
                    key={temporaryAssets[0].id}
                    alt={temporaryAssets[0].id}
                    src={temporaryAssets[0].preview}
                    width={200}
                    height={200}
                    unoptimized={false}
                    className="object-cover"
                  />
                )
              )
            ) : (
              <div
                className={cn(
                  'flex aspect-square w-full items-center justify-center duration-200',
                  currentSelectedType === NodeType.Folders && 'bg-violet-100 dark:bg-violet-950',
                  currentSelectedType === NodeType.Albums && 'bg-teal-100 dark:bg-teal-950',
                )}
              >
                {currentSelectedType === NodeType.Folders ? (
                  <LucideFolder className="size-16 stroke-violet-500 dark:stroke-violet-700" strokeWidth={1} />
                ) : (
                  <BookImage className="size-16 stroke-teal-600 dark:stroke-teal-700" strokeWidth={1} />
                )}
              </div>
            )}
          </div>
          {isUploadPage && !asset && temporaryAssets.length > 0 ? (
            <>
              {temporaryAssets.map((temporaryAsset) => (
                <div key={temporaryAsset.name} className="flex flex-col divide-y divide-void-gap">
                  <div className="grid grid-cols-[1fr_minmax(auto,_auto)] p-3">
                    <div className="font-bold">{temporaryAsset.name.split('.').slice(0, -1).join('.')}</div>
                    <div>
                      <Badge variant="outline" className="uppercase">
                        {temporaryAsset.type?.split('/')[1]}
                      </Badge>
                    </div>
                    <div className="text-xs text-gray-text">{temporaryAsset.name}</div>
                    <div className="text-right text-xs text-gray-text">
                      {filesize(temporaryAsset?.size, { standard: 'jedec', round: 0 })}
                    </div>
                  </div>
                  {erroredAssets[temporaryAsset.id] ? (
                    <div className="bg-status-failure p-3 text-inverted-text-color">
                      {erroredAssets[temporaryAsset.id]}
                      {/* <div className="mb-2 font-bold">Error</div>
                            {Object.keys(errors?.errors).length ? (
                              <ul>
                            ) :
                            {errors?.message && <div>{errors.message}</div>} */}
                    </div>
                  ) : (
                    <div className="flex flex-col p-3">
                      <div className="flex w-full justify-between">
                        <div className="font-bold">Uploading</div>
                        <div>{filesize(temporaryAsset?.size, { standard: 'jedec', round: 0 })}</div>
                      </div>
                      <Progress
                        value={assetsUploadProgress[temporaryAsset.id]?.progress}
                        className={cn('mt-2 h-[6px] w-full')}
                      />
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            selectedAssetIdsLength === 1 &&
            asset && (
              <>
                <div className="flex flex-col divide-y divide-void-gap">
                  <div className="grid grid-cols-[1fr_minmax(auto,_auto)] p-3">
                    <div className="font-bold">{asset.name}</div>
                    {activeVersion && (
                      <div className="flex flex-col">
                        <div>
                          <Badge variant="outline" className="uppercase">
                            {activeVersion.mimeType?.split('/')[1] ?? activeVersion.fileType}
                          </Badge>
                        </div>
                        <div className="text-center text-xs text-gray-text">
                          {filesize(activeVersion.fileSize, { standard: 'jedec', round: 0 })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </>
      </AccordionContent>
    </AccordionItem>
  );
};
