import { DefaultError, useMutation, useQuery, keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { unDeleteAlbum, deleteAlbum } from '@/services/album.service';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getAlbum } from '@/services/album.service';
import { Album } from '@/types/album';

export const useAlbum = (
  id?: string,
  form?: {
    reset: (data: any) => void;
  },
  options?: object,
) => {
  const queryClient = useQueryClient();

  const getAlbumWithAuth = useAuthenticatedQueryFn(getAlbum);
  const authUnDeleteAlbum = useAuthenticatedQueryFn(unDeleteAlbum);
  const authDeleteAlbum = useAuthenticatedQueryFn(deleteAlbum);

  const {
    data: album,
    isLoading: isAlbumLoading,
    isPending: isAlbumPending,
    isFetching: isAlbumFetching,
    error: albumError,
    refetch,
  } = useQuery({
    queryKey: ['album', id],
    queryFn: id
      ? async () => {
          const data = await getAlbumWithAuth(id);
          form?.reset(data);
          return data;
        }
      : () => undefined,
    placeholderData: keepPreviousData,
    enabled: false,
    ...options,
  });

  const { mutateAsync: unDeleteAlbumMutation } = useMutation<
    Album,
    DefaultError,
    Parameters<typeof unDeleteAlbum>['0']
  >({
    mutationFn: (params) => authUnDeleteAlbum(params),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tree'] }),
  });

  const { mutateAsync: deleteAlbumMutation } = useMutation<Album, DefaultError, Parameters<typeof deleteAlbum>['0']>({
    mutationFn: (params) => authDeleteAlbum(params),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tree'] }),
  });

  return {
    album,
    refetchAlbum: refetch,
    isAlbumFetching,
    isAlbumLoading,
    isAlbumPending,
    error: albumError,
    unDeleteAlbumMutation,
    deleteAlbumMutation,
  };
};
