import { boolean, z } from 'zod';
import { keywordSchema } from '@/types/keyword';
import { paginationSchema } from '@/types/pagination';
import { assetNoteSchema } from '@/types/notes';
import { ObjectAction, ObjectType } from './batch';

export enum Variants {
  ThumbnailInternal = 'thumbnail-internal',
  PreviewInternal = 'preview-internal',
  VideoPreviewInternal = 'video-preview-internal',
}

const baseAssetVersionSchema = z.object({
  id: z.string(),
  active: z.boolean(),
  originalFileName: z.string(),
  fileSize: z.number(),
  width: z.number(),
  height: z.number(),
  fileType: z.string(),
  thumbnail: z.string().url().optional(),
  mimeType: z.string().optional(),
});

export const assetWorkflowMetadataSchema = z
  .union([
    z.array(z.never()),
    z
      .record(
        z.union([
          z.literal('').transform(() => null),
          z.string().nullish().optional(),
          z.boolean().optional(),
          z.number().nullable().optional(),
          z.array(z.string()).optional(),
        ]),
      )
      .optional(),
  ])
  .transform((arg) => (Array.isArray(arg) ? {} : arg))
  .optional();

export const assetSchema = z.object({
  createdAt: z.string().optional(),
  createdBy: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
  description: z.string().optional(),
  destinations: z.string().array().optional(),
  fileMetadata: z.any().optional(),
  mimeType: z.string().optional(),
  size: z.number().optional(),
  fileName: z.string().optional(),
  id: z.string(),
  assetId: z.string().optional(),
  name: z.string(),
  type: z.string().optional(),
  slug: z.string(),
  tags: z.string().array().optional(),
  tenantId: z.string().optional(),
  nodeId: z.string(),
  parentFolderId: z.string().optional(),
  keywords: z.array(keywordSchema).optional(),
  updatedAt: z.string().optional(),
  metadata: assetWorkflowMetadataSchema,
  variants: z
    .union([
      z.tuple([]),
      z.record(
        z.string(),
        z.object({
          width: z.number(),
          height: z.number(),
          url: z.string(),
          type: z.string().optional().nullable(),
          name: z.string().optional().nullable(),
          watermark: z.string().optional().nullable(),
          automatic: z.boolean().optional().nullable(),
          public: z.boolean().optional().nullable(),
          format: z.string().optional().nullable(),
        }),
      ),
    ])
    .optional()
    // api will return an empty array if there are no variants,
    // transform it into an empty object instead, to make it easier to work with
    .transform((arg) => (Array.isArray(arg) || arg === undefined ? {} : arg)),
  workflows: assetWorkflowMetadataSchema,
  versions: z.array(baseAssetVersionSchema).optional(),
  trashed: z.boolean().optional(),
  notes: z.array(assetNoteSchema).optional(),
});

export const assetIdNameSchema = assetSchema.pick({ id: true, name: true });
export type AssetIdNameTypes = z.infer<typeof assetIdNameSchema>;

export type Asset = z.infer<typeof assetSchema>;

export const getAssetsSchema = z.object({
  assets: z.array(assetSchema),
  pagination: paginationSchema.optional(),
});

export type GetAssets = z.infer<typeof getAssetsSchema>;

// Created new schema because response of folders and albums api is different from assets api
export const getAssetsRootSchema = z.object({
  nodeId: z.string().optional(),
  name: z.string().optional(),
  assets: getAssetsSchema,
});

export type GetAssetsRoot = z.infer<typeof getAssetsRootSchema>;

const UpdateAssetParams = assetSchema
  .pick({ name: true, description: true, slug: true, tags: true })
  .partial({ name: true, description: true, slug: true, tags: true });

export type UpdateAssetParams = z.infer<typeof UpdateAssetParams>;

export const updateAssetReturnSchema = assetSchema.omit({ variants: true });

export const deleteAssetVersionReturnSchema = assetSchema.pick({ id: true });

export const activateAssetVersionReturnSchema = baseAssetVersionSchema.extend({
  width: z.number().optional(),
  height: z.number().optional(),
  createdAt: z.string().optional(),
  assetId: z.string().optional(),
});

export type AssetWorkflowMetadata = z.infer<typeof assetWorkflowMetadataSchema>;

export const getMultipleAssetsSchema = z.object({
  assetsSelected: z.number(),
  metadata: assetWorkflowMetadataSchema,
  workflows: assetWorkflowMetadataSchema,
  tags: z.object({
    isFullIntersection: z.boolean(),
    tags: z.string().array(),
  }),
});
