import { FC } from 'react';
import { Asset, Variants } from '@/types/asset';
import { FileVideo } from 'lucide-react';

export type VideoPlayerProps = {
  variants: NonNullable<Asset['variants']>;
  className?: string;
};

export const VideoPlayer: FC<VideoPlayerProps> = ({ variants, className }) => {
  const thumbnailUrl = variants[Variants.ThumbnailInternal]?.url;
  const videoUrl = variants[Variants.VideoPreviewInternal]?.url;
  const width = variants[Variants.VideoPreviewInternal]?.width;
  const height = variants[Variants.VideoPreviewInternal]?.height;

  return (
    <>
      {videoUrl ? (
        <video preload="none" width={width} height={height} className={className} controls poster={thumbnailUrl}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="relative flex aspect-square max-h-[calc(100vh-300px)] w-full items-center justify-center">
          <FileVideo strokeWidth={0.5} className="stroke-neutral-500 dark:stroke-neutral-700" size={200} />
        </div>
      )}
    </>
  );
};
