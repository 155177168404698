import { FC, Fragment, useMemo, useState } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { useRefinementList } from 'react-instantsearch';
import { ChevronRightIcon } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
// import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface FilterItemProps {
  filter: {
    attribute: string;
    label: string;
    options?: Record<string, string>;
  };
}

interface RefinementItem {
  label: string;
  value: string;
  count: number;
  isRefined: boolean;
}

interface HierarchicalItem extends RefinementItem {
  children?: Array<HierarchicalItem>;
}

const parseHierarchicalItems = (items: Array<RefinementItem>): Array<HierarchicalItem> => {
  const root: Array<HierarchicalItem> = [];

  items.forEach((item) => {
    const parts = item.value.split('||');
    let currentLevel = root;

    parts.forEach((part, index) => {
      let existingItem = currentLevel.find((i) => i.label === part);

      if (!existingItem) {
        existingItem = {
          label: part,
          value: parts.slice(0, index + 1).join('||'),
          count: item.count,
          isRefined: item.isRefined,
          children: [],
        };
        currentLevel.push(existingItem);
      }

      if (index === parts.length - 1) {
        existingItem.count = item.count;
        existingItem.isRefined = item.isRefined;
      }

      currentLevel = existingItem.children!;
    });
  });

  return root;
};

export const FilterItem: FC<FilterItemProps> = ({ filter }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const {
    items: refinementItems,
    refine,
    canRefine,
    isShowingMore,
    toggleShowMore,
    canToggleShowMore,
  } = useRefinementList({
    attribute: filter.attribute,
    showMore: true,
    limit: 10,
    showMoreLimit: 1000,
  });

  const hierarchicalItems = useMemo(() => parseHierarchicalItems(refinementItems), [refinementItems]);

  const renderItems = (items: Array<HierarchicalItem>, level = 0) =>
    items.map((item) => {
      return (
        <Fragment key={item.value}>
          <div className="flex items-center space-x-2" style={level > 0 ? { marginLeft: `${level * 24}px` } : {}}>
            <Checkbox
              className="rounded-[3px] border-neutral-300 dark:!border-neutral-600"
              id={`${filter.label}-${item.value}`}
              value={item.isRefined ? 1 : 0}
              checked={item.isRefined}
              onCheckedChange={() => refine(item.value)}
            />
            <label
              htmlFor={`${filter.label}-${item.value}`}
              className={cn(
                'flex w-full items-center justify-between text-sm font-normal leading-none hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                item.isRefined ? 'text-black dark:text-white' : 'text-neutral-600 dark:text-neutral-400',
              )}
            >
              <p className="line-clamp-1 break-all text-left">
                {item.label === 'true'
                  ? 'True'
                  : item.label === 'false'
                  ? 'False'
                  : filter.options?.[item.label] || item.label}
              </p>
              {item.count && <p className="ml-2 rounded-full font-light">{item.count}</p>}
            </label>
          </div>
          {item.children && renderItems(item.children, level + 1)}
        </Fragment>
      );
    });

  if (!canRefine) {
    return null;
  }

  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={setIsExpanded}
      className="border-b border-neutral-200 px-1.5 py-0.5 dark:border-ui-bevel"
    >
      <CollapsibleTrigger asChild>
        <div className={cn('flex w-full items-center pr-1.5')}>
          <div className="mr-px cursor-pointer select-none p-1">
            <ChevronRightIcon
              className={cn('size-5.5 text-[#999999] transition duration-300', {
                'rotate-90': isExpanded,
              })}
            />
          </div>
          <div className="flex-1">
            <button className="flex w-full items-center justify-start py-1 outline-none">
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left font-medium">{filter.label}</span>
                {/* placeholder for items counter */}
                {/* <Badge className="ml-2 rounded-full bg-neutral-200 text-[11px]" variant="secondary">
                  {refinementItems.length}
                </Badge> */}
              </div>
            </button>
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-3 py-2 pl-4 pr-2">
        {renderItems(hierarchicalItems)}
        {canToggleShowMore && (
          <div className="flex justify-center">
            <button
              onClick={toggleShowMore}
              className="m-0 inline-block rounded-md px-2 py-1 text-sm font-medium text-text-color"
            >
              {isShowingMore ? 'Show Less' : 'Show More'}
            </button>
          </div>
        )}
      </CollapsibleContent>
    </Collapsible>
  );
};
