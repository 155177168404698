import { FC } from 'react';
import { FileVideo } from 'lucide-react';
import { AssetThumbnail } from '@/components/asset-thumbnail';

type AssetPreviewProps = {
  preview: string;
  type: string;
  index: number;
  name?: string;
  imageClassName?: string;
  iconClassName?: string;
  imageContainerClassName?: string;
  iconContainerClassName?: string;
  playIconClassName?: string;
  width?: number;
  height?: number;
  fill?: boolean;
};

export const AssetPreview: FC<AssetPreviewProps> = ({ preview, type, index }) => {
  switch (type) {
    case 'video/mp4':
      if (preview) {
        return (
          <video className="absolute inset-0 size-full">
            <source src={preview} />
          </video>
        );
      } else {
        return (
          <div className="flex h-full items-center justify-center">
            <FileVideo strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
          </div>
        );
      }
    default:
      return (
        <AssetThumbnail
          thumbnail={preview}
          name={preview ?? `${type}-${index}`}
          type={type}
          onLoad={() => URL.revokeObjectURL(preview)}
          imageContainerClassName="h-full"
          imageClassName="object-cover"
          iconContainerClassName="flex h-full items-center justify-center"
          iconClassName="stroke-light-ui-color size-10"
        />
      );
  }
};
