import { TextareaHTMLAttributes, forwardRef } from 'react';
import { cn } from '@/lib/utils';
import { startCase } from 'lodash';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isLoading?: boolean;
  appendIcon?: any;
  indeterminate?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isLoading, appendIcon, indeterminate, ...props }, ref) => (
    <div
      className={cn(
        'flex items-start rounded-lg border border-neutral-200 focus-within:outline-none focus-within:ring-2 focus-within:ring-neutral-500 focus-within:ring-offset-2 focus-within:duration-150 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:focus-within:ring-neutral-500',
        className,
        indeterminate && '!bg-status-warning/25',
        props.disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      <textarea
        className={cn(
          'flex min-h-[60px] w-full bg-transparent p-[6px] text-xs transition duration-150 focus-visible:outline-none disabled:cursor-not-allowed',
          indeterminate
            ? 'placeholder:italic placeholder:text-status-warning'
            : 'placeholder:text-neutral-500 dark:placeholder:text-neutral-400',
        )}
        ref={ref}
        {...props}
        placeholder={
          indeterminate && props.name
            ? `Multiple values selected for "${startCase(props?.name?.replace(/_/g, ' '))}"`
            : indeterminate
            ? `Multiple values selected`
            : props.placeholder
        }
      />
      {appendIcon}
    </div>
  ),
);

Textarea.displayName = 'Textarea';

export { Textarea };
