import { z } from 'zod';
import { assetSchema } from '@/types/asset';

const uploadAssetSchema = assetSchema.extend({
  folderPath: z.string().optional(),
  size: z.number(),
  path: z.string().optional(),
});

export type UploadAsset = z.infer<typeof uploadAssetSchema>;

const getUploadsSchema = z.array(uploadAssetSchema);

export type getUploads = z.infer<typeof getUploadsSchema>;

const uploadStatusSchema = z.enum(['ready', 'uploading', 'success', 'error', 'no-files', 'no-folder', 'failed']);

export type UploadStatus = z.infer<typeof uploadStatusSchema>;
export enum UploadState {
  NotStarted = 'notStarted',
  Uploading = 'uploading',
  Finished = 'finished',
  Canceled = 'canceled',
}

export const UploadFinishedPercentage = 100;
