import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useModalData } from '@/context/ModalContext';
import { Modal } from '@/components/ui/modal';

export const DeleteNoteAlertDialog: FC<{ id: string; onConfirm: (assetId: string, noteId: string) => void }> = ({
  id,
  onConfirm,
}) => {
  const modalData = useModalData()[0];
  const noteId = modalData?.noteId;
  const assetId = modalData?.assetId;

  return (
    <Modal id={id} variant="alert">
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>Are you sure you want to delete asset note?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="!bg-red-500 !text-white transition-all duration-150 hover:!bg-red-600"
            onClick={() => {
              if (noteId) {
                onConfirm(assetId, noteId);
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </Modal>
  );
};
