import { DAM_URL, damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import axios from '@/lib/axios';
import {
  UpdateAssetParams,
  assetSchema,
  deleteAssetVersionReturnSchema,
  getAssetsRootSchema,
  getAssetsSchema,
  updateAssetReturnSchema,
  activateAssetVersionReturnSchema,
  getMultipleAssetsSchema,
} from '@/types/asset';
import { getAuditsSchema } from '@/types/audit';
import { pageSizeOptions } from '@/types/pagination';
import { assetNoteSchema, getAssetNotesSchema } from '@/types/notes';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { log } from '@/utilities/log';

const BASE = `${INTERNAL_V1}/assets/`;

const getAsset = async (params: { id: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}${params.id}`, token);
  return assetSchema.parse(response);
};

const getAssets = async (params?: { pagination?: { offset: number; limit: number } }, token?: string) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? pageSizeOptions[0]),
  }).toString();

  const response = await damRequest('GET', `${INTERNAL_V1}/assets?${query}`, token);
  return getAssetsSchema.parse(response);
};

const getFolderAssets = async (
  params?: {
    folderId: string;
    pagination?: { offset: number; limit: number };
    includeSubFolderAssets?: boolean;
    sort?: { order: string; value: string };
    queryString?: string;
  },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? pageSizeOptions[0]),
    includeAssets: '1',
    includeAllSubFolders: params?.includeSubFolderAssets ? '1' : '0',
    filterQuery: params?.queryString ?? '',
    sortBy: params?.sort?.value ?? SortOrderValues.CREATED_AT,
    sortOrder: params?.sort?.order ?? SortOrderOptions.ASC,
  }).toString();

  const response = await damRequest('GET', `${INTERNAL_V1}/folders/${params?.folderId}?${query}`, token, {});
  //Schema for response, because response has nodeId param
  return getAssetsRootSchema.parse(response);
};

const getAlbumAssets = async (
  params?: {
    albumId: string;
    pagination?: { offset: number; limit: number };
    sort?: { order: string; value: string };
    queryString?: string;
  },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? pageSizeOptions[0]),
    includeAssets: '1',
    filterQuery: params?.queryString ?? '',
    sortBy: params?.sort?.value ?? SortOrderValues.CREATED_AT,
    sortOrder: params?.sort?.order ?? SortOrderOptions.ASC,
  }).toString();

  const response = await damRequest('GET', `${INTERNAL_V1}/albums/${params?.albumId}?${query}`, token, {});
  return getAssetsRootSchema.parse(response);
};

const createAsset = async (
  params: {
    folderId: string;
    file: Blob | File;
    onProgress: (progress: number, id: string) => void;
    assetId?: string;
    originalFileName?: string;
    temporaryId: string;
    onError: (id: string, error: any) => void;
    metadata: object;
    workflows: object;
    tags: Array<string>;
    name: string;
  },
  token?: string,
) => {
  try {
    const baseUrl = `${DAM_URL}${INTERNAL_V1}`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    };

    log(`Processing file via claim ${params.file.name}`);

    log(`Creating upload URL for file ${params.file.name}`);
    const upload = (await axios.post(`${baseUrl}/uploads`, {}, { headers })).data;
    log(`Upload URL created: ${upload.url}`);

    log(`Uploading file ${params.file.name}`);
    await axios.put(upload.url, params.file, {
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total && params.onProgress) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          params.onProgress?.(progress, params.temporaryId);
        }
      },
    });

    log(`Claiming upload ${upload.id} for file ${params.file.name}`);
    const claimResponse = await axios.post(
      `${baseUrl}/uploads/${upload.id}/claim`,
      {
        ...(params.assetId && { assetId: params.assetId }),
        parentFolderId: params.folderId,
        originalFileName: params.originalFileName ?? params.file.name,
        metadata: params.metadata,
        workflows: params.workflows,
        tags: params.tags,
        name: params.name,
      },
      { headers },
    );

    return claimResponse.data;
  } catch (error: any) {
    if (params.onError && params.temporaryId) {
      params.onError(params.temporaryId, error.message ?? 'Failed to upload asset');
    } else {
      throw new Error(`Failed to upload asset`);
    }
  }
};

const createAssetVersion = async (
  params: {
    folderId: string;
    file: Blob | File;
    onProgress?: (progress: number) => void;
    originalFileName?: string;
    assetId?: string;
  },
  token?: string,
) => {
  const baseUrl = `${DAM_URL}${INTERNAL_V1}`;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  log(`Creating upload URL for file ${params.file.name}`);
  const upload = (await axios.post(`${baseUrl}/uploads`, {}, { headers })).data;
  log(`Upload URL created: ${upload.url}`);

  log(`Uploading new file's version: ${params.file.name}`);
  await axios.put(upload.url, params.file, {
    headers: { 'Content-Type': 'application/octet-stream' },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        params.onProgress?.(progress);
      }
    },
  });

  log(`Claiming upload ${upload.id} for file ${params.file.name}`);
  const response = await axios.post(
    `${baseUrl}/assets/${params.assetId}/versions`,
    {
      uploadId: upload.id,
      originalFileName: params.originalFileName ?? params.file.name,
    },
    { headers },
  );

  return response.data;
};

const updateAsset = async ({ id, body }: { id?: string | null; body: UpdateAssetParams }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}${id}`, token, { body });

  return updateAssetReturnSchema.parse(response);
};

const getAssetAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? pageSizeOptions[0]),
  }).toString();

  const response = await damRequest('GET', `${BASE}${params?.id}/audit?${query}`, token);
  return getAuditsSchema.parse(response);
};

const deleteAssetVersion = async (
  { asset_id, version_id }: { asset_id?: string | null; version_id?: string | null },
  token?: string,
) => {
  const response = await damRequest('DELETE', `${BASE}${asset_id}/versions/${version_id}`, token);

  return deleteAssetVersionReturnSchema.parse(response);
};

const restoreAssetVersion = async (
  { asset_id, version_id }: { asset_id?: string | null; version_id?: string | null },
  token?: string,
) => {
  const response = await damRequest('POST', `${BASE}${asset_id}/versions/${version_id}/activate`, token);

  return activateAssetVersionReturnSchema.parse(response);
};

const createAssetNotes = async ({ assetId, body }: { assetId: string; body: object }, token?: string) => {
  const response = await damRequest('POST', `${BASE}${assetId}/notes`, token, { body });

  return assetNoteSchema.parse(response);
};

const getAssetNotes = async (id: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}${id}/notes`, token);

  return getAssetNotesSchema.parse(response);
};

const updateAssetNotes = async (
  { assetId, noteId, body }: { assetId: string; noteId: string; body: object },
  token?: string,
) => {
  const response = await damRequest('PUT', `${BASE}${assetId}/notes/${noteId}`, token, { body });

  return assetNoteSchema.parse(response);
};

const deleteAssetNotes = async ({ assetId, noteId }: { assetId: string; noteId: string }, token?: string) => {
  const response = await damRequest('DELETE', `${BASE}${assetId}/notes/${noteId}`, token);

  return assetNoteSchema.parse(response);
};

const undeleteAssetNotes = async ({ assetId, noteId }: { assetId: string; noteId: string }, token?: string) => {
  const response = await damRequest('POST', `${BASE}${assetId}/notes/${noteId}/undelete`, token);

  return assetNoteSchema.parse(response);
};

const getMultipleAssets = async (
  params: { folderId?: string; albumId?: string; assetIds?: Array<string>; searchQuery?: string },
  token?: string,
) => {
  const query = new URLSearchParams({
    ...(params.folderId ? { folderId: params.folderId } : {}),
    ...(params.albumId ? { albumId: params.albumId } : {}),
    ...(params.searchQuery ? { searchQuery: params.searchQuery } : {}),
  });

  if (params.assetIds && Array.isArray(params.assetIds)) {
    params.assetIds.forEach((id) => query.append('assetIds[]', id)); // Laravel expects assetIds[] as the array format
  }

  const response = await damRequest('GET', `${INTERNAL_V1}/assets/multiple?${query.toString()}`, token);
  return getMultipleAssetsSchema.parse(response);
};

export {
  getAsset,
  getAssets,
  getFolderAssets,
  getAlbumAssets,
  getAssetAuditLog,
  createAsset,
  createAssetVersion,
  updateAsset,
  deleteAssetVersion,
  restoreAssetVersion,
  createAssetNotes,
  getAssetNotes,
  updateAssetNotes,
  deleteAssetNotes,
  undeleteAssetNotes,
  getMultipleAssets,
};
