import { FC, useEffect, useState } from 'react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { renameFolder } from '@/services/folder.service';
import { updateAsset } from '@/services/asset.service';
import { renameAlbum } from '@/services/album.service';
import { capitalize } from 'lodash';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useWatch } from 'react-hook-form';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export const InformationAccordion: FC<{
  asset: Asset;
  folder: any;
  album: any;
  disabled: boolean;
  form?: any;
  formSchema: any;
  currentSelectedType?: string;
  selectedAssetIds?: any;
  isLoading?: boolean;
  isFetching?: boolean;
  readOnly?: boolean;
}> = ({
  asset,
  folder,
  album,
  disabled,
  form,
  formSchema,
  currentSelectedType,
  selectedAssetIds,
  isLoading,
  isFetching,
  readOnly = false,
}) => {
  const queryClient = useQueryClient();

  const [isEdit, setIsEdit] = useState(false);

  const { isDuplicatesPage } = useCurrentPage();
  const { toast } = useToast();
  const { selectedAlbum, selectedFolder } = useTree();
  const { control, setError, getValues, reset, handleSubmit, formState } = form;

  const watchedName = useWatch({ control: control, name: 'name' });
  const watchedDescription = useWatch({ control: control, name: 'description' });

  const updateAssetWithAuth = useAuthenticatedQueryFn(updateAsset);
  const renameAlbumWithAuth = useAuthenticatedQueryFn(renameAlbum);
  const renameFolderWithAuth = useAuthenticatedQueryFn(renameFolder);

  const { handleError } = useFormErrorHandler(setError, getValues);

  // Reset "information" form if accordion is opened and asset data is fetching or loading
  useEffect(() => {
    if (isLoading || isFetching) {
      reset({
        name: '',
        slug: '',
        description: '',
      });
    }
  }, [form, isLoading, isFetching]);

  const updateAssetMutation = useMutation({
    mutationFn: updateAssetWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === asset?.name && watchedDescription !== asset?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <div className="flex whitespace-nowrap">
            {onlyDescriptionChanged ? (
              <>
                The description for <span className="mx-1 inline-block max-w-24 truncate font-bold">{result.name}</span>
                has been updated.
              </>
            ) : (
              <>
                {capitalize(currentSelectedType?.slice(0, -1))} has been renamed to &quot;
                <span className="inline-block max-w-32 truncate font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </div>
        ),
      });

      setIsEdit((prev) => prev === true && false);

      void queryClient.invalidateQueries({ queryKey: [`${isDuplicatesPage ? 'duplicates' : 'assetData'}`] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameAlbumMutation = useMutation({
    mutationFn: renameAlbumWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === album?.name && watchedDescription !== album?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <div className="flex whitespace-nowrap">
            {onlyDescriptionChanged ? (
              <>
                The description for <span className="mx-1 inline-block max-w-24 truncate font-bold">{result.name}</span>
                has been updated.
              </>
            ) : (
              <>
                {capitalize(currentSelectedType?.slice(0, -1))} has been renamed to &quot;
                <span className="inline-block max-w-32 truncate font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </div>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameFolderMutation = useMutation({
    mutationFn: renameFolderWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === folder?.name && watchedDescription !== folder?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <div className="flex whitespace-nowrap">
            {onlyDescriptionChanged ? (
              <>
                The description for <span className="mx-1 inline-block max-w-24 truncate font-bold">{result.name}</span>{' '}
                has been updated.
              </>
            ) : (
              <>
                {capitalize(currentSelectedType?.slice(0, -1))} has been renamed to &quot;
                <span className="inline-block max-w-32 truncate font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </div>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (currentSelectedType === NodeType.Folders) {
      renameFolderMutation.mutate({
        id: selectedFolder,
        name: values.name,
        description: values.description,
      });
    } else if (currentSelectedType === NodeType.Albums) {
      renameAlbumMutation.mutate({
        id: selectedAlbum,
        name: values.name,
        description: values.description,
        slug: values.slug,
      });
    } else {
      updateAssetMutation.mutate({
        id: selectedAssetIds[0].id,
        body: { name: values.name, description: values.description },
      });
    }
  }

  return (
    <AccordionItem value="information" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Information</AccordionTrigger>
      {!disabled && (
        <AccordionContent className="m-1 flex flex-col">
          <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        isLoading={isLoading || isFetching}
                        disabled={!isEdit || readOnly}
                        placeholder="Name"
                        className={cn(
                          'bg-input-background focus-within:ring-offset-panel-background',
                          formState.errors.name && '!border-red-500 dark:focus-visible:ring-red-500 ',
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="slug"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Slug</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        isLoading={isLoading || isFetching}
                        disabled
                        placeholder="Slug"
                        className={cn(
                          'bg-input-background focus-within:ring-offset-panel-background',
                          formState.errors.slug && '!border-red-500 dark:focus-visible:ring-red-500 ',
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Description"
                        rows={4}
                        {...field}
                        disabled={!isEdit || readOnly}
                        isLoading={isLoading || isFetching}
                        className="bg-input-background focus-within:ring-offset-panel-background"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!readOnly && (
                <div className="flex justify-end">
                  <Button
                    type={isEdit ? 'button' : 'submit'}
                    variant={isEdit ? 'default' : 'outline'}
                    disabled={isFetching || isLoading}
                    onClick={() => setIsEdit((prev) => prev === false && true)}
                  >
                    {isLoading || isFetching ? (
                      <Loader2 className="size-4 animate-spin stroke-neutral-700" />
                    ) : isEdit ? (
                      'Save Information'
                    ) : (
                      'Edit Information'
                    )}
                  </Button>
                </div>
              )}
            </form>
          </Form>
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
