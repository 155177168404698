import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { batchUpdate } from '@/services/batch.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';
import { BackendError } from '@/hooks/useFormErrorHandler';
import { error as errorLog } from '@/utilities/log';

export const useBatchEditAssets = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const batchEditAssetsWithAuth = useAuthenticatedQueryFn(batchUpdate);

  const batchEditAssetsMutation = useMutation({
    mutationFn: batchEditAssetsWithAuth,
    onSuccess: async () => {
      toast({
        title: 'Assets updated',
        description: 'Assets have been updated successfully.',
      });
      await queryClient.invalidateQueries({ queryKey: ['multipleAssets'] });
    },
    onError: (err: BackendError) => {
      errorLog(err);
    },
  });

  return {
    batchEditAssetsMutation,
  };
};
