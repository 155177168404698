import { FC, useCallback, useEffect, useState } from 'react';
import {
  controlStyles,
  placeholderStyles,
  selectInputStyles,
  valueContainerStyles,
  singleValueStyles,
  multiValueStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  indicatorsContainerStyles,
  clearIndicatorStyles,
  indicatorSeparatorStyles,
  dropdownIndicatorStyles,
  menuStyles,
  groupHeadingStyles,
  optionStyles,
  noOptionsMessageStyles,
} from '@/components/ui/react-select-styles';
import { DropdownIndicator, ClearIndicator, CustomInput } from '@/components/ui/react-select-elements';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useAutocompleteTags } from '@/hooks/tags/useTags';
import { useToast } from '@/components/ui/use-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'lodash';
import { moveTag } from '@/services/tags.service';
import { Option } from '@/types/select-option';
import { cn } from '@/lib/utils';
import Select from 'react-select';

export const TagSelector: FC<{ tagId: string; parentTag: { id: string; name: string } | null }> = ({
  tagId,
  parentTag,
}) => {
  const queryClient = useQueryClient();

  const { toast } = useToast();
  const { isAuthenticated } = useAuth0();

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  useEffect(() => {
    if (parentTag) {
      setSelectedValue({ label: parentTag.name, value: parentTag.id });
    }
  }, [parentTag]);

  const moveTagFn = useAuthenticatedQueryFn(moveTag);

  const moveTagMutation = useMutation({
    mutationFn: moveTagFn,
    onSuccess: (result) =>
      toast({
        title: 'Tag moved successfully',
        description: `Successfully relocated tag "${result.moved[0].tags[0].name}" to its new parent, tag "${result.moved[0].parentTag.name}"`,
      }),
    onError: (err: any) =>
      toast({
        title: 'Error',
        description: err ? err.error : `Failed to move tag`,
      }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['tagList'] }),
  });

  const onChange = (newOption: Option | null) => {
    moveTagMutation.mutate({
      move: [
        {
          parentTagId: newOption?.value,
          tags: [tagId],
        },
      ],
    });

    if (newOption) {
      setSelectedValue({ label: newOption.label, value: newOption.value });
    }
  };

  const debounceInputChange = useCallback(
    debounce((value) => {
      setInputValue(value);
    }, 300),
    [],
  );

  const handleInputChange = (newValue: string) => {
    if (newValue.length >= 1) {
      debounceInputChange(newValue);
    } else {
      setInputValue('');
    }
  };

  const { tags, areTagsFetching } = useAutocompleteTags(
    { q: inputValue },
    { enabled: Boolean(inputValue) && inputValue.length >= 1 && isAuthenticated },
  );

  return (
    <Select
      value={selectedValue}
      isMulti={false}
      options={(tags?.suggestions ?? []).map((tag) => ({ label: tag.name, value: tag.id }))}
      onChange={onChange}
      onInputChange={handleInputChange}
      isLoading={areTagsFetching}
      isOptionDisabled={(option) => option.value === tagId}
      components={{
        Input: CustomInput,
        DropdownIndicator,
        ClearIndicator,
      }}
      noOptionsMessage={() => 'No options found'}
      classNames={{
        control: ({ isFocused, isDisabled }) =>
          cn(
            isDisabled && controlStyles.disabled,
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base,
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected, isDisabled }) =>
          cn(
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            isDisabled && optionStyles.disabled,
            optionStyles.base,
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
    />
  );
};
