import { FC } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useDialog } from '@/context/DialogContext';
import { useTree } from '@/hooks/data/tree/useTree';
import { useRouter } from 'next/router';
import { NodeType } from '@/types/tree';
import { RemoveAssetsResponse } from '@/types/album';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { RemoveAssetsCommand } from '@/hooks/commands/albums/RemoveAssetsCommand';
import { TrashNodesCommand } from '@/hooks/commands/trash/TrashNodesCommand';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useShallow } from 'zustand/react/shallow';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DeleteNodeAlertDialog } from '@/components/delete-dialogs/delete-node-alert-dialog';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useBrowserStore } from '@/hooks/data/browser/useBrowserStore';
import { useBatchDeleteAssets } from '@/hooks/data/assets/useBatchDeleteAssets';
import { useBatchRemoveAssets } from '@/hooks/data/assets/useBatchRemoveAssets';
import { ObjectAction, ObjectType } from '@/types/batch';

export const DeleteNodeAlertComponent: FC<{ count?: number }> = ({ count }) => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId } = useDialog();
  const {
    selectedNodes,
    currentSelectedType,
    setSelectedNodes,
    setCurrentSelectedType,
    selectedAlbum,
    selectedFolder,
  } = useTree();
  const { selectedAssetIds, deselectAllAssets, setSelectedAssetIds } = useAssetSelection();
  const { query, push } = useRouter();
  const { pageIndex } = query;
  const { browseRedirection, isDuplicatesPage } = useCurrentPage();
  const { apply, undo } = useCommandContext();

  const queryClient = useQueryClient();
  const album = useTreeStore((state) => state.selectedAlbum);
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const findParentNode = useTreeStore(useShallow((state) => state.findParentNode));
  const findNodePath = useTreeStore(useShallow((state) => state.findNodePath));
  const { setIsAllSelected, setIsAllEntitySelected, isAllEntitySelected } = useBrowserStore();
  const { batchDeleteAssetsMutation } = useBatchDeleteAssets();
  const { batchRemoveAssetsMutation } = useBatchRemoveAssets();

  const getPageNumber = (
    totalCount: number,
    selectedAssets: Array<{ id: string; name: string }>,
    pageNumber: string | Array<string> | undefined,
  ) => {
    return (totalCount - selectedAssets.length) % 10 === 0 ? Number(pageNumber) - 1 : Number(pageNumber ?? 0);
  };
  const removeCommand = RemoveAssetsCommand(
    String(album),
    selectedAssetIds.map(({ id }) => String(id)),
  );
  const trashCommand = TrashNodesCommand(selectedNodes.map(({ id }) => String(id)));
  const isRemove = album && selectedAssetIds?.length;
  const selectedList = isRemove ? selectedAssetIds : selectedNodes;
  const deleteMessage = `The selected ${
    currentSelectedType === NodeType.Folders ? 'folder' : currentSelectedType === NodeType.Albums ? 'album' : 'asset'
  }${selectedAssetIds && selectedAssetIds.length > 1 ? 's' : ''} will be moved to trash`;
  const removeMessage = `The selected asset${
    selectedAssetIds && selectedAssetIds.length > 1 ? 's' : ''
  } will be removed from the album`;

  const message = (
    <>
      {isRemove ? removeMessage : deleteMessage}
      <ul className="py-2">{selectedList?.slice(0, 5).map(({ id, name }) => <li key={id}>{name}</li>)}</ul>
      {selectedList?.length > 5 && (
        <p>and {isAllEntitySelected && count ? count - 5 : selectedList.length - 5} more...</p>
      )}
    </>
  );

  const removeAction = () => {
    if (isAllEntitySelected) {
      if (selectedAlbum) {
        batchRemoveAssetsMutation.mutate({
          objectType: ObjectType.Album,
          objectAction: ObjectAction.Album_Asset_Update,
          filter: {
            albumId: selectedAlbum,
          },
          payload: {
            albumId: selectedAlbum,
            remove: true,
          },
        });
      }
    } else {
      apply(removeCommand).then((response: RemoveAssetsResponse) => {
        const removedCount = response?.remove?.length;

        if (removedCount) {
          toast({
            title: 'Asset(s) removed',
            description: `${removedCount} of ${selectedAssetIds.length} asset(s) have been successfully removed`,
            action: (
              <ToastAction onClick={undo} altText="Undo remove asset(s)">
                Undo
                <ArrowUturnLeftIcon className="ml-1 size-3" />
              </ToastAction>
            ),
          });
        }
      });
    }
    // Deselect assets and update inspector to show selected folder/album details
    deselectAllAssets();
    setIsAllSelected(false);
    setIsAllEntitySelected(false);
    setCurrentSelectedType(NodeType.Albums);
  };

  const deleteAction = () => {
    if (isAllEntitySelected) {
      if (selectedFolder) {
        batchDeleteAssetsMutation.mutate({
          objectType: ObjectType.Node,
          objectAction: ObjectAction.Trash,
          filter: {
            folderId: selectedFolder,
          },
          payload: {
            remove: true,
          },
        });
      }
    } else {
      apply(trashCommand).then(() => {
        let type;

        if (currentSelectedType === NodeType.Assets) {
          const updatedPage = count && selectedNodes ? getPageNumber(count, selectedNodes, pageIndex) : pageIndex;

          void push({
            query: {
              ...query,
              pageIndex: Number(updatedPage ?? 1),
            },
          });

          void queryClient.invalidateQueries({ queryKey: [`${isDuplicatesPage ? 'duplicates' : 'assetData'}`] });

          type = selectedAssetIds && selectedAssetIds.length > 1 ? 'assets' : 'asset';
        } else {
          type = currentSelectedType === NodeType.Folders ? 'folder' : 'album';

          const parentNode = findParentNode(selectedNodes[0]?.id);
          const parentPathArray = findNodePath(parentNode?.id ?? '');

          setSelectedAlbum(undefined);
          setSelectedFolder(parentNode?.id);
          browseRedirection(parentPathArray);
          // if (!parentNode) {
          //   void queryClient.invalidateQueries({ queryKey: ['tree'] });
          // }
        }

        toast({
          title: `${_.capitalize(type)} deleted`,
          description: `Your ${type} has been successfully deleted`,
          action: (
            <ToastAction onClick={undo} altText="Undo node delete">
              Undo
              <ArrowUturnLeftIcon className="ml-1 size-3" />
            </ToastAction>
          ),
        });
      });
    }
    setSelectedNodes([]);
    setSelectedAssetIds([]);
    setCurrentSelectedType(NodeType.Folders);
    deselectAllAssets();
    setIsAllSelected(false);
    setIsAllEntitySelected(false);
  };

  return (
    <DeleteNodeAlertDialog
      open={openDialogId === 'deleteConfirmation'}
      setOpenDialogId={setOpenDialogId}
      deleteAction={deleteAction}
      removeAction={removeAction}
      message={message}
      isRemove={Boolean(isRemove)}
    />
  );
};
