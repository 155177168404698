import { FC, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { BackendError, useFormErrorHandler } from '@/hooks/useFormErrorHandler';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { error as errorLog, warn } from '@/utilities/log';
import { FormProvider, useForm } from 'react-hook-form';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { Loader2, Save } from 'lucide-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderField } from './form/field-components';
import { ToastAction } from '@/components/ui/toast';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { editTag } from '@/services/tags.service';
import { Button } from '@/components/ui/button';
import { useTag } from '@/hooks/tags/useTags';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export const TagFields: FC<{ tagId: string }> = ({ tagId }) => {
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['metadata-manager']);

  const queryClient = useQueryClient();

  const { tag: fieldData, tagIsFetching, tagIsPending } = useTag(tagId, {}, null);
  const { toast } = useToast();

  const editUserWithAuth = useAuthenticatedQueryFn(editTag);

  const editTagMutation = useMutation({
    mutationFn: editUserWithAuth,
    onSuccess: (result) => {
      toast({
        title: 'Tag Updated',
        description: (
          <div className="flex">
            The tag <span className="mx-1 inline-block max-w-20 truncate font-bold">{result.name}</span> has been
            successfully updated
          </div>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tagList'] });

      // TODO: We need to find a better way to handle this on the frontend,
      // but for now after updating the tag, we refresh its data from the server.
      // This is necessary because our API update logic ties the "public" field
      // to the "searchable" field (if searchable is false, public is forced to false).
      // Refreshing ensures the UI displays the correct, updated tag state.
      void queryClient.invalidateQueries({ queryKey: ['getTag', tagId] });
    },
    onError: (err: BackendError) => handleError(err),
  });

  const isLoading = editTagMutation.status === 'pending';
  const disabled = tagIsFetching || isLoading;

  const formFields: Array<any> = useMemo(
    () => [
      {
        'name': 'Name',
        'slug': 'name',
        'type': 'string',
        'disabled': disabled,
        'value': fieldData.name,
        'data-cy': 'tag-field-name',
        'validation': z.string().min(1, { message: 'Name field is required.' }),
      },
      {
        name: 'Parent tag',
        slug: 'parent_tag',
        type: 'tag_selector',
        disabled: disabled,
        tagId: tagId,
        parentTag: fieldData.parentTag,
        dataCy: 'tag-field-parent_tag',
      },
      {
        'name': 'Facet',
        'slug': 'facet',
        'description': 'Whether the tag is considered a facet. It must have children in order to appear as a facet.',
        'type': 'boolean',
        'disabled': disabled,
        'value': fieldData.facet,
        'data-cy': 'tag-field-facet',
        'validation': z.boolean().optional(),
      },
      {
        'name': 'Searchable',
        'slug': 'searchable',
        'description': 'Whether assets with this tag applied to will appears in search results including the tag name.',
        'type': 'boolean',
        'disabled': disabled,
        'value': fieldData.searchable,
        'data-cy': 'tag-field-searchable',
        'validation': z.boolean().optional(),
      },
      {
        'name': 'Public',
        'slug': 'public',
        'description': 'Whether the tag can be shown publicly when publishing the asset externally.',
        'type': 'boolean',
        'disabled': disabled,
        'value': fieldData.public,
        'data-cy': 'tag-field-public',
        'validation': z.boolean().optional(),
      },
    ],
    [fieldData, disabled],
  );

  const formSchema = z.object(
    formFields.reduce(
      (acc, field) => {
        if (field.validation) {
          // Include only fields with validation property in the form schema
          acc[field.slug] = field.validation;
        }
        return acc;
      },
      {} as Record<string, z.ZodType<any>>,
    ),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  // Populate form when `tagId` changes
  useEffect(() => {
    form.reset(defaultValues);
  }, [form, fieldData, tagId]);

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      if (typeof values === 'object' && values !== null) {
        editTagMutation.mutate({
          id: tagId,
          body: {
            name: values.name,
            facet: values.facet,
            searchable: values.searchable,
            public: values.public,
          },
        });
      } else {
        warn('Invalid data for editing user');
      }
    } catch (err) {
      errorLog('Action failed:', err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    }
  }

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['tags-manager-audit-log']}
    >
      <AccordionItem value={'tag'}>
        <AccordionTrigger>Tag</AccordionTrigger>
        <AccordionContent>
          {tagIsPending ? (
            <div className="flex flex-col space-y-6">
              {Array.from(Array(3)).map((_, index) => (
                <>
                  <div key={`skeleton-${index}`} className="flex flex-col space-y-3">
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index === 0 })} />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index === 1 })} />
                  </div>
                  {index !== 2 && <Separator className="block" />}
                </>
              ))}
            </div>
          ) : (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {formFields.map((field) => renderField(form, field, 'hidden'))}

                <Button
                  disabled={disabled}
                  type="submit"
                  variant="default"
                  className="w-full justify-center @[21rem]/inspector:w-auto"
                  data-cy="tag-save-button"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 size-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 size-4" />
                      Save
                    </>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
        </AccordionContent>
      </AccordionItem>
      <AuditLogAccordion
        multipleAssetSelected={false}
        selectedAssetIds={[{ id: fieldData?.id, name: fieldData?.name }]}
        currentAccordionItems={currentAccordionItems}
        entity="Tag"
      />
    </Accordion>
  );
};
