import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getDestinationAuditLog } from '@/services/destination.service';
import { getMetadataAuditLog } from '@/services/metadata.service';
import { getWorkflowAuditLog } from '@/services/workflow.service';
import { getVariantAuditLog } from '@/services/variant.service';
import { getFolderAuditLog } from '@/services/folder.service';
import { getAssetAuditLog } from '@/services/asset.service';
import { getAlbumAuditLog } from '@/services/album.service';
import { getUserAuditLog } from '@/services/users.service';
import { getTagAuditLog } from '@/services/tags.service';
import { EntityType } from '@/types/entity';
import { NodeType } from '@/types/tree';

export const useAudit = (page: number, resultsPerPage: number, id?: string, nodeType?: string, options?: object) => {
  const destinationAuditQueryFn = useAuthenticatedQueryFn(getDestinationAuditLog);
  const workflowAuditQueryFn = useAuthenticatedQueryFn(getWorkflowAuditLog);
  const metadataAuditQueryFn = useAuthenticatedQueryFn(getMetadataAuditLog);
  const variantAuditQueryFn = useAuthenticatedQueryFn(getVariantAuditLog);
  const folderAuditQueryFn = useAuthenticatedQueryFn(getFolderAuditLog);
  const assetAuditQueryFn = useAuthenticatedQueryFn(getAssetAuditLog);
  const albumAuditQueryFn = useAuthenticatedQueryFn(getAlbumAuditLog);
  const userAuditQueryFn = useAuthenticatedQueryFn(getUserAuditLog);
  const tagAuditQueryFn = useAuthenticatedQueryFn(getTagAuditLog);

  const pagination = { limit: Number(resultsPerPage), offset: (Number(page ?? 1) - 1) * resultsPerPage };

  const {
    data: audits,
    isPending: auditsArePending,
    isFetching: auditsAreFetching,
    error: auditError,
    refetch: refetchAudits,
  } = useQuery({
    queryKey: ['auditData', { ...pagination, id }],
    queryFn: () => {
      if (nodeType === NodeType.Folders) {
        return folderAuditQueryFn({ pagination, id });
      } else if (nodeType === NodeType.Albums) {
        return albumAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.User) {
        return userAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.Destination) {
        return destinationAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.Variant) {
        return variantAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.Metadata) {
        return metadataAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.Tag) {
        return tagAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.Workflow) {
        return workflowAuditQueryFn({ pagination, id });
      } else {
        return assetAuditQueryFn({ pagination, id });
      }
    },
    placeholderData: keepPreviousData,
    ...options,
  });

  return { audits, auditsAreFetching, auditsArePending, auditError, refetchAudits };
};
