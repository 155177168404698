import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useCloseModal } from '@/context/ModalContext';
import { Modal } from '@/components/ui/modal';
import { FC, PropsWithChildren } from 'react';

export const CreateNoteDialog: FC<PropsWithChildren & { id: string; title: string }> = ({ id, title, children }) => {
  const closeModal = useCloseModal(id);

  return (
    <Modal id={id} onOpenChange={() => closeModal()}>
      <DialogContent onPointerDownOutside={(e) => e.preventDefault()} className="p-0">
        <DialogHeader className="p-6">
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Modal>
  );
};
