import { useCallback, CSSProperties, Dispatch, SetStateAction } from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { dropStyle, hoverStyles, selectedStyles, styles } from '@/components/sidebar/sidebar';
import { useDraggable, useDndMonitor, useDroppable } from '@dnd-kit/core';
import { NodeType, TreeRecursiveChildrenNode } from '@/types/tree';
import { BookImage, Pen, Trash2Icon, Users } from 'lucide-react';
import { useBatchUpdateAssets } from '@/hooks/data/assets/useBatchUpdateAssets';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { error as errorLog } from '@/utilities/log';
import { AddAssetCommand } from '@/hooks/commands/albums/AddAssetCommand';
import { useBrowserStore } from '@/hooks/data/browser/useBrowserStore';
import { PaginationState } from '@/types/pagination';
import { SortOrderValues } from '@/types/sort';
import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DraggableItems } from '@/types/dragDrop';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { useOpenModal } from '@/context/ModalContext';
import { ToastAction } from '@/components/ui/toast';
import { useDialog } from '@/context/DialogContext';
import { useRouter } from 'next/router';
import { useToast } from '@/components/ui/use-toast';
import { useTree } from '@/hooks/data/tree/useTree';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';
import _ from 'lodash';
import { ObjectAction, ObjectType } from '@/types/batch';

type AlbumProps = {
  treeBranch: TreeRecursiveChildrenNode;
  parentId?: string;
  depth: number;
  handleOnSortChange: (value: string) => void;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
};

export function Album({ treeBranch, parentId, depth, handleOnSortChange, setPagination }: AlbumProps) {
  const {
    id,
    node: { name, id: albumId },
    shared,
  } = treeBranch;
  const { setIsAllSelected, setIsAllEntitySelected, isAllEntitySelected } = useBrowserStore();
  const { setSelectedNodes, setCurrentSelectedType, selectedAlbum } = useTree();
  const { findNodePath, setSelectedFolder, setSelectedAlbum } = useTreeStore();
  const { selectedAssetIds, deselectAllAssets } = useAssetSelection();
  const { addAssets, removeAssets } = useAlbumAssets();
  const { browseRedirection } = useCurrentPage();
  const { batchUpdateAssetsMutation } = useBatchUpdateAssets();
  const { query, push } = useRouter();
  const { openModal } = useDialog();
  const { apply } = useCommandContext();
  const { toast } = useToast();

  const openModalDialog = useOpenModal();

  const onSelectAlbum = useCallback(() => {
    const pathArray = findNodePath(id);

    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Albums);
    setSelectedFolder(undefined);
    setSelectedAlbum(id);
    setIsAllSelected(false);
    setIsAllEntitySelected(false);

    handleOnSortChange(SortOrderValues.CREATED_AT);
    if (setPagination) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
    }

    browseRedirection(pathArray);
  }, [
    id,
    albumId,
    push,
    query,
    selectedAlbum,
    setCurrentSelectedType,
    setSelectedNodes,
    handleOnSortChange,
    setPagination,
  ]);

  const { setNodeRef, isOver } = useDroppable({
    id: albumId,
    data: {
      type: DraggableItems.ALBUM,
      id: albumId,
      parentId: parentId,
      accepts: [DraggableItems.ASSET],
    },
  });

  const {
    attributes,
    listeners,
    setNodeRef: setDragRef,
    transform,
    isDragging,
  } = useDraggable({
    id,
    data: { type: DraggableItems.ALBUM, id, name, parentId },
  });

  useDndMonitor({
    onDragEnd(event) {
      void (async () => {
        const { active, over } = event;

        if (
          over?.id === albumId &&
          over.data.current?.accepts.includes(active.data.current?.type) &&
          over?.data?.current?.type === DraggableItems.ALBUM &&
          active?.data?.current?.type === DraggableItems.ASSET
        ) {
          // Check if the whole album is selected.
          if (isAllEntitySelected) {
            openModalDialog('batch-move-add-assets-confirmation-dialog', {
              message: (
                <>
                  Are you sure you want to add <span className="font-bold">all</span> assets to this album?
                </>
              ),
              confirmLabel: 'Add',
              onConfirm: async () => {
                const albumName = _.truncate(name, {
                  length: 50,
                  omission: '...',
                });

                try {
                  await batchUpdateAssetsMutation({
                    objectType: ObjectType.Album,
                    objectAction: ObjectAction.Album_Asset_Update,
                    filter: {
                      ...(selectedAlbum
                        ? { albumId: active.data.current?.parentNodeId }
                        : { folderId: active.data.current?.parentNodeId }),
                    },
                    payload: {
                      albumId: albumId,
                      add: true,
                    },
                  });

                  toast({
                    title: 'Assets added',
                    description: (
                      <div className="flex flex-wrap gap-x-1">
                        All selected assets have been added to the album
                        <span className="font-bold" title={name}>
                          {albumName}
                        </span>
                      </div>
                    ),
                  });

                  deselectAllAssets();
                  setIsAllSelected(false);
                  setIsAllEntitySelected(false);
                } catch (error) {
                  errorLog('Action failed:', error);

                  toast({
                    title: 'Adding assets failed',
                    variant: 'destructive',
                    description: (
                      <div className="flex flex-wrap gap-x-1">
                        An error occurred while adding all assets to album
                        <span className="font-bold" title={name}>
                          {albumName}
                        </span>
                      </div>
                    ),
                  });
                }
              },
            });
            return;
          }

          let assetIds = [active.data?.current?.id];
          if (active.data?.current?.type === DraggableItems.ASSET && selectedAssetIds?.length > 1) {
            assetIds = selectedAssetIds.map((asset) => asset.id);
          }

          // Fallback logic if not all assets are selected...
          const addAssetCommand = AddAssetCommand(addAssets, removeAssets, albumId, assetIds);
          const response = await apply(addAssetCommand);
          const assetsAdded = response.add ?? assetIds;
          const wereAssetsAdded = assetsAdded.length > 0;

          if (active.data?.current?.type === DraggableItems.ASSET) {
            toast({
              title: wereAssetsAdded ? 'Asset(s) added' : 'No asset has been added',
              description: wereAssetsAdded
                ? `Asset(s) have been added successfully.`
                : 'The album already contains the asset(s).',
              action: wereAssetsAdded ? (
                <ToastAction
                  onClick={() => removeAssets({ albumId: albumId, assetIds: assetsAdded })}
                  altText="Undo add asset(s)"
                >
                  Undo
                  <ArrowUturnLeftIcon className="ml-1 size-3" />
                </ToastAction>
              ) : undefined,
            });
            deselectAllAssets();
            setIsAllSelected(false);
            setIsAllEntitySelected(false);
          }
        }
      })();
    },
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1">
        <li
          key={id}
          className={cn('mt-px flex flex-col', isDragging && 'rounded-md border border-dashed border-separator-color')}
          title={name}
        >
          <div
            style={{ paddingLeft: `${depth * 15}px`, ...style }}
            ref={setNodeRef}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              hoverStyles,
              selectedAlbum === albumId && selectedStyles,
              isOver && dropStyle,
            )}
          >
            <button
              onClick={() => onSelectAlbum()}
              onContextMenu={() => onSelectAlbum()}
              className={cn('flex w-full items-center justify-start py-1 pl-6 outline-none')}
              ref={setDragRef}
              {...listeners}
              {...attributes}
            >
              <BookImage className="mr-2 inline-block size-4 min-w-4 stroke-teal-600" strokeWidth={2} />
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === albumId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
              {shared && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Users className="inline-block size-4 min-w-4" strokeWidth={2} />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>This is a shared album</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              )}
            </button>
          </div>
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'renameAlbum', { id: albumId })}>
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectAlbum();
            openModal('deleteConfirmation');
          }}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
