import { useEffect, useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { findKey, has, get, isEmpty } from 'lodash';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useWorkflowList } from '@/hooks/workflow/useWorkflow';
import { setWorkflow } from '@/services/workflow.service';
import { ToastAction } from '@/components/ui/toast';
import { TrafficCone } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { Asset, AssetWorkflowMetadata } from '@/types/asset';
import { cn } from '@/lib/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { WorkflowStatuses } from '@/types/workflow';
import { useBatchEditAssets } from '@/hooks/data/assets/useBatchEditAssets';
import { ObjectAction, ObjectType } from '@/types/batch';

export const WorkflowAccordion = ({
  asset,
  currentAccordionItems,
  multipleAssetsWorkflows,
  selectedAssetIds,
  folderId,
  albumId,
  disabled,
  readOnly = false,
}: {
  asset?: Asset;
  currentAccordionItems: Array<string>;
  multipleAssetsWorkflows?: AssetWorkflowMetadata;
  selectedAssetIds?: Array<{ id: string; name: string }>;
  folderId?: string;
  albumId?: string;
  disabled?: boolean;
  readOnly?: boolean;
}) => {
  const queryClient = useQueryClient();

  const [selectedWorkflow, setSelectedWorkflow] = useState({});

  const { batchEditAssetsMutation } = useBatchEditAssets();
  const { isAuthenticated } = useAuth0();
  const { toast } = useToast();
  const { workflowList, workflowListIsFetching } = useWorkflowList(
    {
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: currentAccordionItems?.includes('workflow') && isAuthenticated },
  );
  const { workflows } = workflowList;

  useEffect(() => {
    if (multipleAssetsWorkflows) {
      setSelectedWorkflow(multipleAssetsWorkflows);
    } else if (asset?.workflows) {
      setSelectedWorkflow(asset?.workflows);
    }
  }, [asset?.workflows, multipleAssetsWorkflows]);

  const setWorkflowWithAuth = useAuthenticatedQueryFn(setWorkflow);
  const setWorkflowMutation = useMutation({
    mutationFn: setWorkflowWithAuth,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['asset'] });

      toast({
        title: 'Workflow updated',
        description: (
          <div className="flex">
            The workflow for <span className="mx-1 inline-block max-w-20 truncate font-bold">{asset?.name}</span> has
            been successfully updated.
          </div>
        ),
      });
    },
    onError: (err) => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Unable to update workflow',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const handleChange = (selectedValue: string) => {
    const filteredWorkflow = workflows.filter((workflow) =>
      Object.values(workflow.statuses).find((status) => status.id === selectedValue),
    );
    const statusSlug = findKey(filteredWorkflow[0].statuses, { id: selectedValue });
    if (multipleAssetsWorkflows) {
      batchEditAssetsMutation.mutate({
        objectType: ObjectType.Asset,
        objectAction: ObjectAction.Update,
        filter: {
          ...(selectedAssetIds && { assetIds: selectedAssetIds.map((selectedAsset) => selectedAsset.id) }),
          ...(folderId && { folderId }),
          ...(albumId && { albumId }),
        },
        payload: {
          workflows: { [filteredWorkflow[0].slug]: statusSlug },
        },
      });
    } else {
      setWorkflowMutation.mutate({
        asset_id: asset?.id,
        workflows: { [filteredWorkflow[0].slug]: statusSlug },
      });
    }
  };

  return (
    <AccordionItem value="workflow" disabled={disabled}>
      <AccordionTrigger disabled={disabled}>Workflows</AccordionTrigger>
      {workflows && workflows.length > 0 ? ( // TODO it should be with && workflows.statuses.length > 0 and the types should be adjusted
        workflows.map(
          ({ slug, statuses }) =>
            !isEmpty(statuses) && (
              <AccordionContent
                key={slug}
                className="flex flex-col gap-2.5 @[11rem]/inspector:m-1 @[18rem]/inspector:px-2"
              >
                <span className="capitalize">{slug}:</span>{' '}
                <Select onValueChange={handleChange} disabled={workflowListIsFetching}>
                  <SelectTrigger
                    disabled={readOnly}
                    className="bg-input-background"
                    indeterminate={Boolean(multipleAssetsWorkflows && !multipleAssetsWorkflows[slug])}
                  >
                    <SelectValue
                      placeholder={
                        selectedWorkflow && has(selectedWorkflow, slug)
                          ? get<WorkflowStatuses>(statuses, get(selectedWorkflow, slug)).name
                          : 'Select an option'
                      }
                    />
                  </SelectTrigger>
                  {!readOnly && (
                    <SelectContent>
                      {Object.values(statuses).map(({ id, name, color }) => (
                        <SelectItem key={id} value={id}>
                          <div className="flex items-center justify-between">
                            <div
                              style={{ backgroundColor: color }}
                              className={cn('ml-0.5 mr-2.5 size-2 rounded-full')}
                            />
                            <div>{name}</div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  )}
                </Select>
              </AccordionContent>
            ),
        )
      ) : (
        <AccordionContent className="py-8 text-center text-dark-gray-text">
          <TrafficCone className="mx-auto mb-2" size={28} strokeWidth={1.5} />
          No workflow
        </AccordionContent>
      )}
    </AccordionItem>
  );
};
