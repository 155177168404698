import { FC, useEffect, useMemo, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import { createAssetNotes, deleteAssetNotes, undeleteAssetNotes, updateAssetNotes } from '@/services/asset.service';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { EllipsisVertical, Pen, Plus, Trash2Icon } from 'lucide-react';
import { useOpenModal, useCloseModal } from '@/context/ModalContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DialogFooter, DialogClose } from '@/components/ui/dialog';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DeleteNoteAlertDialog } from '@/components/delete-dialogs/delete-note-alert-dialog';
import { parseISO, compareDesc } from 'date-fns';
import { useCommandContext } from '@/context/CommandContext';
import { error as errorLog } from '@/utilities/log';
import { CreateNoteDialog } from '@/components/create-dialogs/create-note-dialog';
import { OptionAction } from '@/components/inspector/entity-fields/form/option-action';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ToastAction } from '@/components/ui/toast';
import { NoteDialog } from '@/components/note-dialog';
import { useNotes } from '@/hooks/notes/useNotesList';
import { useToast } from '@/components/ui/use-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import { Asset } from '@/types/asset';
import { Form } from '@/components/ui/form';
import { z } from 'zod';

type OptionItemProps = {
  option: {
    id: string;
    assetId: string;
    createdAt: string;
    createdBy: { name: string };
    note: string;
  };
  onEdit: () => void;
  readOnly?: boolean;
};

export const OptionItem: FC<OptionItemProps> = ({
  option: { id, assetId, createdAt, createdBy, note },
  onEdit,
  readOnly,
}) => {
  const openModal = useOpenModal();

  return (
    <div key={id} className="flex flex-col justify-center gap-2 px-3">
      <div className="flex w-full items-start gap-2">
        <div className="flex grow items-center gap-2">
          <div className="w-full">
            <p title={createdBy?.name} className="line-clamp-1 break-all text-sm" data-cy="asset-note-created-by">
              {createdBy?.name}
            </p>
            {createdAt && (
              <p className="text-xs text-dark-gray-text" data-cy="asset-note-created-at">
                {format(createdAt, 'yyyy-MM-dd HH:mm')}
              </p>
            )}
            {note && (
              <p
                className="mt-0.5 line-clamp-5 whitespace-pre-line text-xs text-gray-text [overflow-wrap:anywhere] hover:cursor-pointer"
                title={'Double click to show full note'}
                onDoubleClick={() => openModal('assetNote', { note })}
                data-cy="asset-note"
              >
                {note}
              </p>
            )}
          </div>
        </div>

        {!readOnly && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex gap-1">
                <OptionAction icon={EllipsisVertical} type="button" data-cy="asset-note-options" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  if (id) {
                    onEdit();
                  }
                }}
                data-cy="edit-asset-note"
              >
                <Pen className="mr-2 size-4" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="!text-red-600 focus:!bg-red-100/50 dark:focus:!bg-red-950/50"
                onClick={() => openModal('deleteNoteConfirmation', { assetId, noteId: id })}
                data-cy="delete-asset-note"
              >
                <Trash2Icon className="mr-2 size-4" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export const NotesAccordion = ({
  multipleAssetSelected,
  asset,
  currentAccordionItems,
  readOnly = false,
}: {
  multipleAssetSelected: boolean;
  asset: Asset;
  currentAccordionItems: Array<string>;
  readOnly?: boolean;
}) => {
  const [selectedNote, setSelectedNote] = useState<{ id?: string; note: string } | null>(null);

  const queryClient = useQueryClient();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();

  const { apply, undo } = useCommandContext();
  const { isAuthenticated } = useAuth0();
  const { toast } = useToast();

  const { data: { notes = [] } = {}, notesIsFetching } = useNotes(asset?.id, {
    enabled: currentAccordionItems?.includes('notes') && isAuthenticated && Boolean(asset?.id),
  });

  const formFields: Array<any> = useMemo(
    () => [
      {
        name: 'Note',
        slug: 'note',
        type: 'text',
        value: '',
        rows: 6,
        validation: z
          .string({ required_error: 'The note field must be a string.' })
          .min(1, {
            message: 'Note field is required.',
          })
          .max(5000, { message: 'Note must be at most 5000 characters.' }),
      },
    ],
    [],
  );

  const formSchema = z.object(
    formFields.reduce((acc, field) => {
      acc[field.slug] = field.validation;
      return acc;
    }, {}),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const deleteNoteWithAuth = useAuthenticatedQueryFn(deleteAssetNotes);

  const deleteNoteMutation = useMutation({
    mutationFn: deleteNoteWithAuth,
    onSuccess: () => {
      toast({
        title: 'Note Deleted',
        description: `Note has been deleted successfully.`,
        action: (
          <ToastAction altText="Undo" onClick={() => undo()}>
            Undo
          </ToastAction>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['notesList', asset?.id] });
    },
    onError: (err) => {
      errorLog('Action failed:', err);

      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const createAssetNoteWithAuth = useAuthenticatedQueryFn(createAssetNotes);
  const updateAssetNoteWithAuth = useAuthenticatedQueryFn(updateAssetNotes);
  const undeleteNoteWithAuth = useAuthenticatedQueryFn(undeleteAssetNotes);

  const createOrUpdateNoteMutation = useMutation({
    mutationFn: (values: z.infer<typeof formSchema>) =>
      selectedNote?.id
        ? updateAssetNoteWithAuth({ assetId: asset?.id, noteId: selectedNote.id, body: values })
        : createAssetNoteWithAuth({ assetId: asset?.id, body: values }),
    onSuccess: () => {
      toast({
        title: selectedNote?.id ? 'Note Updated' : 'Note Created',
        description: `The note has been successfully ${selectedNote?.id ? 'updated' : 'created'}.`,
      });

      closeModal('createAssetNotes');

      void queryClient.invalidateQueries({ queryKey: ['notesList', asset?.id] });
    },
    onError: (err) => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const undeleteNoteMutation = useMutation({
    mutationFn: undeleteNoteWithAuth,
    onSuccess: () => {
      toast({
        title: 'Note Restored',
        description: 'The note has been restored successfully.',
      });
      void queryClient.invalidateQueries({ queryKey: ['notesList', asset?.id] });
    },
    onError: (err: any) => {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  const openNoteModal = (note?: { id: string; note: string }) => {
    setSelectedNote(note ?? { note: '' });
    form.reset({ note: note?.note ?? '' });
    openModal('createAssetNotes');
  };

  useEffect(() => {
    form.reset({ note: selectedNote?.note ?? '' });
  }, [selectedNote, form]);

  const isLoading = createOrUpdateNoteMutation.isPending;

  return (
    <>
      <AccordionItem value="notes" disabled={multipleAssetSelected}>
        <AccordionTrigger disabled={multipleAssetSelected}>Notes</AccordionTrigger>
        <AccordionContent className="p-0">
          <Options
            disabled={multipleAssetSelected || notesIsFetching}
            handleAddNewOption={() => openNoteModal()}
            addButtonPosition="top"
            addButtonLabel="Add note"
            className="gap-y-5 py-2"
            readOnly={readOnly}
            emptyState={
              <p className="text-balance px-4 py-12 text-center text-gray-icon-color">
                There are currently no notes.{' '}
                {!readOnly && (
                  <>
                    Click{' '}
                    <span className=" whitespace-nowrap">
                      {`"`}
                      <Plus className="inline size-4" /> Add Note{`"`}
                    </span>{' '}
                    to create one.
                  </>
                )}
              </p>
            }
          >
            {
              // Sort notes by creation date desc
              notes
                .sort((a, b) => compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)))
                .map((note) => (
                  <OptionItem key={note.id} option={note} onEdit={() => openNoteModal(note)} readOnly={readOnly} />
                ))
            }
          </Options>
        </AccordionContent>
      </AccordionItem>
      <NoteDialog />
      {!readOnly && (
        <>
          <CreateNoteDialog id="createAssetNotes" title={selectedNote?.id ? 'Edit Note' : 'Create Note'}>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit((values) => createOrUpdateNoteMutation.mutate(values))}
                className="px-4"
              >
                {formFields.map((field) => renderField(form, field, 'hidden'))}
                <DialogFooter className="p-6">
                  <DialogClose asChild className="mt-2 sm:mt-0">
                    <Button
                      onClick={() => closeModal()}
                      variant="secondary"
                      disabled={isLoading}
                      data-cy="create-edit-asset-note-dismiss-button"
                    >
                      Dismiss
                    </Button>
                  </DialogClose>
                  <Button
                    disabled={isLoading}
                    variant={'default'}
                    className="justify-center"
                    data-cy="create-edit-asset-note-create-button"
                  >
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 size-4 animate-spin" />
                        {selectedNote?.id ? 'Saving...' : 'Creating...'}
                      </>
                    ) : selectedNote?.id ? (
                      'Save'
                    ) : (
                      'Create'
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </CreateNoteDialog>
          <DeleteNoteAlertDialog
            id="deleteNoteConfirmation"
            onConfirm={(assetId, noteId) => {
              const deleteNoteCommand = {
                apply: () => deleteNoteMutation.mutateAsync({ assetId, noteId }),
                undo: () => undeleteNoteMutation.mutateAsync({ assetId, noteId }),
                redo: () => deleteNoteMutation.mutateAsync({ assetId, noteId }),
              };

              apply(deleteNoteCommand);
            }}
          />
        </>
      )}
    </>
  );
};
