import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useModalData } from '@/context/ModalContext';
import { Modal } from '@/components/ui/modal';

export const BatchMoveAddAssetsConfirmationDialog: FC<{ id: string }> = ({ id }) => {
  const modalData = useModalData()[0];

  return (
    <Modal id={id} variant="alert">
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>{modalData?.message}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={typeof modalData?.onConfirm === 'function' ? modalData.onConfirm : undefined}>
            {modalData?.confirmLabel ? modalData.confirmLabel : 'Confirm'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </Modal>
  );
};
