import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getPublicAsset, getShare, getPublicShare, getShareList } from '@/services/share.service';
import { SortState } from '@/types/sort';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useSharesList = (
  params: {
    nodeId: string;
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const shareListQueryFn = useAuthenticatedQueryFn(getShareList);

  const {
    data: shareList = { shares: [], pagination: { count: 0, limit: 0, offset: 0 } },
    isPending: shareListIsPending,
    isFetching: shareListIsFetching,
    error: shareListError,
    refetch: refetchShareList,
  } = useQuery({
    queryKey: ['shareList', params.nodeId, params.pagination, params.queryString, params.sort],
    queryFn: () => shareListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    shareList,
    shareListIsFetching,
    shareListIsPending,
    shareListError,
    refetchShareList,
  };
};

export const useShare = (params: { nodeId: string; shareId: string }, options: object, form: any) => {
  const getShareQueryFn = useAuthenticatedQueryFn(getShare);

  const {
    data: share = [],
    isPending: shareIsPending,
    isFetching: shareIsFetching,
    error: shareError,
    refetch: refetchShare,
  } = useQuery({
    queryKey: ['getShare', params.nodeId, params.shareId],
    queryFn: async () => {
      const data = await getShareQueryFn(params);
      form?.reset(data);
      return data;
    },
    ...options,
  });

  return {
    share,
    shareIsPending,
    refetchShare,
    shareIsFetching,
    shareError,
  };
};

export const usePublicShare = (
  shareId: string,
  pagination: { offset: number; limit: number },
  sort: SortState,
  queryString: string,
  options: object,
) => {
  const {
    data: share,
    isPending: shareIsPending,
    isFetching: shareIsFetching,
    isLoading: shareIsLoading,
    isFetched: shareIsFetched,
    error: shareError,
    refetch: refetchShare,
  } = useQuery({
    queryKey: ['share', pagination, sort, queryString],
    queryFn: () => getPublicShare({ shareId, pagination, sort, queryString }),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    share,
    shareIsPending,
    refetchShare,
    shareIsFetching,
    shareIsLoading,
    shareIsFetched,
    shareError,
  };
};

export const usePublicAsset = (
  params: {
    assetId: string;
    shareId: string;
  },
  form?: {
    reset: (data: any) => void;
  },
  options?: object,
) => {
  const {
    data: publicAsset,
    isLoading: isPublicAssetLoading,
    isPending: isPublicAssetPending,
    isFetching: isPublicAssetFetching,
    error: assetError,
    refetch: refetchAsset,
  } = useQuery({
    queryKey: ['asset', params.assetId, params.shareId],
    queryFn:
      params.assetId && params.shareId
        ? async () => {
            const data = await getPublicAsset({ assetId: params.assetId, shareId: params.shareId });
            form?.reset(data);
            return data;
          }
        : () => undefined,
    ...options,
  });

  return {
    publicAsset,
    refetchAsset,
    isPublicAssetFetching,
    isPublicAssetLoading,
    isPending: isPublicAssetPending,
    error: assetError,
  };
};
