import { FileWithPath } from 'react-dropzone';
import { create } from 'zustand';

export type ExtendedFileWithPath = FileWithPath & {
  folderPath: string;
  preview: string;
  id: string;
};

interface BrowserStore {
  isAllSelected: boolean;
  setIsAllSelected: (value: boolean) => void;
  isAllEntitySelected: boolean;
  setIsAllEntitySelected: (value: boolean) => void;
}

export const useBrowserStore = create<BrowserStore>((set, get) => ({
  isAllSelected: false,
  setIsAllSelected: (value: boolean) =>
    set((state) => ({
      ...state,
      isAllSelected: value,
    })),
  isAllEntitySelected: false,
  setIsAllEntitySelected: (value: boolean) =>
    set((state) => ({
      ...state,
      isAllEntitySelected: value,
    })),
}));
