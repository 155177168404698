import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { pageSizeOptions } from '@/types/pagination';
import { shareListSchema, CreateShareParams, UpdateShareParams } from '@/types/share';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';

const NODEBASE = `${INTERNAL_V1}/nodes`;
const SHAREBASE = `${INTERNAL_V1}/shares`;

const getShare = async (params: { nodeId: string; shareId: string }, token?: string) => {
  const response = await damRequest('GET', `${NODEBASE}/${params.nodeId}/shares/${params.shareId}`, token);
  return response;
  // return shareSchema.parse(response);
};

const getPublicShare = async (
  params: {
    shareId: string;
    pagination: { offset: number; limit: number };
    sort: { order: string; value: string };
    queryString?: string;
  },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? pageSizeOptions[0]),
    includeAssets: '1',
    filterQuery: params?.queryString ?? '',
    sortBy: params?.sort?.value ?? SortOrderValues.CREATED_AT,
    sortOrder: params?.sort?.order ?? SortOrderOptions.ASC,
  }).toString();

  const response = await damRequest('GET', `${SHAREBASE}/${params.shareId}?${query}`, token);

  return response;
};

const getPublicAsset = ({ shareId, assetId }: { shareId: string; assetId: string }, token?: string) => {
  const response = damRequest('GET', `${SHAREBASE}/${shareId}/assets/${assetId}/`, token);
  return response;
};

const getShareList = async (
  params: {
    nodeId: string;
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${NODEBASE}/${params.nodeId}/shares?offset=${params.pagination?.offset ?? ''}&limit=${
      params.pagination?.limit ?? ''
    }&filterQuery=${params.queryString}&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return shareListSchema.parse(response);
};

const createShare = async ({ nodeId, body }: CreateShareParams, token?: string) => {
  const response = await damRequest('POST', `${NODEBASE}/${nodeId}/shares`, token, { body });

  return response;
};

const editShare = async ({ nodeId, shareId, body }: UpdateShareParams, token?: string) => {
  const response = await damRequest('PUT', `${NODEBASE}/${nodeId}/shares/${shareId}`, token, { body });

  return response;
};

const deleteShare = ({ nodeId, shareId }: { nodeId: string; shareId: string }, token?: string) =>
  damRequest('DELETE', `${NODEBASE}/${nodeId}/shares/${shareId}`, token);

export { getShare, getPublicShare, createShare, editShare, getShareList, deleteShare, getPublicAsset };
