export enum ObjectType {
  Asset = 'asset',
  Folder = 'folder',
  Album = 'album',
  Node = 'node',
}

export enum ObjectAction {
  Move = 'move',
  Trash = 'trash',
  Update = 'update',
  Album_Asset_Update = 'album_asset_update',
}

export interface BatchUpdateBody {
  objectType: ObjectType;
  objectAction: ObjectAction;
  filter: {
    assetIds?: Array<string>;
    folderId?: string;
    albumId?: string;
    searchQuery?: string;
  };
  payload: {
    parentFolderNodeId?: string; // Required if moving a node
    // Album Asset Update
    add?: boolean;
    remove?: boolean;
    albumId?: string; // Required for album asset updates

    // Asset Update
    workflows?: Record<string, any>;
    metadata?: Record<string, any>;
    tags?: Array<string>;
  };
}
