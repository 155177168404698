import { ButtonHTMLAttributes, forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { LucideIcon } from 'lucide-react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@/lib/utils';

const iconVariants = cva('duration-150', {
  variants: {
    variant: {
      default:
        'text-neutral-500 group-hover/action-button:text-black dark:text-[#333333] dark:group-hover/action-button:text-white',
      destructive:
        'text-neutral-500 group-hover/action-button:text-red-700 dark:text-[#333333] dark:group-hover/action-button:text-red-500',
    },
    size: {
      default: 'size-5',
      sm: 'size-4',
      lg: 'size-8',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface OptionActionProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof iconVariants> {
  asChild?: boolean;
  onClick: () => void;
  icon: LucideIcon;
  [key: string]: any;
}

const OptionAction = forwardRef<HTMLButtonElement, OptionActionProps>(
  ({ onClick, icon: Icon, className, variant, size, asChild = false, ...props }) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp onClick={onClick} className="group/action-button p-[2px]" data-cy="option-action-button" {...props}>
        <Icon className={cn(iconVariants({ variant, size, className }))} />
      </Comp>
    );
  },
);

OptionAction.displayName = 'OptionAction';

export { OptionAction, iconVariants };
