import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getFolderAssets, getAlbumAssets } from '@/services/asset.service';
import { GetAssetsRoot } from '@/types/asset';
import { SortState } from '@/types/sort';
import { useAuth0 } from '@auth0/auth0-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const AssetListQueryKeyPrefix = 'assetData';

export const useAssetList = (
  pagination: { limit: number; offset: number },
  folderId?: string,
  albumId?: string,
  sort?: SortState,
  queryString?: string,
  options?: {
    enabled?: boolean;
  },
  includeSubFolderAssets = false,
) => {
  const folderAssetsQueryFn = useAuthenticatedQueryFn(getFolderAssets);
  const albumAssetsQueryFn = useAuthenticatedQueryFn(getAlbumAssets);

  const queryKey = folderId
    ? [AssetListQueryKeyPrefix, { folderId, includeSubFolderAssets, ...pagination, queryString, sort }]
    : albumId
    ? [AssetListQueryKeyPrefix, { albumId, ...pagination, queryString, sort }]
    : undefined;

  const queryFn = folderId
    ? () => folderAssetsQueryFn({ folderId, pagination, includeSubFolderAssets, queryString, sort })
    : albumId
    ? () => albumAssetsQueryFn({ albumId, pagination, queryString, sort })
    : undefined;

  const mergedOptions = {
    ...options,
    enabled: Boolean(queryFn) && options?.enabled,
  };

  const {
    data: assets,
    isLoading,
    isPending,
    error,
    refetch,
  } = useQuery<GetAssetsRoot>({
    queryKey: queryKey as ReadonlyArray<unknown>,
    queryFn: queryFn,
    placeholderData: keepPreviousData,
    ...mergedOptions,
  });

  return { assets, isLoading, isPending, error, refetch };
};
