import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileInformationAccordion } from '@/components/inspector/file-information-accordion';
import { useDataTableSelection } from '@/context/DataTableSelectionContext';
import { DestinationAccordion } from '@/components/inspector/destination-accordion';
import { InformationAccordion } from '@/components/inspector/information-accordion';
import { MetadataAccordion } from '@/components/inspector/metadata-accordion/metadata-accordion';
import { DestinationFields } from '@/components/inspector/entity-fields/destination-fields';
import { VersionsAccordion } from '@/components/inspector/versions-accordion';
import { WorkflowAccordion } from '@/components/inspector/workflow-accordion';
import { VariantsAccordion } from '@/components/inspector/variants-accordion';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { UploadsAccordion } from '@/components/inspector/uploads-accordion';
import { InvitationFields } from '@/components/inspector/entity-fields/invitation-fields';
import { PreviewAccordion } from '@/components/inspector/preview-accordion';
import { useMultipleAsset } from '@/hooks/data/assets/useMultipleAssets';
import { SharesAccordion } from '@/components/inspector/shares-accordion';
import { useBrowserStore } from '@/hooks/data/browser/useBrowserStore';
import { MetadataFields } from '@/components/inspector/entity-fields/metadata-fields';
import { WorkflowFields } from '@/components/inspector/entity-fields/workflow-fields';
import { usePublicAsset } from '@/hooks/shares/useShares';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { NotesAccordion } from '@/components/inspector/notes-accordion';
import { VariantFields } from '@/components/inspector/entity-fields/variant-fields';
import { TagsAccordion } from '@/components/inspector/tags-accordion';
import { zodResolver } from '@hookform/resolvers/zod';
import { UploadState } from '@/types/uploads';
import { UserFields } from '@/components/inspector/entity-fields/user-fields';
import { EntityType } from '@/types/entity';
import { TagFields } from '@/components/inspector/entity-fields/tag-fields';
import { useFolder } from '@/hooks/data/folder/useFolder';
import { Accordion } from '@/components/ui/accordion';
import { useRouter } from 'next/router';
import { useAlbum } from '@/hooks/data/albums/useAlbum';
import { useAsset } from '@/hooks/data/assets/useAsset';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { Asset } from '@/types/asset';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export const Inspector: FC<{
  data: Array<Asset>;
  pagination?: { offset: number; limit: number; count: number };
  singleAssetShare?: boolean;
  singleAssetView?: boolean;
}> = ({ data, pagination, singleAssetShare, singleAssetView }) => {
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>([]);
  const [scrollY, setScrollY] = useState(0);

  const { currentSelectedType: typeFromTree, selectedAlbum, selectedFolder, setCurrentSelectedType } = useTree();
  const { selectedEntity, selectedEntityType } = useDataTableSelection();
  const { selectedAssetIds: browserSelectedAssetsIds } = useAssetSelection();
  const {
    uploadState,
    selectedAssetIds: uploadSelectedAssetsIds,
    assetsUploadProgress,
    filesData,
    erroredAssets,
  } = useUploadStore();

  const formSchema = z.object({
    name: z.string().min(2, {
      message: 'Asset name must be at least 2 characters.',
    }),
    slug: z.string().optional(),
    description: z.any(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      slug: '',
      description: '',
    },
  });

  const { pathname, query } = useRouter();
  const { isManagePage, isPublicRoute, isUploadPage, isTrashPage } = useCurrentPage();
  const { isAllEntitySelected } = useBrowserStore();

  const selectedAssetIds = useMemo(
    () =>
      isUploadPage
        ? uploadSelectedAssetsIds
        : singleAssetShare
        ? [{ id: data[0].id, name: data[0].name }]
        : browserSelectedAssetsIds,
    [browserSelectedAssetsIds, uploadSelectedAssetsIds, isUploadPage, singleAssetShare, data],
  );

  const currentSelectedType = isPublicRoute
    ? selectedAssetIds.length > 0 || singleAssetShare
      ? NodeType.Assets
      : undefined
    : typeFromTree;

  const isSingleAssetPage = pathname === '/asset/[id]';
  const multipleAssetSelected = selectedAssetIds.length > 1;
  const selectedAssets = data?.filter((asset) => selectedAssetIds.some((id) => id.id === asset.id));
  const stackedAssets = selectedAssets.slice(0, 5);
  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const temporaryAssets = useMemo(() => {
    const selectedIds = selectedAssetIds.map((asset) => asset.id);
    return filesData.filter((file) => selectedIds.includes(file.id));
  }, [filesData, selectedAssetIds]);

  useEffect(() => {
    if (selectedAssetIds.length === 0) {
      setCurrentAccordionItems([]);
    } else if (multipleAssetSelected) {
      setCurrentAccordionItems((prev) => prev.filter((item) => ['metadata', 'workflow', 'tags'].includes(item)));
    } else {
      setCurrentAccordionItems(currentAccordionItems);
    }
  }, [selectedAssetIds, selectedFolder, selectedAlbum, multipleAssetSelected]);

  const {
    asset: privateAsset,
    isAssetLoading,
    isAssetFetching,
  } = useAsset(selectedAssetIds[0]?.id, form, {
    refetchOnWindowFocus: false,
    enabled:
      selectedAssetIds.length === 1 &&
      currentSelectedType === NodeType.Assets &&
      !isPublicRoute &&
      (isUploadPage ? assetsUploadProgress[selectedAssetIds[0]?.id]?.uploaded === true : true),
  });

  const { publicAsset } = usePublicAsset(
    {
      shareId: query.shareId as string,
      assetId: selectedAssetIds[0]?.id,
    },
    form,
    {
      enabled:
        selectedAssetIds.length === 1 &&
        currentSelectedType === NodeType.Assets &&
        isPublicRoute &&
        Boolean(query.shareId),
    },
  );

  // Replace the hardcoded values once the backend is ready
  const { multipleAssets, isMultipleAssetPending } = useMultipleAsset(
    form,
    isAllEntitySelected && selectedFolder ? selectedFolder : undefined,
    isAllEntitySelected && selectedAlbum ? selectedAlbum : undefined,
    !isAllEntitySelected ? selectedAssetIds.map((asset) => asset.id) : undefined,
    '',
    {
      enabled: multipleAssetSelected && currentSelectedType === NodeType.Assets && !isPublicRoute && !isTrashPage,
    },
  );

  const asset = useMemo(() => {
    return isPublicRoute ? (singleAssetShare ? data[0] : publicAsset) : privateAsset;
  }, [isPublicRoute, publicAsset, privateAsset, singleAssetShare, data]);

  const { album, isAlbumLoading, isAlbumFetching } = useAlbum(
    isTrashPage ? selectedAssetIds[0]?.id : selectedAlbum,
    form,
    {
      refetchOnWindowFocus: false,
      enabled:
        currentSelectedType === NodeType.Albums &&
        !isPublicRoute &&
        !isUploadPage &&
        (isTrashPage ? Boolean(selectedAssetIds[0]?.id) : Boolean(selectedAlbum)),
    },
  );

  const { folder, isFolderLoading, isFolderFetching } = useFolder(
    isTrashPage ? selectedAssetIds[0]?.id : selectedFolder,
    form,
    {
      refetchOnWindowFocus: false,
      enabled:
        currentSelectedType === NodeType.Folders &&
        !isPublicRoute &&
        !isUploadPage &&
        (isTrashPage ? Boolean(selectedAssetIds[0]?.id) : Boolean(selectedFolder)),
    },
  );

  const isLoading = isAssetLoading || isAlbumLoading || isFolderLoading;
  const isFetching = isAssetFetching || isAlbumFetching || isFolderFetching;

  const handleScroll = () => {
    const position = scrollRef.current?.scrollTop ?? 0;
    setScrollY(position);
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [selectedAssetIds]);

  /*   const selectedAssetContainerHeight = Math.min(
    Math.max(SelectedAssetContainerMaxHeight - scrollY, SelectedAssetContainerMinHeight),
    SelectedAssetContainerMaxHeight,
  ); */

  const renderManageInspector = useCallback(() => {
    switch (selectedEntityType) {
      case EntityType.User:
        return <UserFields user={selectedEntity!} />;
      case EntityType.Invitation:
        return <InvitationFields invitation={selectedEntity!} />;
      case EntityType.Tag:
        return selectedEntity?.original.id && <TagFields tagId={selectedEntity?.original.id} />;
      case EntityType.Metadata:
        return selectedEntity?.original.id && <MetadataFields metadataId={selectedEntity?.original.id} />;
      case EntityType.Workflow:
        return selectedEntity?.original.id && <WorkflowFields workflowId={selectedEntity?.original.id} />;
      case EntityType.Destination:
        return selectedEntity?.original.id && <DestinationFields destinationId={selectedEntity?.original.id} />;
      case EntityType.Variant:
        return selectedEntity?.original.id && <VariantFields variantId={selectedEntity?.original.id} />;
      default:
        return <div>Unknown entity type</div>;
    }
  }, [selectedEntity, selectedEntityType]);

  const renderContent = () => {
    if (isManagePage) {
      return (
        <>
          {selectedEntity && selectedEntityType ? (
            <div className="relative h-screen overflow-y-auto" ref={scrollRef}>
              {renderManageInspector()}
            </div>
          ) : (
            <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
              No row selected
            </h2>
          )}
        </>
      );
    }
    if (isUploadPage && uploadState === UploadState.NotStarted) {
      return <UploadsAccordion />;
    }

    if (multipleAssetSelected && isMultipleAssetPending && !isPublicRoute && !isTrashPage) {
      return (
        <div className="flex h-screen items-center justify-center">
          <Loader2 className="animate-spin stroke-neutral-950 dark:stroke-white" />
        </div>
      );
    }

    if (multipleAssetSelected && isTrashPage) {
      return (
        <div className="flex h-full flex-col items-center justify-center p-4">
          <h2 className="text-balance text-center text-lg font-semibold tracking-tight text-neutral-500 dark:text-neutral-400">
            Multiple items selected
          </h2>
          <p className="text-balance text-center text-sm font-semibold tracking-tight text-neutral-400 dark:text-neutral-500">
            Please select a single item to view details
          </p>
        </div>
      );
    }
    return (
      <>
        {selectedAssetIds.length > 0 || selectedFolder || selectedAlbum || singleAssetShare ? (
          <>
            {isSingleAssetPage || !singleAssetView ? (
              <div
                className={cn(
                  'sticky z-50 flex max-h-12 min-h-12 items-center gap-3 border-b border-t border-toolbar-bevel border-b-void-gap bg-ul-color px-4 text-lg font-semibold tracking-tight',
                )}
              >
                <p
                  className={cn(
                    'text-balance text-left text-sm text-black transition-all duration-200 dark:text-white',
                  )}
                >
                  {isSingleAssetPage
                    ? ''
                    : currentSelectedType === NodeType.Assets
                    ? multipleAssetSelected
                      ? `${isAllEntitySelected ? pagination?.count : selectedAssetIds.length} Assets Selected`
                      : '1 Asset Selected'
                    : currentSelectedType === NodeType.Folders
                    ? 'Folder Selected'
                    : 'Album Selected'}{' '}
                </p>
              </div>
            ) : null}

            <div className="overflow-y-auto" ref={scrollRef}>
              <Accordion
                type="multiple"
                className="w-full"
                onValueChange={(accordion) => setCurrentAccordionItems(accordion)}
                value={currentAccordionItems}
              >
                {currentSelectedType && (
                  <PreviewAccordion
                    asset={asset}
                    currentSelectedType={currentSelectedType}
                    isUploadPage={isUploadPage}
                    stackedAssets={stackedAssets}
                    temporaryAssets={temporaryAssets}
                    selectedAssetIdsLength={selectedAssetIds?.length}
                    containerRef={containerRef}
                    assetsUploadProgress={assetsUploadProgress}
                    erroredAssets={erroredAssets}
                    disabled={multipleAssetSelected}
                  />
                )}

                {(asset || currentSelectedType === NodeType.Albums || currentSelectedType === NodeType.Folders) && (
                  <>
                    <InformationAccordion
                      disabled={multipleAssetSelected}
                      asset={asset}
                      folder={folder}
                      album={album}
                      form={form}
                      formSchema={formSchema}
                      currentSelectedType={currentSelectedType}
                      selectedAssetIds={selectedAssetIds}
                      isLoading={isLoading}
                      isFetching={isFetching}
                      readOnly={isPublicRoute || isTrashPage}
                    />

                    {currentSelectedType === NodeType.Assets && asset?.fileMetadata && !isPublicRoute && (
                      <FileInformationAccordion
                        disabled={multipleAssetSelected || asset.fileMetadata.length === 0}
                        disabledMessage={
                          multipleAssetSelected ? 'Multiple assets selected' : 'No information available'
                        }
                        asset={asset}
                      />
                    )}
                    {currentSelectedType === NodeType.Assets && (
                      <MetadataAccordion
                        currentAccordionItems={currentAccordionItems}
                        asset={asset}
                        scrollRef={scrollRef}
                        multipleAssetsMetadata={multipleAssetSelected ? multipleAssets?.metadata : undefined}
                        selectedAssetIds={isAllEntitySelected ? undefined : selectedAssetIds}
                        folderId={isAllEntitySelected ? selectedFolder : undefined}
                        albumId={isAllEntitySelected ? selectedAlbum : undefined}
                        disabled={isPublicRoute && multipleAssetSelected}
                        readOnly={isPublicRoute || isTrashPage}
                      />
                    )}
                    {currentSelectedType === NodeType.Assets && asset && !isPublicRoute && (
                      <VersionsAccordion disabled={multipleAssetSelected} asset={asset} readOnly={isTrashPage} />
                    )}
                    {currentSelectedType === NodeType.Assets && (
                      <VariantsAccordion
                        disabled={
                          multipleAssetSelected || Object.values(asset.variants).every((value) => value === null)
                        }
                        disabledMessage={
                          multipleAssetSelected ? 'Multiple assets selected' : 'No information available'
                        }
                        asset={asset}
                      />
                    )}
                    {currentSelectedType !== NodeType.Folders && !isPublicRoute && (
                      <DestinationAccordion
                        multipleAssetSelected={multipleAssetSelected}
                        item={currentSelectedType === NodeType.Assets ? asset : album}
                        currentSelectedType={currentSelectedType}
                        currentAccordionItems={currentAccordionItems}
                        readOnly={isTrashPage}
                      />
                    )}
                    {!isPublicRoute &&
                      ((currentSelectedType === NodeType.Albums && album) ||
                        (currentSelectedType === NodeType.Assets && asset)) &&
                      !isTrashPage && (
                        <SharesAccordion
                          currentAccordionItems={currentAccordionItems}
                          thumbnail={data[0]}
                          item={currentSelectedType === NodeType.Assets ? asset : album}
                          currentSelectedType={
                            currentSelectedType === NodeType.Assets
                              ? 'asset'
                              : currentSelectedType === NodeType.Albums
                              ? 'album'
                              : 'folder'
                          }
                          disabled={multipleAssetSelected}
                          // item={
                          //   currentSelectedType === NodeType.Assets ? asset : currentSelectedType === NodeType.Albums ? album : folder
                          // }
                        />
                      )}
                    {currentSelectedType === NodeType.Assets && (
                      <TagsAccordion
                        asset={asset}
                        currentAccordionItems={currentAccordionItems}
                        multipleAssetsTags={multipleAssetSelected ? multipleAssets?.tags : undefined}
                        selectedAssetIds={isAllEntitySelected ? undefined : selectedAssetIds}
                        folderId={isAllEntitySelected ? selectedFolder : undefined}
                        albumId={isAllEntitySelected ? selectedAlbum : undefined}
                        disabled={isPublicRoute && multipleAssetSelected}
                        readOnly={isPublicRoute || isTrashPage}
                      />
                    )}
                    {currentSelectedType === NodeType.Assets && (
                      <WorkflowAccordion
                        asset={asset}
                        currentAccordionItems={currentAccordionItems}
                        multipleAssetsWorkflows={multipleAssetSelected ? multipleAssets?.workflows : undefined}
                        selectedAssetIds={isAllEntitySelected ? undefined : selectedAssetIds}
                        folderId={isAllEntitySelected ? selectedFolder : undefined}
                        albumId={isAllEntitySelected ? selectedAlbum : undefined}
                        disabled={isPublicRoute && multipleAssetSelected}
                        readOnly={isTrashPage}
                      />
                    )}
                    {currentSelectedType === NodeType.Assets && !isPublicRoute && (
                      <NotesAccordion
                        asset={asset}
                        multipleAssetSelected={multipleAssetSelected}
                        currentAccordionItems={currentAccordionItems}
                        readOnly={isTrashPage}
                      />
                    )}
                    {!isPublicRoute && (
                      <AuditLogAccordion
                        multipleAssetSelected={multipleAssetSelected}
                        selectedAssetIds={selectedAssetIds}
                        currentAccordionItems={currentAccordionItems}
                        entity={currentSelectedType}
                      />
                    )}
                  </>
                )}
              </Accordion>
            </div>
          </>
        ) : (
          <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
            Nothing selected
          </h2>
        )}
      </>
    );
  };

  return (
    <div
      className={cn(
        'flex h-full flex-col bg-container-background @container/inspector',
        isUploadPage && uploadState === UploadState.NotStarted && 'h-full',
      )}
    >
      {renderContent()}
    </div>
  );
};
