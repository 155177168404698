import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { batchUpdate } from '@/services/batch.service';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@/components/ui/use-toast';

export const useBatchRemoveAssets = () => {
  const { toast } = useToast();

  const batchRemoveAssetsWithAuth = useAuthenticatedQueryFn(batchUpdate);

  const batchRemoveAssetsMutation = useMutation({
    mutationFn: batchRemoveAssetsWithAuth,
    onSuccess: () => {
      toast({
        title: 'Assets processed',
        description: 'Assets have been processed and will be removed shortly.',
      });
    },
  });

  return {
    batchRemoveAssetsMutation,
  };
};
