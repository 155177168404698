import { ComponentType, ReactElement } from 'react';
import { FormField, FormItem, FormLabel, FormMessage, FormControl, FormDescription } from '@/components/ui/form';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { MetadataCheckbox } from '@/components/inspector/metadata-accordion/metadata-checkbox';
import { MetadataSelect } from '@/components/inspector/metadata-accordion/metadata-select';
import { QualitySlider } from '@/components/ui/quality-slider';
import { MetadataField } from '@/types/metadata';
import { MultiSelect } from '@/components/inspector/metadata-accordion/metadata-multiselect';
import { TagSelector } from '@/components/tag-selector';
import { DatePicker } from '@/components/ui/datepicker';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { DateTime } from 'luxon';
import { Dropzone } from '@/components/ui/dropzone';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { Button } from '@/components/ui/button';
import { Slider } from '@/components/ui/slider';
import { Input } from '@/components/ui/input';
import { Info } from 'lucide-react';
import { ulid } from 'ulid';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export type MetadataTypeMap = {
  string: string;
  text: string;
  integer: number;
  float: number;
  boolean: boolean;
  tag_selector: string;
  date: Date;
  datetime: DateTime;
  select: string;
  multi_select: Array<string>;
  options: object;
  file: string;
  slider: number;
  dropzone: string;
  quality_slider: number;
};

const FieldComponents: Record<keyof MetadataTypeMap, ComponentType<any>> = {
  string: Input,
  text: Textarea,
  integer: Input,
  float: Input,
  boolean: MetadataCheckbox,
  tag_selector: TagSelector,
  date: DatePicker,
  datetime: DatePicker,
  select: MetadataSelect,
  multi_select: MultiSelect,
  options: Options,
  file: Input,
  slider: Slider,
  dropzone: Dropzone,
  quality_slider: QualitySlider,
};

export type FieldProps = Pick<MetadataField, 'name' | 'slug' | 'type' | 'description' | 'options'> & {
  'disabled': boolean;
  'noLabel'?: boolean;
  'appendIcon'?: ReactElement;
  'placeholder'?: string;
  'validation': z.ZodTypeAny;
  'min'?: number;
  'max'?: number;
  'data-cy'?: string;
  'readOnly'?: boolean;
  'onClick'?: (...args: Array<any>) => void;
  'onChange'?: (...args: Array<any>) => void;
  'value'?: string;
  'checked'?: boolean;
  'withUploader'?: boolean;
  'ref'?: HTMLInputElement;
  'accept'?: string;
  'onInput'?: (...args: Array<any>) => void;
  'step'?: number;
  'rows'?: number;
  'tagId'?: string;
  'parentTag'?: string;
};

export const renderField = (form: any, field: FieldProps, separatorClass?: string): JSX.Element | null => {
  const Component = FieldComponents[field.type as keyof MetadataTypeMap];

  if (!Component) {
    return null;
  }

  const isRequired =
    field.type === 'boolean'
      ? false
      : field.validation
      ? !field.validation.isOptional() && !field.validation.isNullable()
      : false;

  return (
    <FormField
      key={field.slug}
      name={field.slug}
      control={form.control}
      render={({ field: controllerField }) => (
        <>
          <FormItem
            className={cn(
              'flex @[30rem]/inspector:gap-x-3',
              field.type === 'boolean'
                ? 'flex-row flex-wrap gap-x-3 @[24rem]/inspector:items-center @[24rem]/inspector:justify-between @[30rem]/inspector:flex-row @[30rem]/inspector:flex-nowrap @[30rem]/inspector:justify-normal'
                : 'flex-col @[30rem]/inspector:flex-row',
              field.type === 'boolean' && field.disabled && 'opacity-40',
            )}
          >
            <div
              className={cn(
                'flex items-center gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end',
                field.type === 'boolean' && 'grow @[30rem]/inspector:grow-0',
              )}
            >
              {!field.noLabel && (
                <FormLabel
                  htmlFor={field.slug}
                  className={cn(
                    'leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:text-right',
                    field.type === 'boolean' ? '@[30rem]/inspector:mr-1.5' : '@[30rem]/inspector:mt-2',
                    isRequired && 'after:ml-0.5 after:text-red-500 after:content-["*"]',
                  )}
                >
                  {field.name}
                </FormLabel>
              )}
              {field.description && (
                <TooltipProvider key={ulid()} delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger
                      asChild
                      className={cn(
                        'hidden @[30rem]/inspector:order-1',
                        field.type !== 'boolean'
                          ? '@[24rem]/inspector:flex @[30rem]/inspector:mt-2'
                          : '@[30rem]/inspector:flex',
                      )}
                    >
                      <Info className="size-3.5 text-neutral-600" />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent className="max-w-[300px]">
                        <p>{field.description}</p>
                      </TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <FormControl
              className={cn(field.type === 'boolean' ? '!mt-0' : '@[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2')}
            >
              <div className="relative">
                <Component
                  {...controllerField}
                  readOnly={field.readOnly ?? false}
                  // indeterminate
                  placeholder={
                    field.type === 'date' ? 'YYYY-MM-DD' : field.placeholder ? field.placeholder : field.name
                  }
                  {...(field.options && { options: field.options })}
                  onChange={(value: any) => {
                    if (field.type === 'multi_select') {
                      const newValues = (value as Array<{ value: string; label: string }>).map(
                        (option) => option.value,
                      );
                      controllerField.onChange(newValues);
                    } else {
                      controllerField.onChange(value);
                    }
                  }}
                  onValueChange={(value: any) => {
                    if (field.type === 'slider' || field.type === 'quality_slider') {
                      controllerField.onChange(value);
                    }
                  }}
                  {...(['boolean', 'file'].includes(field.type) &&
                    field.onClick && {
                      onClick: field.onClick,
                    })}
                  {...(field.type === 'file' && field.value && { value: field.value })}
                  {...(field.type === 'slider' && { value: controllerField.value ? [controllerField.value] : [0] })}
                  {...(field.type === 'slider' && field.step && { step: field.step })}
                  {...(field.type === 'boolean' && field.checked && { checked: controllerField.value })}
                  {...(field.type === 'float' && { type: 'number' })}
                  {...(field.min !== undefined && { min: field.min })}
                  {...(field.max !== undefined && { max: field.max })}
                  {...(field.type === 'tag_selector' && field.tagId && { tagId: field.tagId })}
                  {...(field.type === 'tag_selector' && field.parentTag && { parentTag: field.parentTag })}
                  {...(field.type === 'file' &&
                    field.withUploader && {
                      withUploader: field.withUploader,
                      inputRef: field.ref,
                      accept: field.accept,
                      onInput: field.onInput,
                    })}
                  {...(field.disabled && { disabled: field.disabled })}
                  {...(field.readOnly && { readonly: 'readonly' })}
                  {...(field.rows && { rows: field.rows })}
                  data-cy={field['data-cy'] ? field['data-cy'] : `${field.slug}-field`}
                  className={cn(
                    // field.className,
                    field.type === 'text' && 'w-full',
                    ['text', 'string', 'float', 'integer', 'select', 'multi_select', 'date'].includes(field.type) &&
                      '@[30rem]/inspector:!mt-0',
                    field.type === 'boolean' &&
                      'ml-3 data-[state=unchecked]:bg-red-500 @[30rem]/inspector:ml-0 @[30rem]/inspector:mt-0.5',
                    ['text', 'string', 'float', 'integer', 'select', 'date', 'file'].includes(field.type) &&
                      'bg-input-background focus-within:ring-offset-white dark:focus-within:ring-offset-neutral-950',
                    field.type === 'date' &&
                      'bg-input-background focus-within:ring-offset-neutral-100 hover:bg-white dark:focus-within:ring-offset-neutral-950 dark:hover:bg-neutral-950',
                    ['text', 'string', 'float', 'integer', 'date'].includes(field.type) && '!pr-0',
                    field.type === 'file' && 'pr-0',
                    form.formState.errors[field.slug] &&
                      ['text', 'string', 'float', 'integer', 'date'].includes(field.type) &&
                      '!border-red-500 dark:focus-visible:ring-red-500 ',
                  )}
                  // Include Input's `appendIcon` property with an "X" button only if field type is
                  // text, string, float, date or integer and if controllerField.value contains value
                  // and if the field is not disabled
                  {...(['text', 'string', 'float', 'integer', 'date', 'file'].includes(field.type) && {
                    appendIcon:
                      controllerField.value && !field.disabled && field.type !== 'file' ? (
                        <Button
                          onClick={() => controllerField.onChange('')}
                          variant="ghost"
                          className="mr-px min-h-6 px-1 py-0 text-xs !text-neutral-400 duration-150 hover:bg-transparent hover:!text-red-300 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-red-800 dark:focus-visible:ring-neutral-900"
                          aria-label="Clear field"
                        >
                          Clear
                        </Button>
                      ) : (
                        field.appendIcon
                      ),
                  })}
                />
                <FormMessage />
              </div>
            </FormControl>
            {field.description && (
              <FormDescription
                className={cn(
                  'block',
                  field.type === 'boolean' ? 'grow @[30rem]/inspector:hidden' : '@[24rem]/inspector:hidden',
                )}
              >
                {field.description}
              </FormDescription>
            )}
          </FormItem>
          <Separator className={separatorClass} />
        </>
      )}
    />
  );
};
