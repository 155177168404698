import { damRequest, INTERNAL_V1 } from '@/hooks/damRequest';
import { auditDefaultPageSize } from '@/types/pagination';
import { metadataSchema } from '@/types/metadata';
import { assetSchema } from '@/types/asset';

const BASE = `${INTERNAL_V1}/metadata`;

const getMetadataList = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}&filterQuery=${
      params.queryString
    }&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );

  return metadataSchema.parse(response);
};

const createMetadata = async (body: object, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });

  return response;
};

const deleteMetadata = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const saveAssetMetadata = async (
  { asset_id, metadata }: { asset_id?: string | null; metadata?: object | null },
  token?: string,
) => {
  const response = await damRequest('PATCH', `${INTERNAL_V1}/assets/${asset_id}`, token, {
    body: { metadata },
  });

  return assetSchema.parse(response);
};

const getMetadata = async ({ id }: { id: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${id}`, token);

  return response;

  // return metadataSchema.parse(response);
};

const updateMetadata = async ({ id, metadata }: { id?: string | null; metadata?: object | null }, token?: string) => {
  const response = await damRequest('PATCH', `${BASE}/${id}`, token, {
    body: { ...metadata },
  });

  return response;

  // return assetSchema.parse(response);
};

const getMetadataAuditLog = async (
  params?: { id?: string; pagination?: { offset: number; limit: number } },
  token?: string,
) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? auditDefaultPageSize),
  }).toString();

  const response = await damRequest('GET', `${BASE}/${params?.id}/audit?${query}`, token);

  return response;
};

export {
  getMetadataList,
  getMetadata,
  saveAssetMetadata,
  createMetadata,
  deleteMetadata,
  updateMetadata,
  getMetadataAuditLog,
};
