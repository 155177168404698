import { FC } from 'react';
import { cn } from '@/lib/utils';
import { ClearIndicator, CustomInput } from '@/components/ui/react-select-elements';
import { OnChangeValue } from 'react-select';
import { Option } from '@/types/select-option';
import CreatableSelect from 'react-select/creatable';
import {
  controlStyles,
  placeholderStyles,
  selectInputStyles,
  valueContainerStyles,
  singleValueStyles,
  multiValueStyles,
  multiValueLabelStyles,
  multiValueRemoveStyles,
  indicatorsContainerStyles,
  clearIndicatorStyles,
  indicatorSeparatorStyles,
  dropdownIndicatorStyles,
  menuStyles,
  groupHeadingStyles,
  optionStyles,
  noOptionsMessageStyles,
  indeterminateDropdownIndicatorStyles,
  indeterminatePlaceholderStyles,
  indeterminateMultiValueStyles,
  indeterminateMultiValueLabelStyles,
} from '@/components/ui/react-select-styles';

export const AssetKeywordSelect: FC<{
  options: Array<Option> | [];
  placeholder?: string;
  disabled?: boolean;
  value?: Array<Option>;
  isLoading: boolean;
  onCreateOption: (inputValue: string) => void;
  onChange: (newOptions: OnChangeValue<Option, true>, context: any) => void;
  onInputChange: (newValue: string) => void;
  indeterminate?: boolean;
}> = ({ options, placeholder, disabled, isLoading, onCreateOption, onChange, onInputChange, value, indeterminate }) => {
  const portalTarget = document.querySelector('#portal');

  return (
    <CreatableSelect
      isClearable
      isMulti
      options={options}
      onChange={(newOptions, context) => onChange(newOptions as Array<Option>, context)}
      placeholder={indeterminate ? 'Multiple values selected for tags' : placeholder ?? 'Type in some keywords'}
      value={value}
      filterOption={null}
      isDisabled={disabled}
      isLoading={isLoading}
      // unstyled
      onCreateOption={onCreateOption}
      onInputChange={onInputChange}
      menuPortalTarget={portalTarget instanceof HTMLElement ? portalTarget : null}
      components={{
        Input: CustomInput,
        DropdownIndicator: () => null,
        ClearIndicator,
      }}
      classNames={{
        control: ({ isFocused, isDisabled }) =>
          cn(
            isDisabled && controlStyles.disabled,
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base,
            indeterminate && controlStyles.indeterminate,
          ),
        placeholder: () => (indeterminate ? indeterminatePlaceholderStyles : placeholderStyles),
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => cn(multiValueStyles, indeterminate && indeterminateMultiValueStyles),
        multiValueLabel: () => cn(multiValueLabelStyles, indeterminate && indeterminateMultiValueLabelStyles),
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => cn(dropdownIndicatorStyles, indeterminate && indeterminateDropdownIndicatorStyles),
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected }) =>
          cn(isFocused && optionStyles.focus, isSelected && optionStyles.selected, optionStyles.base),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
    />
  );
};
