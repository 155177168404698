import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { Loader2, Upload } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DropzoneProps {
  'value'?: string;
  'onChange'?: (image: File | null) => void;
  'disabled'?: boolean;
  'isLoading'?: boolean;
  'data-cy'?: string;
}

export const Dropzone = ({
  'value': initialValue,
  onChange,
  disabled = false,
  isLoading,
  'data-cy': dataCy,
}: DropzoneProps) => {
  const [value, setImage] = useState<string | null>(initialValue ?? null);

  const handleDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileUrl = URL.createObjectURL(file);
      setImage(fileUrl);
      onChange?.(file);
    }
  };

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setImage(initialValue);
    } else if (initialValue === null) {
      setImage(null);
    }
  }, [initialValue]);

  const handleRemove = () => {
    setImage(null);
    onChange?.(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/png': ['.png'],
    },
    multiple: false,
    disabled,
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={cn(
          value
            ? 'flex flex-col items-center space-y-2'
            : 'flex w-full cursor-pointer items-center justify-center rounded-[36px] border-[6px] border-dashed border-gray-text bg-transparent px-5 py-6 text-center text-sm text-gray-text transition duration-150 hover:border-neutral-400 focus:outline-none',
          isDragActive && 'border-neutral-500',
          disabled && 'cursor-not-allowed opacity-50',
        )}
        data-cy={dataCy}
      >
        {value ? (
          <div
            className={cn(
              'relative h-32 w-full border border-dark-gray-text bg-void-gap',
              !value && 'flex items-center justify-center',
            )}
          >
            <Image src={value} alt="Watermark Preview" fill className="object-contain" />
          </div>
        ) : (
          <>
            <input {...getInputProps()} />
            {isLoading ? (
              <Loader2 className="size-4 animate-spin opacity-50" />
            ) : (
              <>
                {/* {isDragActive ? (
            <p>Drop the files here...</p>
            ) : ( */}
                <div className="flex flex-col items-center">
                  <Upload className="mb-2 size-12" strokeWidth={1} />
                  <p className="text-balance">Click here or drop an image to use as a watermark</p>
                </div>
                {/* )} */}
              </>
            )}
          </>
        )}
      </div>
      {value && (
        <Button
          onClick={handleRemove}
          variant="secondary"
          disabled={disabled || !value}
          className="mt-3 h-12 w-full"
          size="sm"
          data-cy={`${dataCy}-remove-button`}
        >
          Remove Watermark
        </Button>
      )}
    </>
  );
};
