import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getVariant, getVariantList } from '@/services/variant.service';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';

export const useVariant = (id: string, options?: object) => {
  const variantQueryFn = useAuthenticatedQueryFn(getVariant);

  const {
    data: variant = [],
    isPending: variantIsPending,
    isFetching: variantIsFetching,
    error: variantError,
    refetch: refetchVariant,
  } = useQuery({
    queryKey: ['variant', id],
    queryFn: () => variantQueryFn(id),
    ...options,
  });

  return {
    variant,
    variantIsFetching,
    variantIsPending,
    variantError,
    refetchVariant,
  };
};

export const useVariantList = (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const variantListQueryFn = useAuthenticatedQueryFn(getVariantList);

  const {
    data: variantList = {
      variants: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: variantListIsPending,
    isFetching: variantListIsFetching,
    error: variantListError,
    refetch: refetchVariantList,
  } = useQuery({
    queryKey: ['variantList', params.pagination, params.queryString, params.sort],
    queryFn: () => variantListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    variantList,
    variantListIsFetching,
    variantListIsPending,
    variantListError,
    refetchVariantList,
  };
};
