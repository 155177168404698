import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@/lib/utils';

const QualitySlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      ' relative flex w-full touch-none select-none items-center border border-ui-bevel bg-input-background p-px',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-6 w-full overflow-hidden">
      <SliderPrimitive.Range className="absolute h-full bg-purple-500" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="absolute -left-2 -top-2 size-4 bg-transparent focus-visible:outline-none" />
    <span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm font-medium text-black dark:text-white">
      {props.value ? `${props.value}%` : '0%'}
    </span>
  </SliderPrimitive.Root>
));

QualitySlider.displayName = 'QualitySlider';

export { QualitySlider };
