import { z } from 'zod';

const tagSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  slug: z.string(),
  facet: z.boolean(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  createdAt: z.string(),
  updatedBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  updatedAt: z.string(),
  searchable: z.boolean(),
  public: z.boolean(),
  parentTag: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

export const getTagsResponseSchema = z.object({
  tags: z.array(tagSchema),
  pagination: z.object({
    count: z.number(),
    limit: z.number(),
    offset: z.number(),
  }),
});

export const getAutocompleteTagsResponseSchema = z.object({
  completion: z.string().nullable(),
  suggestions: z.array(z.object({ id: z.string(), name: z.string() })),
});

const UpdateTagParams = z.object({
  id: z.string().optional(),
  body: z.object({
    name: z.string(),
    facet: z.boolean(),
    public: z.boolean().optional(),
    searchable: z.boolean().optional(),
  }),
});

export type UpdateTagParams = z.infer<typeof UpdateTagParams>;

export type Tag = z.infer<typeof tagSchema>;
